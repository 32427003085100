import React, { FC, useContext } from "react";
import { grey } from "@material-ui/core/colors";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";
import Layout from "../../components/Layout";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { StoreContext } from "../../contexts";
import { useFetch } from "../../hooks/useFetch";
import { API } from "../../api/types";
import { getAuthHeaders } from "../../api";
import config from "../../config";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { formatDistanceToNow } from "date-fns";
import TrendingRelatedSiteKeyword from "./components/TrendingRelatedSiteKeyword";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  trendsCard: {
    marginTop: theme.spacing(2),
  },
  keyword: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    fontSize: "16px",
  },
}));

const TrendsScreen: FC = () => {
  const store = useContext(StoreContext);
  const activeSite = store!.data.user.data?.profile.activeSite;

  const dataStore = useFetch<API.Trends.ITrendingRelatedKeywordsSiteData>({
    axiosConfig: {
      headers: getAuthHeaders(),
      url: `${config.api.url}/trends/trending-related/${activeSite}`,
    },
  });

  const classes = useStyles();

  if (!dataStore.data) return <LoadingOverlay />;

  return (
    <Layout
      backgroundColor={grey["200"]}
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          goBackUrl="/todos"
        />
      )}
    >
      <Container maxWidth="md" className={classes.container}>
        {dataStore.data.lastparsed ? (
          <>
            <Typography variant="body2">
              Last parsed:{" "}
              {formatDistanceToNow(new Date(dataStore.data.lastparsed), {
                addSuffix: true,
              })}
            </Typography>
            {/*dataStore.data.pages.map((page) => {
              return (
                <Card key={page.url} className={classes.trendsCard}>
                  <CardHeader title={page.url} />
                  <CardContent>
                    {page.keywords.map((keyword) => {
                      return (
                        <>
                          <div className={classes.keyword}>
                            {keyword.keyword}
                          </div>
                          {keyword.related.map((related) => {
                            return (
                              <>
                                <TrendingRelatedKeyword {...related} />
                                <Box mb={1} />
                              </>
                            );
                          })}
                          <Box mb={2} />
                        </>
                      );
                    })}
                  </CardContent>
                </Card>
              );
            })*/}
            <Card className={classes.trendsCard}>
              <CardHeader title="Trending Related Keywords" />
              <CardContent>
                {dataStore.data.keywords.map((keyword, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TrendingRelatedSiteKeyword {...keyword} />
                      <Box mb={1} />
                      <Divider />
                      <Box mb={1} />
                    </React.Fragment>
                  );
                })}
              </CardContent>
            </Card>
          </>
        ) : (
          <Typography variant="body2">
            No Trending data has been fetched yet. Please check back later.
          </Typography>
        )}
      </Container>
    </Layout>
  );
};

export default TrendsScreen;
