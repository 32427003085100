import { Typography } from "@material-ui/core";
import { useLocalStorage } from "@rehooks/local-storage";
import React, { FC, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import ConditionalRedirect from "../components/ConditionalRedirect/ConditionalRedirect";
import Layout from "../components/Layout";
import { StoreContext } from "../contexts";
import AnalyticsScreen from "../screens/Analytics/AnalyticsScreen";
import LinkScreen from "../screens/Link/LinkScreen";
import ContactsScreen from "../screens/Links/ContactsScreen";
import LinksScreen from "../screens/Links/LinksScreen";
import LoginScreen from "../screens/Login/LoginScreen";
import PageScreen from "../screens/Page/PageScreen";
import PrivacyScreen from "../screens/Privacy/PrivacyScreen";
import SiteScreen from "../screens/Site/SiteScreen";
import SiteSettingsScreen from "../screens/SiteSettings/SiteSettingsScreen";
import WelcomeScreen from "../screens/Welcome/WelcomeScreen";
import TodosScreen from "../screens/Todos/TodosScreen";
import TrendsScreen from "../screens/Trends/TrendsScreen";
import KeywordToolScreen from "../screens/KeywordTool/KeywordToolScreen";
import SeoProgressScreen from "../screens/SeoProgress/SeoProgressScreen";

const NotFoundScreen: FC = () => {
  return (
    <Layout renderAppToolbar={() => <></>}>
      <Typography>Page was not found</Typography>
    </Layout>
  );
};

const Navigation: FC = () => {
  const [authToken] = useLocalStorage<string>("authToken");
  const store = useContext(StoreContext);
  const isLoggedIn = Boolean(authToken && store!.data.user?.data);
  const hasSites = Boolean(store!.data.sites?.data?.length);

  return (
    <Switch>
      <Route path="/" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <ConditionalRedirect condition={!hasSites} redirectUrl="/welcome">
            <SiteScreen />
          </ConditionalRedirect>
        </ConditionalRedirect>
      </Route>
      <Route path="/login" exact>
        <ConditionalRedirect condition={isLoggedIn} redirectUrl="/">
          <LoginScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/welcome" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <ConditionalRedirect condition={hasSites} redirectUrl="/">
            <WelcomeScreen />
          </ConditionalRedirect>
        </ConditionalRedirect>
      </Route>
      <Route path="/outreach/links" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <LinksScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/outreach/links/:linkId" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <LinkScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/outreach/contacts" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <ContactsScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/sites/:siteId" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <SiteScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/sites/:siteId/settings" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <SiteSettingsScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/sites/:siteId/pages/:pageId" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <PageScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/analytics" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <AnalyticsScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/seo-progress" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <SeoProgressScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/todos" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <TodosScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/trends" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <TrendsScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/keywordtool" exact>
        <ConditionalRedirect condition={!isLoggedIn} redirectUrl="/login">
          <KeywordToolScreen />
        </ConditionalRedirect>
      </Route>
      <Route path="/privacy-policy" exact>
        <PrivacyScreen />
      </Route>
      <Route>
        <NotFoundScreen />
      </Route>
    </Switch>
  );
};

export default Navigation;
