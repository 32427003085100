import React, { FC, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import { API } from "../../api/types";
import SitePicker from "../../components/Drawer/SitePicker";

interface ISiteDialogProps {
  availableSites: API.Analytics.IAvailableSite[];

  onClose: () => void;
  onAdd: (values: API.ISite) => void;
}

const useStyles = makeStyles({
  formControl: {
    width: 400,
  },
});

const AddSiteDialog: FC<ISiteDialogProps> = (props) => {
  const classes = useStyles();
  const [sitePickerInputValue, setSitePickerInputValue] = useState("");
  const [chosenSite, setChosenSite] = useState<null | API.ISite>(null);

  const onFocusSitePicker = () => {
    setSitePickerInputValue("");
  };
  const onBlurSitePicker = () => {
    setSitePickerInputValue("");
  };
  const onChangeSite = (site: API.ISite) => {
    setSitePickerInputValue(site.domain);
    setChosenSite(site);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    props.onAdd(chosenSite!);
  };

  return (
    <Dialog open={true} onClose={props.onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">Add site</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <SitePicker
              disableGrouping
              inputValue={sitePickerInputValue}
              onBlur={onBlurSitePicker}
              onFocus={onFocusSitePicker}
              onChangeInputValue={setSitePickerInputValue}
              sites={props.availableSites}
              onPickSite={onChangeSite}
              isLoading={false}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!chosenSite} type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSiteDialog;
