import React, { FC } from "react";
import AttributeManager, { IAttributeManagerProps } from "./AttributeManager";
import {
  makeStyles,
  createStyles,
  Dialog,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

import MuiDialogTitle from "@material-ui/core/DialogTitle";

interface IAttributeManagerModalProps extends IAttributeManagerProps {
  onClose: () => void;
  isOpen: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(2),
    },
    paper: {
      overflow: "hidden",
    },
    header: {
      padding: theme.spacing(),
      borderBottomWidth: 1,
      borderBottomColor: grey["200"],
      borderBottomStyle: "solid",
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

const AttributeManagerModal: FC<IAttributeManagerModalProps> = (props) => {
  const classes = useStyles();

  const onClickClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      classes={{ paper: classes.paper, root: classes.dialog }}
      onClose={props.onClose}
    >
      <MuiDialogTitle disableTypography className={classes.header}>
        <IconButton aria-label="close" onClick={onClickClose}>
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <AttributeManager
          onChange={props.onChange}
          attributes={props.attributes}
          attributeGroups={props.attributeGroups}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AttributeManagerModal;
