import React, { FC } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { getHelperText, getError } from "../../utils/form";

const PTextField: FC<
  {
    formik: any;
  } & TextFieldProps
> = (props) => {
  return (
    <TextField
      {...props}
      multiline={props.multiline}
      margin="dense"
      label={props.label}
      fullWidth
      onChange={(e) => props.formik.setFieldValue(props.name, e.target.value)}
      name={props.name}
      value={props.formik.values[props.name as string]}
      helperText={getHelperText(props.formik as any, props.name as string)}
      error={getError(props.formik as any, props.name as string)}
    />
  );
};

export default PTextField;
