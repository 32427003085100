import React, { FC } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";

interface IRow {
  label: string | JSX.Element | JSX.Element[];
  value: string;
  renderValue?: ((value: string) => JSX.Element) | null;
}

interface IHorizontalTableProps {
  rows: IRow[];
}

const HorizontalTable: FC<IHorizontalTableProps> = (props) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {props.rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {row.label}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {row.renderValue ? (
                      row.renderValue(row.value)
                    ) : (
                      <Typography variant="body2" color="textPrimary">
                        {row.value}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default HorizontalTable;
