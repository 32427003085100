import React, { FC, FormEventHandler, useContext } from "react";
import Layout from "../../components/Layout";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { getError, getHelperText } from "../../utils/form";
import { checkIsValidURL } from "../../utils/checkIsValidURL";
import { StoreContext } from "../../contexts";
import { createSite } from "../../api";
import { TransitionUp } from "../../utils/snackbar";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    width: "100%",
    maxWidth: 600,
  },
  cardContent: {
    textAlign: "center",
  },
  urlField: {
    width: "90%",
  },
}));

const WelcomeScreen: FC = () => {
  const classes = useStyles();
  const store = useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: { url: "" },
    onSubmit: async (values) => {
      const [createSiteErr] = await createSite({ index_url: values.url });
      const message =
        createSiteErr?.response?.data?.error ||
        createSiteErr?.message ||
        "Sucessfully added page.";

      enqueueSnackbar(message, {
        variant: createSiteErr ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });

      if (!createSiteErr) store!.data.sites.refetch();

      // TOOD: Set the new site as active (blocked since api doesnt return anything upon creation)
    },
    validate: async (values) => {
      if (!checkIsValidURL(values.url))
        return {
          url: "The URL is not valid.",
        };

      return {};
    },
  });

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    formik.submitForm();
  };

  return (
    <Layout
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          searchField={<React.Fragment />}
          onClickLogout={logout}
        />
      )}
    >
      <div className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" color="textPrimary" gutterBottom>
              Welcome to Procontent
            </Typography>
            <Typography variant="body1" color="textSecondary">
              To start, add a site
            </Typography>
          </CardContent>
          <Divider variant="middle" />
          <CardContent className={classes.cardContent}>
            <form onSubmit={onSubmit}>
              <TextField
                className={classes.urlField}
                fullWidth
                label="URL"
                variant="filled"
                size="small"
                name="url"
                onChange={formik.handleChange}
                value={formik.values["url"]}
                helperText={getHelperText(formik as any, "url")}
                error={getError(formik as any, "url")}
              />
              <Box mb={2} />
              <Button type="submit" color="primary">
                Continue
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default WelcomeScreen;
