import React, {
  FormEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  FC,
} from "react";
import Layout from "../../components/Layout";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";
import SearchField from "../../components/SearchField/SearchField";
import { useFetch, usePrevious } from "../../hooks/useFetch";
import config from "../../config";
import {
  addDraft,
  getAuthHeaders,
  parsePage,
  unstarPage,
  starPage,
} from "../../api";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { attributeTranslationMap } from "../../components/SiteTable/utils";
import Error from "../../components/Error/Error";
import { API } from "../../api/types";
import { useSnackbar } from "notistack";
import { TransitionUp } from "../../utils/snackbar";
import { StoreContext } from "../../contexts";
import {
  FormControlLabel,
  makeStyles,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TablePagination,
  Typography,
  useTheme,
  IconButton,
  TypographyProps,
  Divider,
  Checkbox,
} from "@material-ui/core";
import useQueryAid from "../../components/SearchField/hooks";
import AddQueryDialog from "./AddQueryDialog";
import AddDraftDialog from "./AddDraftDialog";
import { IQuery } from "../../components/SearchField/types";
import QueryAid from "../../components/SearchField/QueryAid";
import { ParseSiteIntervalManager } from "../../components/ParseSiteProgress/utils";
import SettingsManager from "../Sites/SettingsManager";
import {
  Autorenew,
  ChevronRight,
  NoteAdd,
  Description,
  WatchLater,
  Delete,
  Settings,
  Dns,
  Assignment,
  OpenInBrowser,
  Star,
  StarBorder,
  Assessment,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { blue, grey, yellow } from "@material-ui/core/colors";
import { pipe } from "ramda";
import { featureNotImplemented } from "../../utils/dev";
import Table from "../../components/Table/Table";
import { IRowContext, ITableProps } from "../../components/Table/types";
import { getTableData } from "./utils";
import { checkIsValidURL } from "../../utils/checkIsValidURL";
import { Business } from "@material-ui/icons";
import clsx from "clsx";
import AttributeManagerModal from "../../components/AttributeManager/AttributeManagerModal";
import AddTodoDialog from "../../components/AddTodoDialog/AddTodoDialog";

const getAttributesFromAttributeGroups = (
  attributeGroups: API.IAttributeGroup[]
) => {
  return attributeGroups.reduce<API.IAttribute[]>((acc, val) => {
    return [...acc, ...val.attributes];
  }, []);
};

const copyToClipboard = (text: string) => {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const getRoute = (url: string) => {
  try {
    return new URL(url).pathname.substring(1);
  } catch {
    return "";
  }
};

const checkIsCompetitor = (page: any, site: API.ISite) =>
  page.site_id !== site?.site_id;

const parsePages = (pages: API.IPage[]) => {
  return pages.reduce(
    (acc, page) => [...acc, page, ...((page?.links as API.IPage[]) || [])],
    [] as API.IPage[]
  );
};

// TODO: Refactor this and think about how to handle the store better...
const getSite = (sites: API.ISite[], id: number) => {
  return sites?.find((site) => site?.id === id)!;
};

// TODO: Improve typing
const reverseObject = (object: any) => {
  var ret = {} as any;
  for (var key in object) ret[object[key]] = key;
  return ret;
};

const lowerCaseKeys = (obj: any) => {
  return Object.entries(obj).reduce((acc: any, [key, value]: any) => {
    return {
      ...acc,
      [key.toLowerCase()]: value,
    };
  }, {});
};

const reversedAttributeTranslationMap = reverseObject(attributeTranslationMap);

const language = {
  seperator: ",",
  equals: ":",
};

const validateSearchQuery = (searchQuery: string) => {
  if (!searchQuery.includes(":")) {
    //console.log("no colon");
    return false;
  }

  for (const condition of searchQuery.split(language.seperator)) {
    const attribute = lowerCaseKeys(reversedAttributeTranslationMap)[
      condition
        .split(language.equals)?.[0]
        .replace(/"/g, "")
        .toLocaleLowerCase()
    ];
    if (!attribute) {
      /*
      console.log("no attribute");
      console.log(lowerCaseKeys(reversedAttributeTranslationMap)); //last_http_status:200
      console.log(condition
          .split(language.equals)?.[0]
          .replace(/"/g, "")
          .toLocaleLowerCase());

       */

      return false;
    }
  }

  //console.log("valid");
  return true;
};

/*
const isOperator = (string: string) => [":", "<", ">"].includes(string);

const validateSearchQuery = (searchQuery: string) => {
  if (searchQuery === "") return true;

  return searchQuery
      .replace(/ /g, "")
      .split(",")
      .every((string) => {
        const regex = /(?<attribute>[^(:|<|>)]+)(?<operator>.)(?<value>.*)$/;
        const { operator, value, attribute } = string.match(regex)!.groups! as {
          attribute: string;
          operator: string;
          value: string;
        };

        return (
            operator && isOperator(operator) && attribute && value !== undefined
        );
      });
};
*/

const searchQueryToFilters = (searchQuery: string): API.IPageFilter[] => {
  if (!searchQuery) return [];

  return searchQuery.split(language.seperator).map((filterString) => {
    const groups = /("?(?<attribute>.*?)"?:"?(?<value>.*)"?)/g.exec(
      filterString
    )?.groups;
    const value = groups?.value;
    const attribute = lowerCaseKeys(reversedAttributeTranslationMap)[
      groups?.attribute?.toLowerCase?.() || ""
    ];
    return {
      attribute: attribute as any,
      value,
    };
  });
};

const useStyles = makeStyles((theme) => ({
  pagination: {
    //height: 160,
  },
  attributeContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    marginLeft: "auto",
  },
  rowIcon: {
    fontSize: "inherit",
    marginRight: theme.spacing(1),
    transform: "scale(1.2) translateY(1px)",
    width: theme.typography.body1.fontSize,
  },
  menuItemIcon: {
    marginRight: theme.spacing(1),
  },
  settingsManagerContainer: {
    padding: theme.spacing(1) / 2,
    width: 250,
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    justifyContent: "space-between",
  },
  radioButton: {
    padding: theme.spacing(0.75),
  },
  headerButtons: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
  },
  container: {
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  competitorPageRow: {
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
    backgroundColor: grey["50"],
  },
  competitorPageText: {
    fontSize: ".85em",
  },
  settingsManagerDivider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

const initialPaginationOptions = {
  rowsPerPageOptions: [10, 50, 100],
  rowsPerPage: 50,
  page: 0,
};

const SiteScreen: FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const activeSite = store!.data.user.data?.profile.activeSite;
  const filters = useMemo(
    () =>
      store!.data.user.data?.profile?.filters || {
        competitors: "none",
        onlyStarred: false,
      },
    [store]
  );

  const previousSite = usePrevious(activeSite);
  const [createDraftState, setCreateDraftState] = useState<{
    page: API.IPage | null;
  }>({ page: null });
  const sortSettings = store!.data.user.data?.profile?.sortSettings || {
    columnIndex: 0,
    direction: "asc",
  };
  const [attributeManagerIsOpen, setAttributeManagerIsOpen] = useState(false);
  const [actionMenuState, setActionMenuState] = useState<{
    element: Element | null;
    page: API.IPage | null;
    context: {
      isURL: boolean;
    };
  }>({ element: null, page: null, context: { isURL: false } });
  const [siteOptionsAnchorElement, setSiteOptionsAnchorElement] =
    useState<Element | null>(null);
  const [addQueryDialogIsOpen, setAddQueryDialogIsOpen] = useState(false);
  const [addTodoDialogIsOpen, setAddTodoDialogIsOpen] = useState(false);
  const initialTodoDialogData = { pageId: 0, url: "" };
  const [todoDialogData, setTodoDialogData] = useState(initialTodoDialogData);
  const searchFieldInputRef = useRef(null);
  const [paginationOptions, setPaginationOptions] = useState(
    initialPaginationOptions
  );
  const queryAidUtils = useQueryAid(activeSite as string);
  const { enqueueSnackbar } = useSnackbar();
  const site = getSite(store!.data.sites.data, Number(activeSite));
  const [searchQueryLastSubmit, setSearchQueryLastSubmit] = useState(
    Date.now()
  );
  const searchQueryFilters = useMemo(
    () => [
      ...searchQueryToFilters(queryAidUtils.inputState.value),
      { attribute: "starred", value: filters?.onlyStarred },
    ],
    [queryAidUtils.inputState.value, filters?.onlyStarred]
  );
  const attributes = useMemo(
    () => store!.data.user?.data?.profile?.attributes || [],
    [store]
  );
  const {
    data: pagesData,
    isLoading: isLoadingFetchPages,
    error: fetchPagesError,
    refetch: refetchPages,
  } = useFetch<{
    attribute_groups: API.IAttributeGroup[];
    count_all: number;
    count_filter: number;
    pages: API.IPage[];
  }>(
    {
      axiosConfig: {
        url: `${config.api.url}/pages/all/${site?.site_id}`,
        headers: getAuthHeaders(),
        data: {
          filters: searchQueryFilters,
          with_competitors: filters?.competitors,
          page: paginationOptions.page + 1,
          results_per_page: paginationOptions.rowsPerPage,
          sorting: [
            {
              attribute: attributes[sortSettings.columnIndex]?.name || "",
              order: sortSettings.direction,
            },
          ],
        } as API.IGetPageData,
        method: "POST",
      },
      ignore: !site,
    },
    [
      activeSite,
      searchQueryLastSubmit,
      sortSettings.columnIndex,
      sortSettings.direction,
      filters?.competitors,
      filters?.onlyStarred,
      store?.ui.parseSite?.get()?.lastPolled,
      paginationOptions,
    ]
  );

  useEffect(() => {
    if (!pagesData || attributes.length) return;
    store!.data.user.set(
      {
        attributes: getAttributesFromAttributeGroups(
          pagesData.attribute_groups
        ),
      },
      { optimisticResponse: true }
    );
  }, [pagesData, attributes, store]);
  useEffect(() => ParseSiteIntervalManager.clear, []);
  useEffect(
    () => setPaginationOptions(initialPaginationOptions),
    [searchQueryLastSubmit, filters, activeSite]
  );
  useEffect(() => {
    const siteWasChanged =
      [activeSite, previousSite].every(Boolean) && activeSite !== previousSite;
    if (siteWasChanged)
      store!.data.user.set(
        {
          filters: { competitors: "none", onlyStarred: false },
        },
        { optimisticResponse: true }
      );
  }, [activeSite, previousSite, store]);

  if (fetchPagesError)
    return (
      <Error
        message={
          fetchPagesError?.response?.data?.error || fetchPagesError?.message
        }
      />
    );
  const onClickSaveQuery = () => setAddQueryDialogIsOpen(true);
  const onClickQuery = (query: IQuery) => {
    if (query.attributes)
      store!.data.user.set(
        { attributes: query.attributes },
        { optimisticResponse: true }
      );
    setSearchQueryLastSubmit(Date.now());
    queryAidUtils.setInputState({
      isOpen: false,
      isDirty: false,
      value: query.query,
    });
  };
  const onClickSearchField = () => {
    if (queryAidUtils.inputState.isOpen)
      queryAidUtils.setInputState({
        isOpen: true,
        isDirty: false,
        value: queryAidUtils.inputState.value,
      });
  };
  const onChangeSearchField = (e: React.ChangeEvent<any>) => {
    queryAidUtils.setInputState({
      isDirty: true,
      isOpen: true,
      value: e.target.value,
    });
  };
  const onFocusSearchField = () => {
    queryAidUtils.setInputState({
      isDirty: false,
      isOpen: true,
      value: queryAidUtils.inputState.value,
    });
  };
  const onClickAwayQueryAid = () =>
    queryAidUtils.setInputState({
      isOpen: false,
      isDirty: false,
      value: queryAidUtils.inputState.value,
    });

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (
      queryAidUtils.inputState.value !== "" &&
      !validateSearchQuery(queryAidUtils.inputState.value)
    ) {
      enqueueSnackbar("Invalid search query.", {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
      return;
    }
    setSearchQueryLastSubmit(Date.now());
    queryAidUtils.setInputState({
      isDirty: false,
      isOpen: false,
      value: queryAidUtils.inputState.value,
    });

    if (queryAidUtils.inputState.value) queryAidUtils.onSearch();
  };

  const searchField = (
    <form onSubmit={onSubmit}>
      <SearchField
        textFieldProps={{
          onClick: onClickSearchField,
          onFocus: onFocusSearchField,
          onChange: onChangeSearchField,
          name: "searchQuery",
          value: queryAidUtils.inputState.value,
          InputProps: {
            ref: searchFieldInputRef,
          },
        }}
        onClickSaveQuery={onClickSaveQuery}
      />

      <QueryAid
        onClickAway={onClickAwayQueryAid}
        anchorEl={searchFieldInputRef.current}
        onClickQuery={onClickQuery}
        onDeleteQuery={(query) => queryAidUtils.onClickDelete(query)}
        recentQueries={queryAidUtils.filteredRecentQueries()}
        savedQueries={queryAidUtils.filteredSavedQueries()}
        isOpen={
          queryAidUtils.inputState.isOpen &&
          queryAidUtils.filteredRecentQueries().length +
            queryAidUtils.filteredSavedQueries().length >
            0
        }
      />
    </form>
  );

  if ((isLoadingFetchPages && !pagesData) || store!.data.user.isLoading)
    return <LoadingOverlay />;

  const parsedPages = pipe((pages: API.IPage[]) =>
    filters?.competitors === "linked" ? parsePages(pages) : pages
  )(pagesData?.pages || []);

  const tableData = getTableData(parsedPages, attributes);

  const onChangeSorting: ITableProps["onChangeSorting"] = (
    columnIndex,
    direction
  ) => {
    store!.data.user.set(
      { sortSettings: { columnIndex, direction } },
      { optimisticResponse: true }
    );
  };

  const onClickAttributeOptions: MouseEventHandler = () =>
    setAttributeManagerIsOpen(true);

  const onClickSiteOptions: MouseEventHandler = (event) =>
    setSiteOptionsAnchorElement(event.currentTarget);

  const onCloseAttributeManager = () => setAttributeManagerIsOpen(false);

  const onChangeAttributeManager = (attributes: API.IAttribute[]) => {
    store!.data.user.set(
      {
        attributes,
      },
      { optimisticResponse: true }
    );
  };

  const onCloseAddQueryDialog = () => setAddQueryDialogIsOpen(false);
  const onCloseAddTodoDialog = () => setAddTodoDialogIsOpen(false);

  const onAddQuery = (data: {
    attributes: API.IAttribute[];
    name: string;
    query: string;
  }) => {
    store!.data.user.set(
      {
        attributes: data.attributes,
      },
      { optimisticResponse: true }
    );
    queryAidUtils.onClickSaveQuery(data);
    setAddQueryDialogIsOpen(false);
  };

  const onClickParsePage = async () => {
    setActionMenuState({
      element: null,
      page: null,
      context: { isURL: false },
    });
    const page = actionMenuState.page!;
    const [parsePageError] = await parsePage({
      page_id: page.id,
    });
    const message =
      parsePageError?.response?.data?.error ||
      parsePageError?.message ||
      "Successfully initiated parsing of page.";
    enqueueSnackbar(message, {
      variant: parsePageError ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });
  };

  const onClickCreateDraft = async () => {
    setCreateDraftState({ page: actionMenuState.page! });
    setActionMenuState({
      element: null,
      page: null,
      context: { isURL: false },
    });
  };

  const onAddDraft = async (draftPath: string) => {
    setCreateDraftState({ page: null });

    const page = createDraftState.page;
    const [addDraftErr] = await addDraft({
      competitor_page_id: page!.id,
      draft_path: draftPath,
      user_site_id: site?.id,
    });
    const message =
      addDraftErr?.response?.data?.error || "Successfully created draft.";
    enqueueSnackbar(message, {
      variant: addDraftErr ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });
  };

  const onClickAddTodo = (data: API.ITodoNoteDialogData) => {
    setAddTodoDialogIsOpen(true);
    setTodoDialogData(data);
    setActionMenuState({
      element: null,
      page: null,
      context: { isURL: false },
    });
  };

  const onCloseAddDraftDialog = () => setCreateDraftState({ page: null });

  const onChangePage = (event: any, page: number) => {
    setPaginationOptions({ ...paginationOptions, page });
  };

  const onChangeRowsPerPage = (event: any) => {
    const rowsPerPage = Number(event.target.value);
    setPaginationOptions({ ...paginationOptions, rowsPerPage });
  };

  const onCloseSettingsPopper = () => {
    setSiteOptionsAnchorElement(null);
  };

  const onCloseActionMenu = () => {
    setActionMenuState({
      element: null,
      page: null,
      context: { isURL: false },
    });
  };

  const onClickPage = (page: API.IPage) => {
    if (checkIsCompetitor(page, site)) return;

    history.push(`/sites/${activeSite}/pages/${page.id}`);
  };

  const onClickStarPage = async (page: API.IPage) => {
    if (checkIsCompetitor(page, site)) return;
    setActionMenuState({
      page: null,
      context: { isURL: false },
      element: null,
    });
    const [err] = await starPage({ id: page.id });
    if (err) {
      const message = err?.response?.data?.error;
      return enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }
    refetchPages();
  };

  const onClickUnstarPage = async (page: API.IPage) => {
    if (checkIsCompetitor(page, site)) return;
    setActionMenuState({
      page: null,
      context: { isURL: false },
      element: null,
    });
    const [err] = await unstarPage(page.id);
    if (err) {
      const message = err?.response?.data?.error;
      return enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }
    refetchPages();
  };

  const onChangeCompetitorFilter = (e: any) => {
    store!.data.user.set(
      {
        filters: { ...filters, competitors: e.target.value },
      },
      { optimisticResponse: true }
    );
  };

  const onChangeStarredFilter = (e: any) => {
    store!.data.user.set(
      {
        filters: { ...filters, onlyStarred: e.target.checked },
      },
      { optimisticResponse: true }
    );
  };

  const onClickDeletePage = (page: API.IPage) =>
    featureNotImplemented(enqueueSnackbar);

  const onClickExternalLink = (page: API.IPage) => {
    window.open(page.url, "_blank");
    setActionMenuState({
      page: null,
      element: null,
      context: {
        isURL: false,
      },
    });
  };

  const onClickCopySelection = (text: string) => () => {
    copyToClipboard(text);
    setActionMenuState({
      page: null,
      element: null,
      context: { isURL: false },
    });
  };

  const onClickTableRow = (context: IRowContext, event: MouseEvent) => {
    const { clientX, clientY } = event;
    const clickedElementValue = (event.target as any).innerText;

    // TODO: Clear up all created elements
    const myEl = document.createElement("span");
    myEl.style.position = "fixed";
    myEl.style.left = `${clientX}px`;
    myEl.style.top = `${clientY}px`;
    document.body.appendChild(myEl);

    const isURL = checkIsValidURL(clickedElementValue);

    setActionMenuState({
      element: myEl,
      page: parsedPages[context.index],
      context: {
        isURL,
      },
    });
  };

  const renderTableRowIcon: ITableProps["renderRowIcon"] = (rowContext) => {
    if (rowContext.isHeader) return <React.Fragment />;
    const page = parsedPages[rowContext.index];
    if (!page) return <React.Fragment />;
    const isDraft = page.status === "draft";
    const isStarred = Boolean(page.starred);

    const isCompetitor = checkIsCompetitor(page, site);

    const starIcon = isStarred ? (
      <Star
        className={classes.rowIcon}
        style={{ color: yellow["700"], marginLeft: -6 }}
      />
    ) : (
      <React.Fragment />
    );

    if (isCompetitor && filters?.competitors === "linked")
      return <ChevronRight className={classes.rowIcon} color="disabled" />;

    if (isCompetitor)
      return <Business className={classes.rowIcon} color="disabled" />;

    if (isDraft)
      return <WatchLater className={classes.rowIcon} color="disabled" />;

    return (
      <>
        <Description className={classes.rowIcon} color="disabled" />
        {starIcon}
      </>
    );
  };

  const headerButtons = (
    <div className={classes.headerButtons}>
      <IconButton onClick={onClickAttributeOptions} size="small">
        <Dns />
      </IconButton>
      <IconButton onClick={onClickSiteOptions} size="small">
        <Settings />
      </IconButton>
    </div>
  );

  const getTableCellProps: ITableProps["cellProps"] = (
    cellContext,
    rowContext
  ) => {
    if (rowContext.isHeader) return {};

    const page = parsedPages[rowContext.index];

    if (!page) return {};

    const isCompetitor = checkIsCompetitor(page, site);

    if (isCompetitor)
      return {
        className: classes.competitorPageRow,
      };

    return {};
  };

  const getTableTypographyProps: ITableProps["typographyProps"] = (
    columnContext,
    rowContext
  ) => {
    if (rowContext.isHeader) return {};
    const page = parsedPages[rowContext.index];
    if (!page) return {};

    const attribute = attributes[columnContext.index];
    const value = (page as any)?.[attribute.name] || "";
    const isUrl = checkIsValidURL(value);
    const isCompetitor = checkIsCompetitor(page, site);

    let props: TypographyProps = {};

    if (isUrl)
      props = {
        ...props,
        className: clsx(props.className, classes.link),
        // TODO: Refactor so we can use <Link /> here instead.
        style: { color: blue["900"] },
      };

    if (isCompetitor)
      props = {
        ...props,
        className: clsx(props.className, classes.competitorPageText),
      };

    return props;
  };

  const getActionMenuContent = (
    page: API.IPage,
    context: { isURL: boolean }
  ) => {
    const isCompetitor = checkIsCompetitor(page, site);
    const isDraft = page.status === "draft";
    const textSelection = String(window.getSelection()?.getRangeAt(0));

    if (isDraft)
      return (
        <>
          {textSelection && (
            <MenuItem onClick={onClickCopySelection(textSelection)}>
              <Assignment className={classes.menuItemIcon} fontSize="small" />{" "}
              Copy selected text
            </MenuItem>
          )}
          {context.isURL && (
            <MenuItem onClick={() => onClickExternalLink(page)}>
              <OpenInBrowser
                className={classes.menuItemIcon}
                fontSize="small"
              />{" "}
              Go to external page
            </MenuItem>
          )}
          <MenuItem onClick={() => onClickDeletePage(page)}>
            <Delete className={classes.menuItemIcon} fontSize="small" /> Delete
          </MenuItem>
        </>
      );

    if (isCompetitor)
      return (
        <>
          {textSelection && (
            <MenuItem onClick={onClickCopySelection(textSelection)}>
              <Assignment className={classes.menuItemIcon} fontSize="small" />{" "}
              Copy selected text
            </MenuItem>
          )}
          {context.isURL && (
            <MenuItem onClick={() => onClickExternalLink(page)}>
              <OpenInBrowser
                className={classes.menuItemIcon}
                fontSize="small"
              />{" "}
              Go to external page
            </MenuItem>
          )}
          <MenuItem onClick={onClickCreateDraft}>
            <NoteAdd className={classes.menuItemIcon} fontSize="small" /> Create
            draft
          </MenuItem>
        </>
      );

    return (
      <>
        <MenuItem onClick={() => onClickPage(page)}>
          <Assessment className={classes.menuItemIcon} fontSize="small" />
          Page Details
        </MenuItem>
        {textSelection && (
          <MenuItem onClick={onClickCopySelection(textSelection)}>
            <Assignment className={classes.menuItemIcon} fontSize="small" />{" "}
            Copy selected text
          </MenuItem>
        )}
        {context.isURL && (
          <MenuItem onClick={() => onClickExternalLink(page)}>
            <OpenInBrowser className={classes.menuItemIcon} fontSize="small" />{" "}
            Go to external page
          </MenuItem>
        )}
        {page.starred ? (
          <MenuItem onClick={() => onClickUnstarPage(page)}>
            <StarBorder className={classes.menuItemIcon} fontSize="small" />
            Unstar
          </MenuItem>
        ) : (
          <MenuItem onClick={() => onClickStarPage(page)}>
            <Star className={classes.menuItemIcon} fontSize="small" />
            Star
          </MenuItem>
        )}
        <MenuItem onClick={onClickParsePage}>
          <Autorenew className={classes.menuItemIcon} fontSize="small" /> Parse
        </MenuItem>
        <MenuItem
          onClick={() => onClickAddTodo({ pageId: page.id, url: page.url })}
        >
          <NoteAdd className={classes.menuItemIcon} fontSize="small" /> Add todo
        </MenuItem>
      </>
    );
  };

  return (
    <Layout
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          searchField={searchField}
          goBackUrl="/sites"
        />
      )}
    >
      <div className={classes.container}>
        <Table
          isLoading={isLoadingFetchPages}
          cellProps={getTableCellProps}
          typographyProps={getTableTypographyProps}
          headerTooltips={tableData.descriptions}
          headerButtons={headerButtons}
          onClickRow={onClickTableRow}
          renderRowIcon={renderTableRowIcon}
          sorting={sortSettings}
          onChangeSorting={onChangeSorting}
          header={tableData.header}
          body={tableData.body}
        />

        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={paginationOptions.rowsPerPageOptions}
          component="div"
          count={pagesData?.count_filter}
          rowsPerPage={paginationOptions.rowsPerPage}
          page={paginationOptions.page}
          //onChangePage={onChangePage}
          onPageChange={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
      <AddQueryDialog
        open={addQueryDialogIsOpen}
        onAdd={onAddQuery}
        onClose={onCloseAddQueryDialog}
        attributes={attributes}
        attributeGroups={pagesData?.attribute_groups || []}
        query={queryAidUtils.inputState.value}
      />
      <AddTodoDialog
        open={addTodoDialogIsOpen}
        onClose={onCloseAddTodoDialog}
        data={todoDialogData}
      />
      <AddDraftDialog
        open={Boolean(createDraftState.page)}
        onAdd={onAddDraft}
        onClose={onCloseAddDraftDialog}
        indexUrl={site?.index_url || ""}
        defaultRoute={getRoute(createDraftState.page?.url || "")}
      />
      <AttributeManagerModal
        isOpen={attributeManagerIsOpen}
        onClose={onCloseAttributeManager}
        attributes={attributes}
        attributeGroups={pagesData?.attribute_groups || []}
        onChange={onChangeAttributeManager}
      />
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{
          horizontal: "center",
          vertical: -theme.spacing(3),
        }}
        anchorEl={actionMenuState.element}
        open={Boolean(actionMenuState.element)}
        onClose={onCloseActionMenu}
      >
        {Boolean(actionMenuState.page) &&
          getActionMenuContent(
            actionMenuState!.page as API.IPage,
            actionMenuState!.context
          )}
      </Menu>
      <SettingsManager
        onClose={onCloseSettingsPopper}
        open={Boolean(siteOptionsAnchorElement)}
        anchorElement={siteOptionsAnchorElement}
      >
        <div className={classes.settingsManagerContainer}>
          <Typography variant="body1" color="primary">
            Competitors
          </Typography>
          <RadioGroup
            aria-label="competitor"
            name="competitors"
            value={filters?.competitors}
            onChange={onChangeCompetitorFilter}
          >
            {[
              { label: "None", value: "none" },
              { label: "Linked", value: "linked" },
              { label: "All", value: "all" },
            ].map((setting, index) => (
              <div className={classes.attributeContainer} key={index}>
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.formControlLabel,
                  }}
                  labelPlacement="start"
                  value={setting.value}
                  label={
                    <Typography variant="body2">{setting.label}</Typography>
                  }
                  control={
                    <Radio
                      color="primary"
                      className={classes.radioButton}
                      size="small"
                    />
                  }
                />
              </div>
            ))}
          </RadioGroup>

          <Divider className={classes.settingsManagerDivider} />

          <FormControlLabel
            classes={{
              labelPlacementStart: classes.formControlLabel,
            }}
            labelPlacement="start"
            onChange={onChangeStarredFilter}
            label={<Typography variant="body2">Only Starred</Typography>}
            control={
              <Checkbox
                color="primary"
                className={classes.radioButton}
                size="small"
                checked={filters?.onlyStarred}
              />
            }
          />
        </div>
      </SettingsManager>
    </Layout>
  );
};

export default SiteScreen;
