import React, { FC, useContext } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import LinkComponent from "./LinkComponent";
import Notes from "./Notes";
import { linkFunnelContext } from "../utils/contexts";
import { API } from "../../../api/types";
import HorizontalTable from "../../../components/HorizontalTable/HorizontalTable";
import Container from "../../../components/Container/Container";
import ContactDetails from "./ContactDetails";
import LinkCard from "../../Links/components/LinkCard";

const getPurchaseRows = (link: API.Outreach.Link) => {
  return [
    {
      label: "Buy Price",
      value: link.buy_price,
    },
    {
      label: "Currency",
      value: link.buy_currency,
    },
    {
      label: "Date Bought",
      value: link.date_bought,
    },
    {
      label: "Date Expires",
      value: link.date_expires,
    },
  ].map((row) => ({ ...row, value: String(row.value || "") }));
};

const getExchangeRows = (link: API.Outreach.Link) => {
  return [
    {
      label: "URL From",
      value: link.exchange_from_url,
    },
    {
      label: "URL To",
      value: link.exchange_to_url,
    },
    {
      label: "Anchor Text",
      value: link.exchange_anchor,
    },
    {
      label: "No Follow",
      value: link.exchange_is_nofollow ? "Yes" : "No",
    },
    {
      label: "Image Link",
      value: link.exchange_is_image_link ? "Yes" : "No",
    },
  ].map((row) => ({ ...row, value: String(row.value || "") }));
};

const getTheirCommitmentRows = (link: API.Outreach.Link) => {
  return [
    {
      label: "From URL",
      value: link.settled_from_url || link.from_url,
    },
    {
      label: "To URL",
      value: link.to_url,
    },
    {
      label: "Anchor Text",
      value: link.link_anchor,
    },
    {
      label: "No Follow",
      value: link.is_nofollow ? "Yes" : "No",
    },
    {
      label: "Image Link",
      value: link.is_image_link ? "Yes" : "No",
    },
  ].map((row) => ({ ...row, value: String(row.value || "") }));
};

const StatusAccepted: FC = () => {
  const { linkStore, addNote, deleteNote } = useContext(linkFunnelContext)!;

  const onAddNote = (data: { note: string }) => {
    addNote({ text: data.note, link_id: linkStore.data.id });
  };

  const onDeleteNote = (note: API.Outreach.ILinkComment) => {
    deleteNote(note.id);
  };

  return (
    <Container>
      <LinkCard link={linkStore.data} showData disableActions />
      <Box mb={2} />
      <LinkComponent title="Contact">
        {Boolean(linkStore.data.contact) ? (
          <ContactDetails />
        ) : (
          <Typography variant="body2" color="textSecondary">
            No contact
          </Typography>
        )}
      </LinkComponent>
      <Box mb={2} />
      <LinkComponent title="Your Commitment">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6">Purchase</Typography>
            <HorizontalTable rows={getPurchaseRows(linkStore.data)} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Exchange</Typography>
            <HorizontalTable rows={getExchangeRows(linkStore.data)} />
          </Grid>
        </Grid>
      </LinkComponent>
      <Box mb={2} />
      <LinkComponent title="Their Commitment">
        <HorizontalTable rows={getTheirCommitmentRows(linkStore.data)} />
      </LinkComponent>
      <Box mb={2} />
      <LinkComponent title="Notes">
        <Notes
          onDeleteNote={onDeleteNote}
          onAddNote={onAddNote}
          notes={linkStore.data.notes}
        />
      </LinkComponent>
    </Container>
  );
};

export default StatusAccepted;
