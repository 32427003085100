// This component is deprecated and only used by one component. I recommend using
// /src/components/Table instead of this, since this is specifically for rendering
// pages in a table, and no other collection.

import React, { FC, MouseEventHandler, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Checkbox,
  IconButton,
  TableSortLabel,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { API } from "../../api/types";
import { Dns, MoreVert, Settings } from "@material-ui/icons";
import clsx from "clsx";
import { ISiteTableProps } from "./types";
import { grey } from "@material-ui/core/colors";
import ConditionalElement from "../ConditionalElement/ConditionalElement";
import { valueToPresentableText } from "./utils";
import AttributeTooltip from "./AttributeTooltip";

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    minWidth: 650,
  },
  tableRowClickable: {
    cursor: "pointer",
  },
  attributeManagerButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headerButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tableCellStickyHeader: {
    backgroundColor: grey["100"],
  },
  linkCell: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  openInNewIcon: {
    transform: "translateY(2px)",
    marginRight: theme.spacing(0.5),
  },
  bodyCell: {
    verticalAlign: "top",
  },
  actionMenuTableCell: {
    paddingRight: theme.spacing(2),
  },
  tableCellContainer: {
    display: "flex",
    // alignItems: "flex-start",
  },
}));

const BodyCell: FC<{
  icon?: any;
  tableCellProps?: TableCellProps;
  typographyProps?: TypographyProps;
}> = (props) => {
  return (
    <TableCell {...(props.tableCellProps || {})}>
      <Typography variant="body2" {...props.typographyProps}>
        {props.children}
      </Typography>
    </TableCell>
  );
};

const SiteTable: FC<ISiteTableProps> = (props) => {
  const classes = useStyles();
  const buttonRef = useRef(null);

  const onChangeSelect = (page: API.IPage, checked: boolean) => {
    props.onChangeSelect!(page, checked);
  };

  const onClickLinkColumn: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  const attributes = [
    ...(Boolean(props.selectedPages) ? [null] : []),
    ...props.attributes,
  ];

  return (
    <>
      <TableContainer
        style={{ height: props.height }}
        className={classes.tableContainer}
      >
        <Table stickyHeader className={classes.table}>
          {!props.hideHeader && (
            <TableHead>
              <TableRow>
                {attributes.map((attribute) => (
                  <TableCell
                    key={attribute}
                    classes={{ stickyHeader: classes.tableCellStickyHeader }}
                  >
                    {attribute && (
                      <AttributeTooltip
                        showTooltip={Boolean(
                          props.attributeHelpTextMap[attribute]
                        )}
                        description={
                          props.attributeHelpTextMap[attribute]?.description
                        }
                        examples={
                          props.attributeHelpTextMap[attribute]?.examples
                        }
                        render={
                          <TableSortLabel
                            active={props.sort.attribute === attribute}
                            direction={props.sort.direction}
                            onClick={() => props.onClickSort(attribute)}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {props.attributeTranslationMap[attribute]}
                          </TableSortLabel>
                        }
                      />
                    )}
                  </TableCell>
                ))}
                <TableCell
                  classes={{ stickyHeader: classes.tableCellStickyHeader }}
                  align="right"
                >
                  <div className={classes.headerButtonsContainer}>
                    {props.AttributeManager && (
                      <IconButton
                        size="small"
                        onClick={props.onClickAttributeOptions}
                      >
                        <Dns />
                      </IconButton>
                    )}
                    {props.SiteOptions && (
                      <IconButton
                        size="small"
                        onClick={props.onClickSiteOptions}
                      >
                        <Settings />
                      </IconButton>
                    )}
                  </div>
                </TableCell>
                {props.ActionMenu && (
                  <TableCell align="right" padding="none"></TableCell>
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {props.pages.map((page) => {
              return (
                <TableRow
                  key={page.title}
                  className={clsx({
                    [classes.tableRowClickable]: Boolean(props.onClickPage),
                  })}
                  hover={Boolean(props.onClickPage)}
                  onClick={
                    Boolean(props.onClickPage)
                      ? (e) => {
                          // TODO: Refactor this
                          if (window.getSelection()?.type === "Caret")
                            props.onClickPage!(page as API.IPage);
                        }
                      : () => {}
                  }
                  style={props.rowSettings?.general?.(page)?.rowStyles || {}}
                >
                  {props.selectedPages && (
                    <BodyCell
                      tableCellProps={{ padding: "checkbox" }}
                      typographyProps={{ color: "textSecondary" }}
                    >
                      <Checkbox
                        color="primary"
                        checked={props.selectedPages[(page as API.IPage).id]}
                        onChange={(event) =>
                          onChangeSelect(
                            page as API.IPage,
                            event.currentTarget.checked
                          )
                        }
                      />
                    </BodyCell>
                  )}
                  {attributes
                    .filter(Boolean)
                    .map((attribute, attributeIndex) => {
                      const isLink = Boolean(
                        props.columnSettings?.isLink?.(
                          attribute as any,
                          page as API.IPage
                        )
                      );
                      const value = (page as any)[attribute as any];

                      return (
                        <TableCell
                          key={attribute}
                          style={
                            props.columnSettings?.columnStyles?.(
                              attribute as any,
                              page
                            ) || {}
                          }
                          className={classes.bodyCell}
                        >
                          <div className={classes.tableCellContainer}>
                            <div>
                              {props.rowSettings?.icon?.({
                                page,
                                attribute: attribute as any,
                                attributeIndex,
                              })}
                            </div>
                            <Typography
                              variant="body2"
                              color={
                                attributeIndex === 0
                                  ? "textPrimary"
                                  : "textSecondary"
                              }
                              style={
                                props.columnSettings?.textStyles?.(
                                  attribute as any,
                                  page
                                ) || {}
                              }
                            >
                              <ConditionalElement
                                target="_blank"
                                href={value}
                                tag={isLink ? "a" : "span"}
                                className={clsx({
                                  [classes.linkCell]: isLink,
                                })}
                                onClick={isLink ? onClickLinkColumn : () => {}}
                              >
                                {valueToPresentableText(
                                  value,
                                  attribute as any
                                )}
                              </ConditionalElement>
                            </Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                  {Boolean(props.ActionMenu) &&
                    !props.rowSettings?.actions?.(page)?.disabled && (
                      <TableCell
                        className={classes.actionMenuTableCell}
                        align="right"
                        padding="none"
                      >
                        <IconButton
                          ref={buttonRef}
                          size="small"
                          onClick={(event) =>
                            props.onClickActions?.(event, page as API.IPage)
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      </TableCell>
                    )}
                  {/* An empty table cell for attribute column, which only has a header */}
                  {Boolean(props.AttributeManager || props.SiteOptions) && (
                    <TableCell align="right" padding="none" />
                  )}
                  {props.ActionMenu && (
                    <TableCell align="right" padding="none" />
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* </Container> */}
      </TableContainer>

      {props.TablePagination}

      {/* TODO: Refactor this to become more dynamic... */}
      {props.AttributeManager}
      {props.SiteOptions}
      {props.ActionMenu}
    </>
  );
};

export default SiteTable;
