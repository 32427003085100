import React, { FC } from "react";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

const PSelect: FC<{
  label: string;
  name: string;
  formik: any;
  options: { label: string; value: string }[];
}> = (props) => {
  const inputId = `input-label-${props.name}`;

  return (
    <>
      <InputLabel id={inputId}>{props.label}</InputLabel>
      <Select
        style={{ width: "100%" }}
        labelId={inputId}
        value={props.formik.values[props.name]}
        onChange={(e) => props.formik.setFieldValue(props.name, e.target.value)}
      >
        {props.options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default PSelect;
