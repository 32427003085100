import { API } from "../../api/types";

export const getTableData = (
  pages: API.IPage[],
  attributes: API.IAttribute[]
) => {
  const header = attributes.map((attribute) => {
    return attribute.label;
  });

  const body = pages.map((page) => {
    return attributes.map((attribute) => {
      return (page as any)[attribute.name] as string;
    });
  });

  const descriptions = attributes.map((attribute) => {
    return attribute.description || "";
  });

  return {
    descriptions,
    header,
    body,
  };
};
