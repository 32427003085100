import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { logout } from "../../utils/auth";
import AppBarToolbar from "../AppBarToolbar/AppBarToolbar";
import Layout from "../Layout";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 64px)",
  },
});

const LoadingOverlay: FC = () => {
  const classes = useStyles();

  return (
    <Layout
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          goBackUrl="/sites"
        />
      )}
    >
      <div className={classes.container}>
        <CircularProgress />
      </div>
    </Layout>
  );
};

export default LoadingOverlay;
