import * as colors from "@material-ui/core/colors";
import React, { FC, useContext } from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { linkFunnelContext } from "../utils/contexts";

const COLORS = [
  colors.red["700"],
  colors.blue["700"],
  colors.green["700"],
  colors.amber["700"],
  colors.cyan["700"],
  colors.yellow["700"],
  colors.teal["700"],
];

const AnchorDistribution: FC = () => {
  const { linkStore } = useContext(linkFunnelContext)!;

  const data = linkStore.data.page_backlinks.map((backlink) => {
    return {
      name: backlink.AnchorText || "Empty Text",
      value: Number(backlink.RefDomains),
    };
  });

  return (
    <PieChart width={400} height={200}>
      <Pie
        nameKey="name"
        label={({ name }) => name}
        data={data}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  );
};

export default AnchorDistribution;
