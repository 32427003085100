import React, { ChangeEvent, FC, useContext, useState } from "react";
import { grey } from "@material-ui/core/colors";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";
import Layout from "../../components/Layout";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { getAuthHeaders, uploadKeywordToolCsvFile } from "../../api";
import { StoreContext } from "../../contexts";
import { API } from "../../api/types";
import { useFetch } from "../../hooks/useFetch";
import config from "../../config";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import KeywordToolImportDataRow from "./components/KeywordToolImportDataRow";
import clsx from "clsx";
import { TransitionUp } from "../../utils/snackbar";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  inputUploadFile: {
    display: "none",
  },
  buttonUploadFile: {
    margin: 8,
  },
  rowWrapper: {
    padding: "8px 0",
  },
  greyBg: {
    backgroundColor: "#f1f1f1",
  },
  btnChoose: {
    marginBottom: "4px",
  },
  btnUpload: {
    marginBottom: "16px",
  },
  fileName: {
    marginBottom: "4px",
  },
}));

const KeywordToolScreen: FC = () => {
  const classes = useStyles();

  const store = useContext(StoreContext);
  const activeSite = store!.data.user.data?.profile?.activeSite;

  const [selectedFile, setSelectedFile] = useState<File | null>();

  const { enqueueSnackbar } = useSnackbar();

  const dataStore = useFetch<API.KeywordTool.IImportData[]>({
    axiosConfig: {
      headers: getAuthHeaders(),
      url: `${config.api.url}/keywordtool/list/${activeSite}`,
    },
  });

  const uploadFile = async () => {
    if (selectedFile) {
      const [err] = await uploadKeywordToolCsvFile({
        file: selectedFile,
        user_site_id: activeSite,
      });

      if (err) {
        const message = err?.response?.data?.error || err?.message;

        enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        });
      } else {
        enqueueSnackbar(
          "File uploaded. Job started on server, give a little time while the list is being generated.",
          {
            variant: "success",
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            TransitionComponent: TransitionUp,
          }
        );
      }

      setSelectedFile(null);
      dataStore.refetch();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files) {
      console.log(event.target.files[0].name);

      setSelectedFile(event.target.files[0]);
    }
  };

  if (!dataStore.data) return <LoadingOverlay />;

  return (
    <Layout
      backgroundColor={grey["200"]}
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          goBackUrl="/todos"
        />
      )}
    >
      <Container maxWidth="md" className={classes.container}>
        <div>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: "none" }}
              type="file"
              accept="text/csv"
              onChange={(event) => {
                handleFileChange(event);
              }}
            />
            <Button
              className={classes.btnChoose}
              variant="outlined"
              component="span"
            >
              Choose File
            </Button>
          </label>
          <div className={classes.fileName}>
            {selectedFile ? selectedFile.name : null}
          </div>
          <Button
            className={classes.btnUpload}
            color="primary"
            variant="contained"
            component="span"
            disabled={!selectedFile}
            onClick={uploadFile}
          >
            Upload
          </Button>
        </div>
        {dataStore.data.length && (
          <Card>
            <CardHeader title="Imported Data" />
            <CardContent>
              {dataStore.data.map((importRow, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={clsx(classes.rowWrapper, {
                        [classes.greyBg]: index % 2 === 0,
                      })}
                    >
                      <KeywordToolImportDataRow {...importRow} />
                    </div>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </CardContent>
          </Card>
        )}
      </Container>
    </Layout>
  );
};

export default KeywordToolScreen;
