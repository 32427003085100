import React, { FC } from "react";
import styled from "styled-components";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { Box, Typography } from "@material-ui/core";
import { verifyGoogleAuthOffline } from "../../api";
import { writeStorage } from "@rehooks/local-storage";

const Container = styled.div({
  display: "flex",
  width: "100%",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const LoginScreen: FC = () => {
  const onSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const verifyGoogleAuthResponse = await verifyGoogleAuthOffline(
      (response as GoogleLoginResponseOffline).code
    );

    writeStorage("authToken", verifyGoogleAuthResponse.data.token);
  };

  // TODO: Handle error
  const onFailure = (e: any) => {
    console.log(e);
  };

  return (
    <Container>
      <Typography variant="h3">Procontent</Typography>
      <Box mb={2} />
      <GoogleLogin
        prompt="consent"
        scope="profile email https://www.googleapis.com/auth/webmasters.readonly"
        accessType="offline"
        onSuccess={onSuccess}
        onFailure={onFailure}
        clientId="1024330253166-b6qm6kap7ga53se2fjq12vrtbsnt5g9b"
        responseType="code"
        cookiePolicy="none"
        theme="dark"
      />
    </Container>
  );
};

export default LoginScreen;
