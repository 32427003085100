import { CssBaseline } from "@material-ui/core";
import useLocalStorage from "@rehooks/local-storage";
import React, { FC, useEffect } from "react";
import LoadingOverlay from "./components/LoadingOverlay/LoadingOverlay";
import { StoreContext } from "./contexts";
import Navigation from "./navigation/Navigation";
import { logout } from "./utils/auth";
import startPoller from "./utils/poller";
import { useStore } from "./utils/store";

//let poller = 0;
let poller: NodeJS.Timeout | null;

const App: FC = () => {
  const [authToken] = useLocalStorage("authToken");
  const store = useStore({ authToken });
  useEffect(() => {
    if (store.data.user.error) logout();
  }, [store.data.user.error]);
  useEffect(() => {
    if (authToken && !poller) {
      poller = startPoller(store);
    }
    if (!authToken && poller) {
      clearInterval(poller);
      //poller = 0;
      poller = null;
    }
  }, [authToken, store]);
  const isLoading =
    (store.data.user.isLoading && !Boolean(store.data.user.data)) ||
    store.data.sites.isLoading;
  if (isLoading)
    return (
      <StoreContext.Provider value={store}>
        <CssBaseline />
        <LoadingOverlay />
      </StoreContext.Provider>
    );
  return (
    <StoreContext.Provider value={store}>
      <>
        <CssBaseline />
        <Navigation />
      </>
    </StoreContext.Provider>
  );
};

export default App;
