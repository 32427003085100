import React, { FC } from "react";
import {
  Typography,
  IconButton,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { Close, ArrowBack } from "@material-ui/icons";

interface IMessageDrawerHeaderProps {
  onClickBack?: () => void;
  onClickClose: () => void;
  site?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    textContainer: {},
  })
);

const MessageDrawerHeader: FC<IMessageDrawerHeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Boolean(props.onClickBack) ? (
        <IconButton edge="start" onClick={props.onClickBack}>
          <ArrowBack />
        </IconButton>
      ) : (
        <div>
          <Typography>Messages</Typography>
        </div>
      )}
      <IconButton edge="end" onClick={props.onClickClose}>
        <Close />
      </IconButton>
    </div>
  );
};

export default MessageDrawerHeader;
