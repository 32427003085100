import React, { FC } from "react";
import {
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { API } from "../../api/types";
import { DragIndicator } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

export interface IAttributeManagerProps {
  onChange: (attributes: API.IAttribute[]) => void;
  attributeGroups: API.IAttributeGroup[];
  attributes: API.IAttribute[];
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const isChecked = (attribute: API.IAttribute, attributes: API.IAttribute[]) =>
  Boolean(attributes.find((a) => a.name === attribute.name));

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
  },
  attributeGroups: {
    padding: theme.spacing(2),
    flex: 3,
    marginRight: theme.spacing(),
    overflow: "auto",
    display: "flex",
  },
  attributes: {
    padding: theme.spacing(2),
    flex: 1,
    marginRight: theme.spacing(),
    overflowY: "auto",
    overflowX: "hidden",
    borderLeftWidth: 2,
    borderLeftColor: grey["200"],
    borderLeftStyle: "solid",
  },
  attributeGroupTitle: {},
  attributeGroup: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    minWidth: 250,
    width: 250,
  },
  draggableAttribute: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.75),
    "&:hover": {
      backgroundColor: grey["100"],
      cursor: "pointer",
    },
  },
  draggableAttributeDragIndicator: {
    marginRight: theme.spacing(1),
    color: grey["600"],
  },
}));

const AttributeManager: FC<IAttributeManagerProps> = (props) => {
  const classes = useStyles();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    props.onChange(
      reorder(props.attributes, result.source.index, result.destination.index)
    );
  };

  const onClickAttributeCheckbox = (attribute: API.IAttribute) => (e: any) => {
    if (attribute.required) return;

    const checked = e.target.checked;

    if (checked) return props.onChange([...props.attributes, attribute]);
    if (props.attributes.length === 1) return;
    props.onChange(props.attributes.filter((a) => a.name !== attribute.name));
  };

  const renderDraggableAttribute = (
    attribute: API.IAttribute,
    index: number
  ) => {
    return (
      <Draggable
        key={attribute.name}
        draggableId={attribute.name}
        index={index}
      >
        {(provided) => {
          return (
            <div
              className={classes.draggableAttribute}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <DragIndicator
                className={classes.draggableAttributeDragIndicator}
              />
              <Typography>{attribute.label}</Typography>
            </div>
          );
        }}
      </Draggable>
    );
  };

  const renderAttribute = (attribute: API.IAttribute) => {
    return (
      <div>
        <Tooltip title={attribute.description || ""}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={attribute.required}
                size="small"
                color="primary"
                checked={isChecked(attribute, props.attributes)}
                onChange={onClickAttributeCheckbox(attribute)}
                name={attribute.name}
              />
            }
            label={attribute.label}
          />
        </Tooltip>
      </div>
    );
  };

  const renderAttributeGroups = () => {
    return (
      <div className={classes.attributeGroups}>
        {props.attributeGroups.map((attributeGroup) => {
          return (
            <div key={attributeGroup.label} className={classes.attributeGroup}>
              {attributeGroup.label && (
                <Typography
                  variant="body1"
                  className={classes.attributeGroupTitle}
                >
                  {attributeGroup.label}
                </Typography>
              )}

              {attributeGroup.attributes.map(renderAttribute)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderAttributes = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                className={classes.attributes}
                {...provided.droppableProps}
              >
                {props.attributes.map(renderDraggableAttribute)}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <div className={classes.container}>
      {renderAttributeGroups()}
      {renderAttributes()}
    </div>
  );
};

export default AttributeManager;
