import React, { FC } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Divider,
} from "@material-ui/core";

interface ISingleMessageProps {
  title: string;
  message: string;
  created: string;
  site?: string | null;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const SingleMessage: FC<ISingleMessageProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="body2">{props.title}</Typography>
      {Boolean(props.site) && (
        <Typography variant="body2" color="textSecondary">
          {props.site}
        </Typography>
      )}
      <Typography variant="caption" color="textSecondary">
        {props.created}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="body2">{props.message}</Typography>
    </div>
  );
};

export default SingleMessage;
