import Layout from "../../components/Layout";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";
import React, { FC, useContext } from "react";
import { Card, makeStyles, Container } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { API } from "../../api/types";
import { getAuthHeaders, setTodoNoteDone } from "../../api";
import Todos from "../Page/components/Todos/Todos";
import { TransitionUp } from "../../utils/snackbar";
import { useSnackbar } from "notistack";
import { useFetch } from "../../hooks/useFetch";
import config from "../../config";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { StoreContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  todoCard: {
    paddingTop: "8px",
  },
}));

const TodosScreen: FC = () => {
  const store = useContext(StoreContext);
  const activeSite = store!.data.user.data?.profile.activeSite;

  const notesStore = useFetch<API.ITodoNote[]>({
    axiosConfig: {
      headers: getAuthHeaders(),
      url: `${config.api.url}/todo/usersite/${activeSite}`,
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const onTodoSetDone = async (id: number) => {
    const [err] = await setTodoNoteDone(id);

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    notesStore.refetch(); // Todo refresh page
  };

  const classes = useStyles();

  if (!notesStore.data) return <LoadingOverlay />;

  return (
    <Layout
      backgroundColor={grey["200"]}
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          goBackUrl="/todos"
        />
      )}
    >
      <Container maxWidth="md" className={classes.container}>
        <Card className={classes.todoCard}>
          <Todos
            notes={notesStore.data}
            onTodoSetDone={onTodoSetDone}
            printAllValues={true}
            userSiteId={activeSite}
          />
        </Card>
      </Container>
    </Layout>
  );
};

export default TodosScreen;
