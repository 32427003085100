import to from "await-to-js";

export const grantAnalyticsAccess = () => {
  // All google scopes can be found here: https://developers.google.com/identity/protocols/oauth2/scopes
  const scope = "https://www.googleapis.com/auth/analytics.readonly";

  // When logging in, an auth instance will be initiated on the
  // client. But if the user logs in and refreshes the site, it
  // is still authenticated, but the google auth instance is
  // no longer initiated. Additionally, we don't want to
  // initiate an instance if it's already initiated. Therefore,
  // we have to check if an instance is created, and use that
  // instead. If it's not, we create a new one.
  let auth2 = (window as any).pgapi.auth2.getAuthInstance();
  if (!auth2) {
    auth2 = (window as any).pgapi.auth2.init({
      client_id:
        "1024330253166-b6qm6kap7ga53se2fjq12vrtbsnt5g9b.apps.googleusercontent.com",
      cookiepolicy: "single_host_origin",
      scope,
    });
  }
  const options = {
    prompt: "consent",
    scope,
  };
  const googleUser = auth2.currentUser.get();
  return to<{ code: string }, any>(googleUser.grantOfflineAccess(options));
};
