import { makeStyles, createStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useTableStyles = makeStyles((theme) =>
  createStyles({
    tableContainer: {
      maxWidth: "100%",
      overflow: "auto",
      flex: 1,
    },
    headText: { whiteSpace: "nowrap" },
    table: {
      //position: "relative",
    },
    bodyCell: {
      verticalAlign: "top",
    },
    bodyCellContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
    iconContainer: {
      marginRight: theme.spacing(),
      display: "flex",
      alignItems: "center",
    },
    clickableBodyRow: {
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.025)",
        cursor: "pointer",
      },
    },
    headerButtonsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      right: 0,
      zIndex: 2,
      backgroundColor: grey["50"],
    },
  })
);

export default useTableStyles;
