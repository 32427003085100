import {
  Box,
  Button,
  Chip,
  createStyles,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { OpenInBrowser } from "@material-ui/icons";
import React, { FC, useContext } from "react";
import { API } from "../../../api/types";
import Container from "../../../components/Container/Container";
import HorizontalTable from "../../../components/HorizontalTable/HorizontalTable";
import LinkCard from "../../Links/components/LinkCard";
import { linkFunnelContext } from "../utils/contexts";
import LinkComponent from "./LinkComponent";
import Notes from "./Notes";

const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const humanizeAttributeName = (attributeName: string) => {
  return attributeName.split("_").map(capitalize).join(" ");
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
    majesticLink: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginTop: -12,
      marginBottom: 12,
    },
    archiveLink: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  })
);

const linkToMajesticObject = (link: API.Outreach.Link) => {
  return {
    ext_back_links: link.majestic_ext_back_links,
    ref_domains: link.majestic_ref_domains,
    ref_ips: link.majestic_ref_ips,
    affected_by_anti_spam: link.majestic_affected_by_anti_spam,
    citation_flow: link.majestic_citation_flow,
    trust_flow: link.majestic_trust_flow,
    trust_metric: link.majestic_trust_metric,
    topical_trust_flow: link.majestic_topical_trust_flow,
  };
};

const StatusAnalyzePending: FC = () => {
  const classes = useStyles();
  const {
    linkStore,
    isPreview,
    onClickCancel,
    onClickProceed,
    addNote,
    deleteNote,
  } = useContext(linkFunnelContext)!;
  const isSettled = ["accepted", "analyze_no_go", "declined"].includes(
    linkStore.data.status
  );

  const rows = Object.entries(linkToMajesticObject(linkStore.data))
    .map(([key, value]) => {
      const shouldRenderTags = key === "topical_trust_flow" && Boolean(value);

      if (shouldRenderTags) {
        const tags = Object.entries(value as string).map(([key, value]) => {
          return `${key}: ${value}`;
        });

        return {
          label: humanizeAttributeName(key),
          value: value as any,
          renderValue: shouldRenderTags
            ? () => (
                <>
                  {tags.map((tag) => (
                    <Chip
                      key={tag}
                      style={{ marginRight: 6, marginBottom: 6 }}
                      label={tag}
                    />
                  ))}
                </>
              )
            : null,
        };
      }

      return {
        label: humanizeAttributeName(key),
        value: value as any,
      };
    })

    .filter(({ value }) => value !== null);

  const onClickGoToArchive = () => {
    window.open(
      `https://web.archive.org/web/*/${linkStore.data.from_url}`,
      "_blank"
    );
  };

  const onClickGoToMajestic = () => {
    window.open(
      `https://majestic.com/reports/site-explorer?folder=&q=${linkStore.data.from_url}&IndexDataSource=F`,
      "_blank"
    );
  };

  const onAddNote = (data: { note: string }) => {
    addNote({ text: data.note, link_id: linkStore.data.id });
  };

  const onDeleteNote = (note: API.Outreach.ILinkComment) => {
    deleteNote(note.id);
  };

  return (
    <Container>
      <LinkCard disableActions showData={true} link={linkStore.data} />
      {linkStore.data.archive_age_first_snapshot && (
        <>
          <Box mb={2} />
          <LinkComponent title="General">
            <HorizontalTable
              rows={[
                {
                  label: (
                    <>
                      <Typography variant="body2">
                        Archive Age First Snapshot
                      </Typography>
                      <Link
                        onClick={onClickGoToArchive}
                        className={classes.archiveLink}
                      >
                        <OpenInBrowser />
                        <Box mr="3px" />
                        View on Archive.org
                      </Link>
                    </>
                  ),
                  value: linkStore.data.archive_age_first_snapshot,
                },
              ]}
            />
          </LinkComponent>
        </>
      )}
      <Box mb={2} />
      <LinkComponent title="Majestic">
        {Boolean(rows.length) ? (
          <>
            <Link
              className={classes.majesticLink}
              onClick={onClickGoToMajestic}
            >
              <OpenInBrowser />
              <Box mr={"3px"} />
              View on Majestic
            </Link>
            <HorizontalTable rows={rows} />
          </>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No Majestic data.
          </Typography>
        )}
      </LinkComponent>
      <Box mb={2} />
      <LinkComponent title="Notes">
        <Notes
          onDeleteNote={onDeleteNote}
          onAddNote={onAddNote}
          notes={linkStore.data.notes}
        />
      </LinkComponent>
      <Box mb={2} />
      {!isSettled && !isPreview && (
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={onClickCancel(linkStore.data)}
          >
            Decline
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            onClick={onClickProceed(linkStore.data)}
          >
            Continue
          </Button>
        </div>
      )}
    </Container>
  );
};

export default StatusAnalyzePending;
