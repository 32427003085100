import React, { FC } from "react";
import { API } from "../../../api/types";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dataWrapper: {
    display: "flex",
  },
  datum: {
    flex: 1,
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #ccc",
    },
  },
  datumHeading: {
    position: "absolute",
    fontSize: "10px",
    textTransform: "uppercase",
    left: "10px",
    top: "5px",
    color: "#aaa",
  },
}));

const TrendingRelatedPageKeyword: FC<API.Trends.ITrendingRelatedKeywordsRelatedData> = (
  props
) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <a
          href={"https://trends.google.com" + props.link}
          target="_blank"
          rel="noreferrer"
        >
          {props.query}
        </a>
      </div>
      <div className={classes.dataWrapper}>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Increase</div>
          <Typography variant="body2">{props.formattedValue}</Typography>
        </div>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Volume</div>
          <Typography variant="body2">
            {typeof props.searches != "undefined" && props.searches !== null ? (
              <>{props.searches}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
        {/*
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Competition level</div>
        </div>
        */}
        <div className={classes.datum}>
          <div className={classes.datumHeading}>CPC</div>
          <Typography variant="body2">
            {typeof props.CPC != "undefined" && props.CPC !== null ? (
              <>{props.CPC}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default TrendingRelatedPageKeyword;
