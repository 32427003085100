import React, { FC } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

const PSwitch: FC<{ label: string; name: string; formik: any }> = (props) => {
  return (
    <FormControlLabel
      onChange={(e) =>
        props.formik.setFieldValue(props.name, (e.target as any).checked)
      }
      value={Boolean(props.formik.values[props.name])}
      control={
        <Switch
          color="primary"
          checked={Boolean(props.formik.values[props.name])}
        />
      }
      label={props.label}
    />
  );
};

export default PSwitch;
