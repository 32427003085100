import React, { FC } from "react";
import {
  Card,
  Typography,
  Box,
  makeStyles,
  createStyles,
  Tooltip,
  CardContent,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  AccountCircle,
  Email,
  Phone,
  ContactPhone,
  Notes,
} from "@material-ui/icons";
import { API } from "../../../api/types";

interface IContactCardProps {
  contact: API.Outreach.IContact;
  onClick: () => void;
  outlined?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: grey["50"],
      },
    },
    attributeContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    attributeIcon: {
      fontSize: 18,
      color: grey["700"],
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    divider: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      width: "100%",
    },
    attributeValue: { wordBreak: "break-all" },
    section: {
      padding: theme.spacing(2),
    },
    borderLeft: {
      borderLeftWidth: 1,
      borderLeftColor: grey["300"],
      borderLeftStyle: "solid",
    },
  })
);

const Attribute: FC<{ label: string; icon?: any; value: any }> = (props) => {
  const classes = useStyles();

  if (!props.value) return null;

  return (
    <Tooltip title={props.label} placement="left" aria-label="add">
      <div className={classes.attributeContainer}>
        {props.icon || <Box mr="26px" />}
        {props.icon && <Box mr={1} />}
        <Typography
          variant="body2"
          className={classes.attributeValue}
          color="textSecondary"
        >
          {props.value}
        </Typography>

        <Box mb={3} />
      </div>
    </Tooltip>
  );
};

const ContactCard: FC<IContactCardProps> = (props) => {
  const classes = useStyles();
  return (
    <Card
      variant={props.outlined ? "outlined" : "elevation"}
      onClick={props.onClick}
      classes={{ root: classes.card }}
    >
      <CardContent>
        <Attribute
          label="Name"
          value={props.contact.name}
          icon={<AccountCircle className={classes.attributeIcon} />}
        />
        <Attribute
          label="Email"
          value={props.contact.email}
          icon={<Email className={classes.attributeIcon} />}
        />
        <Attribute
          label="Phone"
          value={props.contact.phone}
          icon={<Phone className={classes.attributeIcon} />}
        />
        <Attribute
          label="Skype"
          value={props.contact.skype}
          icon={<ContactPhone className={classes.attributeIcon} />}
        />
        <Attribute
          label="Note"
          value={props.contact.notes}
          icon={<Notes className={classes.attributeIcon} />}
        />
      </CardContent>
    </Card>
  );
};

export default ContactCard;
