import React, { FC } from "react";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";

interface ISearchFieldProps {
  // onClickQuery: (query: IQuery) => void;
  // onDeleteQuery: (query: IQuery) => void;
  onClickSaveQuery: () => void;
  // recentQueries: IQuery[];
  // savedQueries: IQuery[];
  textFieldProps?: TextFieldProps;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 550,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  inputAdornment: {
    // pointerEvents: "none",
  },
}));

const SearchField: FC<ISearchFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        className={classes.root}
        label="Search"
        variant="filled"
        size="small"
        {...((props.textFieldProps as any) || {})}
        InputProps={{
          endAdornment: (
            <InputAdornment
              classes={{ root: classes.inputAdornment }}
              position="end"
            >
              <IconButton edge="end" onClick={props.onClickSaveQuery}>
                <Save />
              </IconButton>
            </InputAdornment>
          ),
          autoComplete: "off",
          ...(props.textFieldProps?.InputProps || {}),
        }}
      />
    </>
  );
};

export default SearchField;
