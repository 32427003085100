export class ParseSiteIntervalManager {
  private static delay = 5000;

  static timer: NodeJS.Timeout;
  static state = {
    done: 0,
    total: 0,
    isVisible: false,
    pollsWithoutProgress: 0,
    lastPolled: new Date(),
  };
  static cb = () => {};

  static start = (cb: () => void) => {
    cb = ParseSiteIntervalManager.cb = cb;
    ParseSiteIntervalManager.timer = setInterval(
      cb,
      ParseSiteIntervalManager.delay
    );
  };

  static clear = () => {
    clearInterval(ParseSiteIntervalManager.timer);

    // invoke the callback one last time after clearing
    setTimeout(ParseSiteIntervalManager.cb, ParseSiteIntervalManager.delay);
  };

  static setState = (state: {
    done: number;
    total: number;
    isVisible: boolean;
    pollsWithoutProgress: number;
    lastPolled: Date;
  }) => (ParseSiteIntervalManager.state = state);
}
