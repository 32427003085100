import { OptionsObject } from "notistack";
import { TransitionUp } from "./snackbar";

// Utility function only meant to be used under development.
// Shows a snackbar saying "Feature not implemented"
export const featureNotImplemented = (
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined
  ) => React.ReactText
) => {
  return enqueueSnackbar("Feature not implemented.", {
    variant: "info",
    TransitionComponent: TransitionUp,
    anchorOrigin: { horizontal: "center", vertical: "bottom" },
  });
};
