import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  createStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import { getError, getHelperText } from "../../utils/form";
import AttributeManager from "../../components/AttributeManager/AttributeManager";
import { API } from "../../api/types";

interface ISiteDialogProps {
  onClose: () => void;
  onAdd: (data: {
    attributes: API.IAttribute[];
    name: string;
    query: string;
  }) => void;
  open: boolean;

  query: string;
  attributeGroups: API.IAttributeGroup[];
  attributes: API.IAttribute[];
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(2),
    },
    form: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
    },
    attributeManagerContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
    },
    textFieldContainer: {
      maxWidth: 600,
      marginBottom: theme.spacing(1),
    },
  })
);

const AddQueryDialog: FC<ISiteDialogProps> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    onSubmit: (values, { resetForm }) => {
      props.onAdd(values);
      resetForm();
    },
    validate: (values) => {
      if (!values.name)
        return {
          name: "Required field.",
        };

      if (!values.query)
        return {
          query: "Required field",
        };

      return {};
    },
    initialValues: {
      name: "",
      query: props.query,
      attributes: props.attributes,
    },
    enableReinitialize: true,
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    formik.submitForm();
  };

  const onClose = () => {
    props.onClose();
  };

  const onChangeAttributeManager = (attributes: API.IAttribute[]) => {
    formik.setFieldValue("attributes", attributes);
  };

  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      open={props.open}
      onClose={props.onClose}
    >
      <form onSubmit={onSubmit} className={classes.form}>
        <DialogTitle id="form-dialog-title">Save query</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.textFieldContainer}>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              fullWidth
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
              name="name"
              value={formik.values.name}
              error={getError(formik as any, "name")}
              helperText={getHelperText(formik as any, "name")}
            />
            <TextField
              margin="dense"
              label="Query"
              fullWidth
              onChange={(e) => formik.setFieldValue("query", e.target.value)}
              name="query"
              value={formik.values.query}
              error={getError(formik as any, "query")}
              helperText={getHelperText(formik as any, "query")}
            />
          </div>
          <div className={classes.attributeManagerContainer}>
            <AttributeManager
              onChange={onChangeAttributeManager}
              attributes={formik.values.attributes}
              attributeGroups={props.attributeGroups}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddQueryDialog;
