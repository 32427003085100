import React, { FC, useState } from "react";
import { API } from "../../../api/types";
import useInterval from "@use-it/interval";
import { Box, IconButton, Typography } from "@material-ui/core";
import { formatDistanceToNow } from "date-fns";
import { Delete } from "@material-ui/icons";

const NoteComment: FC<{
  note: API.Outreach.ILinkComment;
  onClickDelete?: () => void;
}> = (props) => {
  // doing this to force rerender in order to update dates
  // without forcing the user to refresh
  const [, setLastRerendered] = useState(new Date());
  useInterval(() => setLastRerendered(new Date()), 5000);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            color="textPrimary"
            variant="body2"
            style={{ fontWeight: "bold" }}
          >
            {props.note.name}
          </Typography>
          <Box ml={1} />
          <Typography variant="caption" color="textSecondary">
            {formatDistanceToNow(new Date(props.note.created_at), {
              addSuffix: true,
            })}
          </Typography>
        </div>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {props.note.text}
        </Typography>
      </div>

      {Boolean(props.onClickDelete) && (
        <IconButton
          size="small"
          style={{ marginLeft: "auto" }}
          onClick={props.onClickDelete}
        >
          <Delete />
        </IconButton>
      )}
    </div>
  );
};

export default NoteComment;
