import { createContext } from "react";
import { API } from "../../../api/types";
import { IUseFetchReturn } from "../../../hooks/useFetch";

export interface IFormData {
  analyze_pending: {
    contact_id: number | null;
  };
}

interface ILinkFunnelContext {
  isPreview: boolean;
  isDeclined: boolean;

  contactsStore: IUseFetchReturn<API.Outreach.IContact[]>;
  linkStore: IUseFetchReturn<API.Outreach.Link>;

  addContact: (contact: API.Outreach.ICreateContact) => void;
  addNote: (note: API.Outreach.ICreateLinkComment) => void;
  deleteNote: (noteId: number) => void;
  setLink: (data: any) => void;
  setContactId: (contactId: number) => Promise<any>;
  onClickProceed: (
    link: API.Outreach.Link
  ) => () => Promise<string | number | undefined>;
  onClickPrevious: (
    link: API.Outreach.Link
  ) => () => Promise<string | number | undefined>;
  onClickCancel: (link: API.Outreach.Link) => () => Promise<void>;
  onClickHold: (link: API.Outreach.Link) => () => Promise<void>;

  formData: IFormData;
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>;
}

export const linkFunnelContext = createContext<ILinkFunnelContext | null>(null);
