import React, { ChangeEvent, FC, useContext, useState } from "react";
import { API } from "../../../api/types";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { downloadNoteAttachment, uploadNoteAttachment } from "../../../api";
import { TransitionUp } from "../../../utils/snackbar";
import { useSnackbar } from "notistack";
import { linkFunnelContext } from "../utils/contexts";
import NoteComment from "./NoteComment";

interface INoteProps {
  onDeleteNote?: (note: API.Outreach.ILinkComment) => void;
  note: API.Outreach.ILinkComment;
}

const useStyles = makeStyles((theme) => ({
  btnChoose: {
    marginBottom: "4px",
  },
  btnUpload: {
    marginBottom: "16px",
  },
  uploadButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  fileName: {
    marginBottom: "4px",
  },
  uploadedFile: {
    margin: "8px 0",
  },
  downloadLink: {
    textDecoration: "underline",
    color: "#0d47a1",
    cursor: "pointer",
  },
}));

const Note: FC<INoteProps> = (props) => {
  const classes = useStyles();

  const { linkStore } = useContext(linkFunnelContext)!;

  const [selectedFile, setSelectedFile] = useState<File | null>();
  const { enqueueSnackbar } = useSnackbar();

  const uploadFile = async () => {
    if (selectedFile) {
      const [err] = await uploadNoteAttachment({
        file: selectedFile,
        note_id: props.note.id,
      });

      if (err) {
        const message = err?.response?.data?.error || err?.message;

        enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        });
      } else {
        enqueueSnackbar("File uploaded.", {
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        });
      }

      setSelectedFile(null);
      await linkStore.refetch();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  let attachmentModified = "";
  if (props.note.attachment_modified) {
    const date = new Date(props.note.attachment_modified! * 1e3);
    attachmentModified = date.toLocaleString();
  }

  return (
    <>
      <NoteComment
        onClickDelete={
          Boolean(props.onDeleteNote)
            ? () => props.onDeleteNote!(props.note)
            : undefined
        }
        note={props.note}
      />
      {attachmentModified ? (
        <div className={classes.uploadedFile}>
          <Typography color="textPrimary" variant="body2">
            File uploaded: {attachmentModified}&nbsp;-&nbsp;
            <span
              className={classes.downloadLink}
              onClick={() =>
                downloadNoteAttachment({
                  note_id: props.note.id,
                  file_name: props.note.attachment_original_filename!,
                })
              }
            >
              {props.note.attachment_original_filename}
            </span>
          </Typography>
        </div>
      ) : (
        <div className={classes.uploadButtons}>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: "none" }}
              type="file"
              onChange={(event) => {
                handleFileChange(event);
              }}
            />
            <Button
              className={classes.btnChoose}
              variant="outlined"
              component="span"
            >
              Choose File
            </Button>
          </label>
          <div className={classes.fileName}>
            {selectedFile ? selectedFile.name : null}
          </div>
          <Button
            className={classes.btnUpload}
            color="primary"
            variant="contained"
            component="span"
            disabled={!selectedFile}
            onClick={uploadFile}
          >
            Upload
          </Button>
        </div>
      )}
    </>
  );
};

export default Note;
