import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { FC } from "react";
import tinycolor from "tinycolor2";
import { API } from "../../api/types";
import { getStatusColor, getStatusPresentationName } from "./utils";

interface IStatusFilterProps {
  activeStatus: API.Outreach.Link["status"] | null;
  statusCountMap: { [key: string]: number };
  onClickStatus: (status: API.Outreach.Link["status"]) => void;
}

const size = 50;

const statuses: API.Outreach.Link["status"][] = [
  "analyze_pending",
  "contact",
  "awaiting_response",
  "negotiating",
  "on_hold",
  "accepted",
  "declined",
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  statusRingContainer: {
    transition: "150ms ease-out",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  statusRing: {
    width: size,
    height: size,
    borderRadius: size,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 4,
    borderStyle: "solid",
  },
}));

const StatusRing: FC<{
  amount: number;
  status: API.Outreach.Link["status"];
  isActive: boolean;
  onClick: () => void;
}> = (props) => {
  const classes = useStyles();
  return (
    <Tooltip title={getStatusPresentationName(props.status)}>
      <div onClick={props.onClick} className={classes.statusRingContainer}>
        <div
          className={classes.statusRing}
          style={{
            borderColor: getStatusColor(props.status),
            backgroundColor: props.isActive
              ? tinycolor(getStatusColor(props.status))
                  .setAlpha(0.2)
                  .toRgbString()
              : "white",
          }}
        >
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {props.amount || 0}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
};

const StatusFilter: FC<IStatusFilterProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {statuses.map((status, index) => {
        if (!props.statusCountMap[status]) return null;

        const isLast = index === statuses.length - 1;

        return (
          <React.Fragment key={status}>
            <StatusRing
              onClick={() => props.onClickStatus(status)}
              amount={props.statusCountMap[status]}
              isActive={props.activeStatus === status}
              status={status}
            />
            {!isLast && <Box mr={1} />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default StatusFilter;
