import React, { FC, useContext } from "react";
import { useFormik } from "formik";
import { Button, Box, Grid } from "@material-ui/core";
import PTextField from "../../../components/Form/PTextField";
import PSwitch from "../../../components/Form/PSwitch";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { linkFunnelContext } from "../utils/contexts";

const TheirCommitmentForm: FC = () => {
  const { linkStore, setLink } = useContext(linkFunnelContext)!;

  const formik = useFormik({
    initialValues: {
      settled_from_url:
        linkStore.data.settled_from_url || linkStore.data.from_url || "",
      to_url: linkStore.data.to_url || "",
      is_image_link: linkStore.data.is_image_link || 0,
      is_nofollow: linkStore.data.is_nofollow || 0,
      link_anchor: linkStore.data.link_anchor || "",
    },
    onSubmit: (values) => {
      setLink(values);
    },
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PTextField
              formik={formik}
              name="settled_from_url"
              label="From URL"
            />
            <PTextField formik={formik} name="to_url" label="To URL" />
            <PTextField
              formik={formik}
              name="link_anchor"
              label="Anchor Text"
            />
            <Box mt={1} />
            <PSwitch formik={formik} name="is_nofollow" label="No Follow" />
            <div />
            <PSwitch formik={formik} name="is_image_link" label="Image Link" />
          </Grid>
        </Grid>
        <div style={{ display: "flex" }}>
          <Button
            type="submit"
            style={{ marginLeft: "auto" }}
            variant="outlined"
            color="primary"
          >
            Save
          </Button>
        </div>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default TheirCommitmentForm;

// from_url, to_url, is_image_link, is_nofollow, link_anchor;
