import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { API } from "../../api/types";
import { checkIsValidURL } from "../../utils/checkIsValidURL";
import { getError, getHelperText } from "../../utils/form";

interface IAddSiteDialogProps {
  onClose: () => void;
  onAdd: (values: API.ICreateSite) => void;
}

const useStyles = makeStyles({
  formControl: {
    width: 400,
  },
});

const AddSiteDialog: FC<IAddSiteDialogProps> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    validate: async (values) => {
      if (!checkIsValidURL(values.index_url))
        return {
          index_url: "The URL is not valid.",
        };

      return {};
    },
    onSubmit: (values) => {
      props.onAdd(values);
    },
    initialValues: { index_url: "" } as API.ICreateSite,
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    formik.submitForm();
  };

  return (
    <Dialog open={true} onClose={props.onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">Add Site</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              label="URL"
              fullWidth
              onChange={(e) =>
                formik.setFieldValue("index_url", e.target.value)
              }
              name="URL"
              value={formik.values.index_url}
              helperText={getHelperText(formik as any, "index_url")}
              error={getError(formik as any, "index_url")}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add Site
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSiteDialog;
