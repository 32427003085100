import React, { FC } from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";

interface IMessageProps {
  message: string;
  created: string;
  onClick: () => void;
  isRead: boolean;
  site?: string | null;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),

      "&:hover": {
        backgroundColor: grey["100"],
        cursor: "pointer",
      },
    },
    bold: {
      fontWeight: "bold",
    },
  })
);

const Message: FC<IMessageProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={() => props.onClick()}>
      <Typography
        variant="body2"
        className={clsx({ [classes.bold]: !props.isRead })}
      >
        {props.message}
      </Typography>
      {Boolean(props.site) && (
        <Typography variant="body2" color="textSecondary">
          {props.site}
        </Typography>
      )}
      <Typography variant="caption" color="textSecondary">
        {props.created}
      </Typography>
    </div>
  );
};

export default Message;
