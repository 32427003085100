import React, { FC, FormEventHandler, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import SiteTable from "../../../../components/SiteTable/SiteTable";
import {
  ISortSettings,
  PageAttribute,
} from "../../../../components/SiteTable/types";
import { useFormik } from "formik";
import { API } from "../../../../api/types";
import {
  attributeTranslationMap,
  attributeHelpTextMap,
} from "../../../../components/SiteTable/utils";
import { getError, getHelperText } from "../../../../utils/form";
import { checkIsValidURL } from "../../../../utils/checkIsValidURL";

interface ILinkCompetitorsTabProps {
  page: API.IPage;
  onAddLink: (data: {
    competitor_page_id?: number;
    page_id: number;
    competitor_url?: string;
  }) => void;
  onRemoveLink: (link: API.ILink) => void;
  onGotoLink: (link: API.ILink) => void;
}

const useStyles = makeStyles((theme) => ({
  addSiteButton: {
    height: 48,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  addSiteContainer: {
    display: "flex",
  },
  addSiteField: {
    maxWidth: 600,
    flex: 1,
  },
}));

const LinkCompetitors: FC<ILinkCompetitorsTabProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [activeLink, setActiveLink] = React.useState<{
    target: null | HTMLElement;
    link: API.ILink;
  } | null>(null);

  const [sortSettings, setSortSettings] = useState<ISortSettings>({
    attribute: "title",
    direction: "asc",
  });

  const onClickSort = (attribute: PageAttribute) => {
    if (attribute === sortSettings.attribute)
      return setSortSettings({
        attribute,
        direction: sortSettings.direction === "asc" ? "desc" : "asc",
      });

    return setSortSettings({ attribute, direction: "asc" });
  };

  const formik = useFormik({
    initialValues: { url: "" },
    validate: async (values) => {
      if (!checkIsValidURL(values.url)) {
        return {
          url: "The URL is invalid.",
        };
      }
      return {};
    },
    onSubmit: async (values, { resetForm, setErrors }) => {
      props.onAddLink({ competitor_url: values.url, page_id: props.page.id });
      resetForm();
    },
  });

  const onClickActions = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    link: API.ILink
  ) => {
    setActiveLink({ target: event.currentTarget, link });
  };

  const onRemoveLink = async (link: API.ILink) => {
    if (window.confirm("Remove the competitor URL?")) {
      props.onRemoveLink(link);
    }
    setActiveLink(null);
  };

  const onGotoLink = (link: API.ILink) => {
    props.onGotoLink(link);
    setActiveLink(null);
  };

  const onCloseActionMenu = () => setActiveLink(null);

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    formik.submitForm();
  };

  const ActionMenu = (
    <Menu
      anchorEl={activeLink?.target}
      keepMounted
      open={Boolean(activeLink?.target)}
      onClose={onCloseActionMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{
        vertical: -theme.spacing(6),
        horizontal: "center",
      }}
    >
      <MenuItem onClick={() => onRemoveLink(activeLink!.link)}>Remove</MenuItem>
      <MenuItem onClick={() => onGotoLink(activeLink!.link)}>
        Go to external page
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <form className={classes.addSiteContainer} onSubmit={onSubmit}>
        <TextField
          placeholder="https://example.com/"
          className={classes.addSiteField}
          size="small"
          variant="filled"
          label="URL"
          onChange={formik.handleChange}
          name="url"
          value={formik.values["url"]}
          helperText={getHelperText(formik as any, "url")}
          error={getError(formik as any, "url")}
        />
        <Button
          className={classes.addSiteButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          Add
        </Button>
      </form>

      <Box mb={2} />

      {props.page?.links?.length ? (
        <SiteTable
          attributeHelpTextMap={attributeHelpTextMap}
          hideHeader
          height="auto"
          onClickSort={onClickSort}
          sort={{
            attribute: sortSettings.attribute,
            direction: sortSettings.direction,
          }}
          attributes={["url"]}
          attributeTranslationMap={attributeTranslationMap}
          pages={props.page.links || []}
          ActionMenu={ActionMenu}
          onClickActions={onClickActions}
        />
      ) : (
        <Typography variant="body2" color="textSecondary">
          There are no linked pages.
        </Typography>
      )}
    </>
  );
};

export default LinkCompetitors;
