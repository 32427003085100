import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import Layout from "../Layout";
import AppBarToolbar from "../AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";

interface IErrorProps {
  message: string;
}

const Error: FC<IErrorProps> = (props) => {
  return (
    <Layout
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          goBackUrl="/sites"
        />
      )}
    >
      <Typography>{props.message}</Typography>
    </Layout>
  );
};

export default Error;
