// Temporary component since it's going to be a lot of changes.

import React, { FC, useContext, useMemo, useState } from "react";
import { API } from "../../../../api/types";
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  TextField,
  //FormControl,
  //FormControlLabel,
  //FormLabel,
  //Radio,
  //RadioGroup,
  Typography,
} from "@material-ui/core";
import Query from "./Query";
import { IUseFetchReturn, useFetch } from "../../../../hooks/useFetch";
import config from "../../../../config";
import { StoreContext } from "../../../../contexts";
import {
  addKeyword,
  getAuthHeaders,
  refetchGSCWeek,
  removeKeyword,
} from "../../../../api";
import { useSnackbar } from "notistack";
import { TransitionUp } from "../../../../utils/snackbar";
import Totals from "./Totals";
import { useFormik } from "formik";
import KeywordAnalyzeDialog from "./KeywordAnalyzeDialog";
//import { blue, green, orange, purple } from "@material-ui/core/colors";

interface IGSCProps {
  pageStore: IUseFetchReturn<API.IPage>;
  site: API.ISite;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    showMoreButton: {
      cursor: "pointer",
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    paginationContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexDirection: "column",
      marginTop: theme.spacing(1),
    },
    searchForm: {
      width: "100%",
      maxWidth: 600,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    searchTextField: {
      marginRight: theme.spacing(1),
    },
  })
);

const escapeRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const matchStringQuery = (query: string | number, value: string) =>
  Boolean(
    new RegExp(
      "^" +
        escapeRegExp(String(query).replace(/"/g, "")).replace(/%/g, ".*") +
        "$"
    ).exec(value)?.length
  );

const isOperator = (string: string) => [":", "<", ">"].includes(string);

const validateSearchQuery = (searchQuery: string) => {
  if (searchQuery === "") return true;

  return searchQuery
    .replace(/ /g, "")
    .split(",")
    .every((string) => {
      const regex = /(?<attribute>[^(:|<|>)]+)(?<operator>.)(?<value>.*)$/;
      const { operator, value, attribute } = string.match(regex)!.groups! as {
        attribute: string;
        operator: string;
        value: string;
      };

      return (
        operator && isOperator(operator) && attribute && value !== undefined
      );
    });
};

const attributeTranslationMap: any = {
  week: "year_week",
  pos: "position",
  imps: "impressions",
};

const getCompiledQuery = (searchQuery: string) => {
  return searchQuery
    .replace(/ /g, "")
    .split(",")
    .map((string) => {
      const regex = /(?<attribute>[^(:|<|>)]+)(?<operator>.)(?<value>.*)$/;
      const { operator, value, attribute } = string.match(regex)!.groups! as {
        attribute: string;
        operator: string;
        value: string;
      };

      return {
        attribute:
          attributeTranslationMap?.[attribute.toLowerCase()] ||
          attribute.toLowerCase(),
        value: isNaN(Number(value)) ? value : Number(value),
        operator,
      };
    })
    .filter(({ attribute }) => {
      return attribute !== "sort";
    });
};

const getFilteredGSCPages = (
  searchQuery: string,
  pages: API.ISearchConsoleEntries[]
) => {
  if (searchQuery === "") return pages;
  const compiledQuery = getCompiledQuery(searchQuery);
  const filteredPages = pages.filter((page) => {
    return page.data.some((week) => {
      return compiledQuery.every((condition) => {
        const value = (week as any)[condition.attribute];
        if (condition.operator === ":")
          return matchStringQuery(condition.value as string, value);
        if (condition.operator === "<") return condition.value > value;
        if (condition.operator === ">") return condition.value < value;
        return true;
      });
    });
  });
  return filteredPages;
};

const getQueryWeekResult = (
  searchQuery: string,
  pages: API.ISearchConsoleEntries[]
) => {
  if (searchQuery === "") return {};
  const weeks: any = {};
  const compiledQuery = getCompiledQuery(searchQuery);
  pages.forEach((page) => {
    page.data.forEach((week) => {
      let attributes: string[] = [];
      const match = compiledQuery.every((condition) => {
        const value = (week as any)[condition.attribute];
        const foundResultEquals =
          condition.operator === ":" &&
          matchStringQuery(condition.value as string, value);
        const foundResultLesserThan =
          condition.operator === "<" && condition.value > value;
        const foundResultGreaterThan =
          condition.operator === ">" && condition.value < value;
        const foundResult =
          foundResultEquals || foundResultLesserThan || foundResultGreaterThan;
        if (foundResult) attributes.push(condition.attribute);
        return foundResult;
      });
      if (match) weeks[`${page.query}-${week.year_week}`] = attributes.slice();
      attributes = [];
    });
  });
  return weeks;
};

const sortPages = (
  sortSettings: { direction: "ASC" | "DESC"; attribute: string },
  pages: API.ISearchConsoleEntries[]
) => {
  const { direction } = sortSettings;
  const attribute =
    attributeTranslationMap?.[sortSettings.attribute.toLowerCase()] ||
    sortSettings.attribute.toLowerCase();

  return pages.sort((a, b) => {
    if ((b.data[0] as any)[attribute] === (a.data[0] as any)[attribute])
      return b.data[0].position! < a.data[0].position! ? 1 : -1;
    return (
      (((b.data[0] as any)[attribute] || 0) >
      ((a.data[0] as any)[attribute] || 0)
        ? 1
        : -1) * (direction === "ASC" ? -1 : 1)
    );
  });
};

const getSortValue = (searchQuery: string) => {
  const regex = /sort:(?<value>(.*?))(,| |$)/g;
  return regex.exec(searchQuery)?.groups?.value?.replace(/"/g, "");
};

const getSortSettingsFromValue = (sortValue: string) => {
  const direction: "DESC" | "ASC" = sortValue.startsWith("-") ? "DESC" : "ASC";

  return {
    direction,
    attribute: sortValue.replace("-", ""),
  };
};

const getSite = (sites: API.ISite[], id: number) => {
  return sites?.find((site) => site?.id === id)!;
};

const GSC: FC<IGSCProps> = (props) => {
  const [sortSettings, setSortSettings] = useState<{
    attribute: string;
    direction: "ASC" | "DESC";
  }>({
    direction: "DESC",
    attribute: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values) => {
      if (!validateSearchQuery(values.search))
        enqueueSnackbar("Invalid search query", {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        });
      else {
        setSearchQuery(values.search);
        setGSCDataLimit(10);
        const sortValue = getSortValue(values.search);
        if (sortValue) setSortSettings(getSortSettingsFromValue(sortValue));
        else setSortSettings({ direction: "DESC", attribute: "" });
      }
    },
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const store = useContext(StoreContext);
  const activeSite = store!.data.user?.data?.profile?.activeSite;
  const site = getSite(store!.data.sites.data, activeSite);
  const gscData = props.pageStore.data.search_console_data!;
  const [GSCDataLimit, setGSCDataLimit] = useState(10);
  const canRefetchStore = useFetch<boolean>({
    axiosConfig: {
      url: `${config.api.url}/searchconsole/searchanalytics/can-user-refetch/${site.site_id}`,
      headers: getAuthHeaders(),
    },
  });
  const pages = useMemo(() => {
    const filteredPages = getFilteredGSCPages(
      searchQuery,
      gscData?.pages || []
    );
    if (sortSettings.attribute) return sortPages(sortSettings, filteredPages);
    return filteredPages;
  }, [searchQuery, gscData?.pages, sortSettings]);

  const weekResult = useMemo(() => {
    return getQueryWeekResult(searchQuery, gscData?.pages || []);
  }, [searchQuery, gscData?.pages]);

  const onClickRefresh = async (yearWeek: string) => {
    const [err] = await refetchGSCWeek(yearWeek, site.site_id);
    const message =
      err?.response?.data?.error ||
      err?.message ||
      `Successfully initiated refetch of ${yearWeek}`;

    enqueueSnackbar(message, {
      variant: err ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });
  };

  const onClickShowMore = () => {
    setGSCDataLimit(GSCDataLimit + 10);
  };

  const onClickStar = async (query: API.ISearchConsoleEntries) => {
    const hasQuery = (props.pageStore.data?.keywords?.keywords || []).includes(
      query.query
    );
    const newKeywords = hasQuery
      ? (props.pageStore.data?.keywords?.keywords || []).filter(
          (keyword) => keyword !== query.query
        )
      : [...(props.pageStore.data?.keywords?.keywords || []), query.query];

    const shouldDeleteLastKeyword = newKeywords.length === 0;

    const [err, res] = await (shouldDeleteLastKeyword
      ? removeKeyword(props.pageStore.data.keywords.id)
      : addKeyword({
          page_id: props.pageStore.data.id,
          keywords: newKeywords,
          user_site_id: props.site.id,
        }));

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    if (!err) {
      if (shouldDeleteLastKeyword) {
        return props.pageStore.set((state) => ({
          ...state,
          keywords: undefined,
        }));
      }

      props.pageStore.set((state) => ({
        ...state,
        keywords: (res as any).data,
      }));
    }
  };

  const [
    keywordAnalyzingDialogIsOpen,
    setKeywordAnalyzingDialogIsOpen,
  ] = useState(false);
  const initialKeywordAnalyzeDialogState: API.ISearchConsoleKeywordAnalyzeData = {
    query: "",
    geo: new Array<API.ISearchConsoleGeoData>(),
  };
  const [
    keywordAnalyzingDialogContent,
    setKeywordAnalyzingDialogContent,
  ] = useState(initialKeywordAnalyzeDialogState);
  const onClickKeywordAnalyzeDialog = (
    content: API.ISearchConsoleKeywordAnalyzeData
  ) => {
    setKeywordAnalyzingDialogIsOpen(true);
    setKeywordAnalyzingDialogContent(content);
  };
  const onCloseKeywordAnalyzeDialog = () =>
    setKeywordAnalyzingDialogIsOpen(false);

  return (
    <div>
      <KeywordAnalyzeDialog
        open={keywordAnalyzingDialogIsOpen}
        onClose={onCloseKeywordAnalyzeDialog}
        content={keywordAnalyzingDialogContent}
      />

      <form onSubmit={formik.handleSubmit} className={classes.searchForm}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Search"
          className={classes.searchTextField}
          value={formik.values.search}
          onChange={(e) => formik.setFieldValue("search", e.target.value)}
        />
        <Button variant="contained" color="primary" size="medium" type="submit">
          Search
        </Button>
      </form>

      {searchQuery.length === 0 && (
        <>
          <Totals
            amountOfKeywords={pages.length}
            //attributes={attributes}
            //activeAttributes={activeAttributes}
            //onChangeAttribute={onChangeAttribute}
            //query={{ query: "Totals", data: gscData.totals }}
            //canRefetch={canRefetchStore.data}
            //onClickRefresh={onClickRefresh}
            data={gscData.totals}
            canRefetch={canRefetchStore.data}
            onClickRefresh={onClickRefresh}
            //type={dataPresentType}
          />
          <Box mt={3} />
          <Divider />
          <Box mt={3} />
        </>
      )}

      {pages.length ? (
        <>
          {pages.slice(0, GSCDataLimit).map((query, index) => {
            const isFirst = index === 0;
            const isLast = gscData.pages.length - 1 === index;

            return (
              <React.Fragment key={query.query}>
                {!isFirst && <Box mt={3} />}
                <Query
                  isStarred={(
                    props.pageStore.data?.keywords?.keywords || []
                  ).includes(query.query)}
                  onClickStar={() => onClickStar(query)}
                  query={query}
                  weekResult={weekResult}
                  openKeywordAnalyzeDialog={onClickKeywordAnalyzeDialog}
                />

                {!isLast && (
                  <>
                    <Box mb={3} />
                    <Divider />
                  </>
                )}
              </React.Fragment>
            );
          })}
          {pages.length > GSCDataLimit && (
            <div className={classes.paginationContainer}>
              <Button
                className={classes.showMoreButton}
                onClick={onClickShowMore}
                variant="contained"
                size="small"
                color="primary"
              >
                Show more
              </Button>
              <Typography variant="body2" color="textSecondary">
                1-{GSCDataLimit} of {pages.length}
              </Typography>
            </div>
          )}
        </>
      ) : (
        <Typography align="center" variant="body2" color="textSecondary">
          no data
        </Typography>
      )}
    </div>
  );
};

export default GSC;
