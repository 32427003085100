import { toUnicode } from "punycode";
import { identity, pipe, sortBy } from "ramda";
import { API } from "../../api/types";
import { checkIsValidURL } from "../../utils/checkIsValidURL";
import { Direction, PageAttribute } from "./types";

export const attributeTranslationMap: { [key in PageAttribute]: string } = {
  links: "Links",
  page_id: "Page ID",
  description: "Description",
  id: "ID",
  title: "Title",
  url: "URL",
  last_http_status: "Last HTTP Status",
  site_id: "Site ID",
  traffic_amount: "Traffic Amount",
  traffic_value: "Traffic Value",
  traffic_value_currency: "Traffic Value Currency",
  competitor_id: "Competitor ID",
  search_console_data: "Search Console Data",
  last_parsed: "Last Parsed",
  keywords: "Keywords",
  status: "Status",
  majestic_affected_by_anti_spam: "Affected By Anti Spam (Majestic)",
  majestic_citation_flow: "Citation Flow (Majestic)",
  majestic_crawled_flag: "Crawled Flag (Majestic)",
  majestic_ext_back_links: "External Back Links (Majestic)",
  majestic_final_redirect_result: "Final Redirect Result (Majestic)",
  majestic_last_crawl_date: "Last Crawl Date (Majestic)",
  majestic_last_crawl_result: "Last Crawl Date Result (Majestic)",
  majestic_out_domains_external: "Out Domains External (Majestic)",
  majestic_out_links_external: "Out Links External (Majestic)",
  majestic_out_links_internal: "Out Links Internal (Majestic)",
  majestic_out_links_pages: "Out Links Pages (Majestic)",
  majestic_redirect_flag: "Recirect Flag (Majestic)",
  majestic_redirect_to: "Redirect To (Majestic)",
  majestic_ref_domains: "Ref Domains (Majestic)",
  majestic_ref_ips: "Ref Ips (Majestic)",
  majestic_ref_language: "Ref Language (Majestic)",
  majestic_ref_sub: "Ref Sub (Majestic)",
  majestic_topical_trust_flow: "Tropical Trust Flow (Majestic)",
  majestic_trust_flow: "Trust Flow (Majestic)",
  majestic_trust_metric: "Trust Metric (Majestic)",
  sitemap_lastmod: "Last Modified",
  majestic: "Majestic",
  starred: "Starred",
  todos: "Todos",
  trending: "Trending",
  gsc_position: "GSC position",
  gsc_clicks: "GSC clicks",
  gsc_impressions: "GSC impressions",
  gsc_ctr: "GSC CTR",
  gsc_nbr_keywords: "GSC Keywords",
};

export const attributeHelpTextMap: {
  [key in PageAttribute]: { description: string; examples: string[] } | null;
} = {
  links: null,
  page_id: null,
  description: {
    description: "The description of the page attained from the meta tag.",
    examples: [`"description": "The quick brown fox%"`],
  },
  id: null,
  title: {
    description:
      "The title of the page attained from the title tag inside head.",
    examples: [`"title":"The quick brown fox%"`],
  },
  url: { description: "The URL of the page.", examples: [`"url":"https://%"`] },
  last_http_status: {
    description: "The last HTTP status we receieved from the page.",
    examples: [`"last_http_status": 200`],
  },
  site_id: null,
  traffic_amount: null,
  traffic_value: null,
  traffic_value_currency: null,
  competitor_id: null,
  search_console_data: null,
  last_parsed: {
    description: `The date the page was last parsed.`,
    examples: [`"last_parsed": "N/A"`],
  },
  keywords: {
    description: "Keywords",
    examples: [`"keywords": "N/A"`],
  },
  status: {
    description: "The status of the page.",
    examples: [`"status": "Published"`, `"status": "Draft"`],
  },
  starred: null,
  majestic_affected_by_anti_spam: null,
  majestic_citation_flow: null,
  majestic_crawled_flag: null,
  majestic_ext_back_links: null,
  majestic_final_redirect_result: null,
  majestic_last_crawl_date: null,
  majestic_last_crawl_result: null,
  majestic_out_domains_external: null,
  majestic_out_links_external: null,
  majestic_out_links_internal: null,
  majestic_out_links_pages: null,
  majestic_redirect_flag: null,
  majestic_redirect_to: null,
  majestic_ref_domains: null,
  majestic_ref_ips: null,
  majestic_ref_language: null,
  majestic_ref_sub: null,
  majestic_topical_trust_flow: null,
  majestic_trust_flow: null,
  majestic_trust_metric: null,
  sitemap_lastmod: null,
  majestic: null,
  todos: null,
  trending: null,
  gsc_position: null,
  gsc_clicks: null,
  gsc_impressions: null,
  gsc_ctr: null,
  gsc_nbr_keywords: null,
};

const capitalizedAttributes = new Set(["status"] as (keyof API.IPage)[]);

const toString = (value: any) => String(value);
const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const typeCheck = (value: any) => {
  if (!value) return "unknown";
  if (typeof value === "string") return "string";
  if (typeof value?.getMonth === "function") return "date";
  if (typeof value === "number") return "number";
  if (Boolean(value.keywords)) return "keywords";

  return "unknown";
};

export const pageSortFunction = (sortSettings: {
  direction: Direction;
  attribute: PageAttribute;
}) => (pageA: API.IPage, pageB: API.IPage): number => {
  switch (typeCheck((pageA as any)[sortSettings.attribute])) {
    case "number":
    case "string": {
      return (
        ((pageA as any)[sortSettings.attribute]! >
        (pageB as any)[sortSettings.attribute]!
          ? 1
          : -1) * (sortSettings.direction === "desc" ? -1 : 1)
      );
    }

    default: {
      return 1;
    }
  }
};

export const valueToPresentableText = (
  value: any,
  attribute: PageAttribute
) => {
  const type = typeCheck(value);
  const isUrl = checkIsValidURL(value);

  if (value === null || !value) return "-";
  if (isUrl) return pipe(toString, toUnicode, decodeURIComponent)(value);

  // TODO: Make sorting an option
  if (type === "keywords")
    return pipe(
      (arr: string[]) => sortBy(identity)(arr),
      (arr: string[]) => arr.join(", ")
    )(value.keywords);

  if (capitalizedAttributes.has(attribute as any))
    return pipe(toString, capitalizeFirstLetter)(value);

  return pipe(toString)(value);
};
