import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { API } from "../../api/types";
import { format, getDay, sub, subDays } from "date-fns";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
} from "@material-ui/icons";
import { blue, green, grey, purple, red } from "@material-ui/core/colors";
import Table from "../../components/Table/Table";
import * as V from "victory";

interface ISiteCardProps {
  snapshot: API.Analytics.ISnapshot;
  onClickPage: (pageId: number, siteId: number) => void;
  onClickMoreMenu: (e: any) => void;
}

const lowercase = (string: string) => string.toLowerCase();
const uppercaseFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const getWeekdayName = (offset: number) => {
  const today = Date.now();
  const dayIndex = getDay(subDays(today, offset));
  switch (dayIndex) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
  }
};

const getOffset = (amountOfTrafficTypes: number) => {
  if (amountOfTrafficTypes <= 1) return 0;
  return 17 - 2.76 * amountOfTrafficTypes;
};

const getAverageChartData = (snapshot: API.Analytics.ISnapshot) => {
  let data = [];
  snapshot.previous
    .slice()
    .reverse()
    .forEach((day, index) => {
      data.push({
        x: index + 1,
        y: day.avg,
        _label: `Average: ${Math.round(day.avg * 100) / 100}`,
      });
    });
  data.push({
    x: snapshot.previous.length + 1,
    y: snapshot.average_visitors,
    _label: `Average: ${Math.round(snapshot.average_visitors * 100) / 100}`,
  });
  return data;
};

//const getChartData = (snapshot: API.Analytics.ISnapshot) => {
//const filteredPrevious = snapshot.previous.filter(
//(day) => Object.keys(day.traffic_types || {}).length > 0
//);
//let data = [];
//filteredPrevious
//.slice()
//.reverse()
//.forEach(({ low, high, day, avg }, index) => {
//data.push({
//x: index + 1,
//y0: low,
//y: high,
//dayOffset: day,
//low,
//high,
//avg,
//});
//});
//data.push({
//x: data.length + 1,
//y0: snapshot.lowest_nbr_visitors,
//y: Number(snapshot.highest_nbr_visitors),
//dayOffset: 0,
//low: snapshot.lowest_nbr_visitors,
//high: Number(snapshot.highest_nbr_visitors),
//avg: snapshot.average_visitors,
//});
//return data;
//};

const getChartDataByTrafficType = (
  type: "ORGANIC" | "ORGANIC" | "REFERRAL" | "SOCIAL" | "CUSTOM",
  snapshot: API.Analytics.ISnapshot
) => {
  let data = [];
  snapshot.previous
    .slice()
    .reverse()
    .forEach(({ day, traffic_types }, index) => {
      data.push({
        x: index + 1,
        y0: traffic_types?.[type]?.lowValue || 0,
        y: traffic_types?.[type]?.highValue || 0,
        low: traffic_types?.[type]?.lowValue || 0,
        high: traffic_types?.[type]?.highValue || 0,
        dayOffset: day,
        trafficType: uppercaseFirstLetter(lowercase(type || "")),
        _label: `${uppercaseFirstLetter(lowercase(type))}: ↑${
          traffic_types?.[type]?.highValue || 0
        } ↓${traffic_types?.[type]?.lowValue || 0}`,
        date: sub(new Date(), { days: day }),
      });
    });
  data.push({
    x: data.length + 1,
    y0: snapshot.traffic_type_data[type].lowValue,
    y: snapshot.traffic_type_data[type].highValue,
    dayOffset: 0,
    low: snapshot.traffic_type_data[type].lowValue,
    high: snapshot.traffic_type_data[type].highValue,
    //avg: snapshot.average_visitors,
    trafficType: uppercaseFirstLetter(lowercase(type)),
    _label: `${uppercaseFirstLetter(lowercase(type))}: High: ${
      snapshot.traffic_type_data[type].highValue
    }, Low: ${snapshot.traffic_type_data[type].lowValue}`,
    date: sub(new Date(), { days: 0 }),
  });
  return data;
};

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    padding: theme.spacing(2),
  },
  leftIcon: {
    top: "50%",
    transform: "translateY(-50%) translateX(-18px)",
    position: "absolute",
    fontSize: 15,
    color: grey["600"],
  },
  rightIcon: {
    top: "50%",
    transform: "translateY(-50%) translateX(-18px)",
    position: "absolute",
    fontSize: 15,
    color: grey["600"],
  },
  moreButton: {
    top: theme.spacing(),
    right: theme.spacing(),
    position: "absolute",
  },
  limitSelectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 6,
  },
  tableContainer: {
    maxHeight: 350,
    display: "flex",
  },
  link: {
    cursor: "pointer",
    color: blue["900"],
    "&:hover": {
      textDecoration: "underline",
    },
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  table: {
    tableLayout: "fixed",
  },
  formControlLabel: {
    marginRight: theme.spacing(1.75),
  },
  checkbox: {
    padding: 1,
  },
}));

const dateStringToTimeString = (dateString: string) =>
  format(new Date(dateString.replace(" ", "T")), "HH:mm");

const mergeCountryData = (
  realtime: object,
  past: object,
  sorting: { columnIndex: number; direction: "asc" | "desc" }
) => {
  return Object.entries(past)
    .map(([key, val]) => {
      const current = (realtime as any)?.[key as any] || 0;
      const highTime = val.highTime;
      const highValue = val.highValue;

      return [key, current, highValue, highTime];
    })
    .sort((a, b) => {
      return (
        ((((b[sorting.columnIndex] as any) > a[sorting.columnIndex]) as any)
          ? 1
          : -1) * (sorting.direction === "asc" ? -1 : 1)
      );
    });
};

const mergePageData = (
  realtime: object,
  past: object,
  sorting: { columnIndex: number; direction: "asc" | "desc" }
) => {
  return Object.entries(past)
    .map(([key, val]) => {
      const current = (realtime as any)?.[key as any] || 0;
      const highTime = val.highTime;
      const highValue = val.highValue;

      return [key, current, highValue, highTime];
    })
    .sort((a, b) => {
      return (
        ((((b[sorting.columnIndex] as any) > a[sorting.columnIndex]) as any)
          ? 1
          : -1) * (sorting.direction === "asc" ? -1 : 1)
      );
    });
};

//const topAndBottomLabelMargin = 18;

const getAllTrafficTypes = () => {
  return [
    { name: "DIRECT", color: red["700"] },
    { name: "ORGANIC", color: green["700"] },
    { name: "REFERRAL", color: blue["700"] },
    { name: "SOCIAL", color: purple["700"] },
  ];
};

const SiteCard: FC<ISiteCardProps> = (props) => {
  const [trafficTypes, setTrafficTypes] = useState<
    { name: string; color: string }[]
  >(getAllTrafficTypes());
  const classes = useStyles();
  const [amountOfPagesToShow, setAmountOfPagesToShow] = useState(10);
  const [amountOfCountriesToShow, setAmountOfCountriesToShow] = useState(10);
  const noPreviousData = !props.snapshot.previous?.length;
  const noCurrentData =
    !props.snapshot.highest_nbr_visitors &&
    !props.snapshot.lowest_nbr_visitors &&
    !props.snapshot.latest_snapshot.active_users;

  const shouldRenderGraph = !(noPreviousData && noCurrentData);

  const [pagesSorting, setPagesSorting] = useState<{
    direction: "desc" | "asc";
    columnIndex: number;
  }>({
    direction: "desc",
    columnIndex: 1,
  });

  const [countriesSorting, setCountriesSorting] = useState<{
    direction: "desc" | "asc";
    columnIndex: number;
  }>({
    direction: "desc",
    columnIndex: 1,
  });

  const pageData = Boolean(props.snapshot.page_data)
    ? mergePageData(
        props.snapshot.latest_snapshot.pages,
        props.snapshot.page_data,
        pagesSorting
      ).slice(0, amountOfPagesToShow)
    : null;

  const pageTableData = (pageData || []).map(
    ([label, current, highValue, highTime]) => {
      return [
        <Tooltip title={label}>
          <Typography
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            variant="body2"
          >
            {label}
          </Typography>
        </Tooltip>,
        current,
        <Tooltip title={`Time: ${dateStringToTimeString(highTime)}`}>
          <Typography variant="body2">{highValue}</Typography>
        </Tooltip>,
      ];
    }
  );

  const countryData = Boolean(props.snapshot.country_data)
    ? mergeCountryData(
        props.snapshot.latest_snapshot.country,
        props.snapshot.country_data,
        countriesSorting
      ).slice(0, amountOfCountriesToShow)
    : null;

  const countryTableData = (countryData || []).map(
    ([label, current, highValue, highTime]) => {
      return [
        label,
        current,
        <Tooltip title={`Time: ${dateStringToTimeString(highTime)}`}>
          <Typography variant="body2">{highValue}</Typography>
        </Tooltip>,
      ];
    }
  );

  const onChangePageSorting = (columnIndex: number, newDir: "desc" | "asc") => {
    setPagesSorting({ columnIndex, direction: newDir });
  };

  const onChangeCountriesSorting = (
    columnIndex: number,
    newDir: "desc" | "asc"
  ) => {
    setCountriesSorting({ columnIndex, direction: newDir });
  };

  const onChangeTrafficTypes = (
    type: { name: string; color: string },
    checked: boolean
  ) => {
    if (checked) setTrafficTypes([...trafficTypes, type]);
    else
      setTrafficTypes(trafficTypes.filter((_type) => _type.name !== type.name));
  };

  return (
    <Card className={classes.card}>
      <Typography variant="h5" align="center">
        {props.snapshot.domain}
      </Typography>
      <Box mb={3} />
      <Grid container alignItems="flex-end">
        <Grid item xs={4}>
          <Tooltip title="Lowest number of visitors today">
            <div style={{ cursor: "pointer" }}>
              <div style={{ position: "relative" }}>
                <Typography variant="h6" align="center">
                  <KeyboardArrowDown className={classes.leftIcon} />
                  {props.snapshot.lowest_nbr_visitors}
                </Typography>
              </div>
              <Typography variant="body2" color="textSecondary" align="center">
                {dateStringToTimeString(
                  props.snapshot.lowest_nbr_visitors_time
                )}
              </Typography>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="Number of visitors currently on the site">
            <div style={{ cursor: "pointer" }}>
              <Typography variant="h4" align="center">
                {props.snapshot.latest_snapshot.active_users}
              </Typography>
              <Typography variant="body2" color="textSecondary" align="center">
                Now
              </Typography>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="Highest number of visitors today">
            <div style={{ cursor: "pointer" }}>
              <div style={{ position: "relative" }}>
                <Typography variant="h6" align="center">
                  <KeyboardArrowUp className={classes.rightIcon} />
                  {props.snapshot.highest_nbr_visitors}
                </Typography>
              </div>
              <Typography variant="body2" color="textSecondary" align="center">
                {dateStringToTimeString(
                  props.snapshot.highest_nbr_visitors_time
                )}
              </Typography>
            </div>
          </Tooltip>
        </Grid>
      </Grid>

      {shouldRenderGraph && (
        <>
          <Box mt={2} />
          <FormGroup style={{ justifyContent: "center" }} row>
            {getAllTrafficTypes().map((type) => {
              return (
                <FormControlLabel
                  classes={{ root: classes.formControlLabel }}
                  control={
                    <Checkbox
                      classes={{ root: classes.checkbox }}
                      style={{ color: type.color }}
                      size="small"
                      checked={trafficTypes.some(
                        (_type) => _type.name === type.name
                      )}
                      onChange={(e) =>
                        onChangeTrafficTypes(type, e.target.checked)
                      }
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {uppercaseFirstLetter(lowercase(type.name))}
                    </Typography>
                  }
                />
              );
            })}
          </FormGroup>
          <V.VictoryChart
            theme={V.VictoryTheme.material}
            padding={{ top: 30, right: 40, left: 40, bottom: 30 }}
            height={250}
            domainPadding={20}
            containerComponent={
              <V.VictoryVoronoiContainer
                labelComponent={
                  <V.VictoryTooltip
                    text={(params) => {
                      return (params as any).activePoints.reduce(
                        (acc: any, val: any) => {
                          return `${
                            acc || format(params.datum.date, "yyyy-MM-dd")
                          }\n${val._label}`;
                        },
                        ""
                      );
                    }}
                    centerOffset={{ y: -50 }}
                  />
                }
                voronoiDimension="x"
                labels={({ datum }) => {
                  return datum._label;
                }}
              />
            }
          >
            <V.VictoryAxis
              tickValues={[
                ...props.snapshot.previous.map((day) => day.day),
                props.snapshot.previous.length + 1,
              ]}
              tickFormat={[
                ...props.snapshot.previous
                  .slice()
                  .reverse()
                  .map((day) => {
                    if (day.high === null) return "";
                    return getWeekdayName(day.day);
                  }),
                "Today",
              ]}
              tickLabelComponent={
                <V.VictoryLabel
                  angle={15}
                  style={{ fontSize: 10, color: "blue", fill: grey["700"] }}
                />
              }
            />

            <V.VictoryAxis dependentAxis />

            <V.VictoryGroup
              minDomain={{ x: 0 }}
              offset={getOffset(trafficTypes.length)}
            >
              {trafficTypes.map((type) => {
                return (
                  <V.VictoryBar
                    style={{
                      data: {
                        fill: type.color,
                      },
                    }}
                    labelComponent={<V.VictoryTooltip />}
                    data={getChartDataByTrafficType(
                      type.name as any,
                      props.snapshot
                    )}
                  />
                );
              })}
            </V.VictoryGroup>
            <V.VictoryLine
              interpolation="natural"
              data={getAverageChartData(props.snapshot)}
              style={{ data: { strokeWidth: 3 } }}
            />
          </V.VictoryChart>
        </>
      )}

      <Grid container spacing={4}>
        {Boolean(pageData) && (
          <Grid item xs={12}>
            <div className={classes.limitSelectContainer}>
              <Typography variant="body1">Top Pages</Typography>
              <FormControl size="small">
                <InputLabel id="page-limit-label">Limit</InputLabel>
                <Select
                  labelId="page-limit-labe"
                  value={amountOfPagesToShow}
                  onChange={(e) =>
                    setAmountOfPagesToShow((e.target as any).value)
                  }
                >
                  {[5, 10, 25, 50, 100].map((value) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={classes.tableContainer}>
              <Table
                tableClassName={classes.table}
                typographyProps={(cellContext, rowContext) => {
                  if (cellContext.index === 0 && !rowContext.isHeader)
                    return {
                      className: classes.link,
                      onClick: () => {
                        const route = pageData![rowContext.index][0];
                        const pageId = props.snapshot.page_data[route].page_id;
                        const siteId = props.snapshot.user_site_id;
                        props.onClickPage(pageId, siteId);
                      },
                    };

                  if (cellContext.index > 0) {
                    return {
                      align: "right",
                    };
                  }

                  return {};
                }}
                cellProps={(cellContext) => {
                  if (cellContext.index === 0 && cellContext.isHeader)
                    return {
                      style: {
                        width: "50%",
                        paddingTop: 2,
                        paddingBottom: 2,
                      },
                      padding: "none",
                    };

                  if (cellContext.index > 0 && cellContext.isHeader) {
                    return {
                      style: {
                        width: "25%",
                        paddingTop: 2,
                        paddingBottom: 2,
                      },
                      align: "right",
                      padding: "none",
                    };
                  }

                  if (cellContext.index > 0) {
                    return {
                      align: "right",
                      padding: "none",
                      style: {
                        paddingTop: 2,
                        paddingBottom: 2,
                      },
                    };
                  }

                  return {
                    padding: "none",
                    style: {
                      paddingTop: 2,
                      paddingBottom: 2,
                    },
                  };
                }}
                small
                onChangeSorting={onChangePageSorting}
                sorting={pagesSorting}
                header={["Page", "Active", "High"]}
                body={pageTableData}
              />
            </div>
          </Grid>
        )}
        {Boolean(countryData) && (
          <Grid item xs={12}>
            <div className={classes.limitSelectContainer}>
              <Typography variant="body1">Top Countries</Typography>
              <FormControl size="small">
                <InputLabel id="page-limit-label">Limit</InputLabel>
                <Select
                  labelId="page-limit-labe"
                  value={amountOfCountriesToShow}
                  onChange={(e) =>
                    setAmountOfCountriesToShow((e.target as any).value)
                  }
                >
                  {[5, 10, 25, 50, 100].map((value) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={classes.tableContainer}>
              <Table
                tableClassName={classes.table}
                typographyProps={(cellContext, rowContext) => {
                  if (cellContext.index > 0) {
                    return {
                      align: "right",
                    };
                  }

                  return {};
                }}
                cellProps={(cellContext) => {
                  if (cellContext.index === 0 && cellContext.isHeader)
                    return {
                      style: {
                        width: "50%",
                        paddingTop: 2,
                        paddingBottom: 2,
                      },
                      padding: "none",
                    };
                  if (cellContext.index === 0)
                    return {
                      style: {
                        wordBreak: "break-all",
                        paddingTop: 2,
                        paddingBottom: 2,
                      },
                      padding: "none",
                    };

                  if (cellContext.index > 0 && cellContext.isHeader) {
                    return {
                      style: {
                        width: "25%",
                        paddingTop: 2,
                        paddingBottom: 2,
                      },
                      align: "right",
                      padding: "none",
                    };
                  }

                  return {
                    align: "right",
                    padding: "none",
                    style: {
                      paddingTop: 2,
                      paddingBottom: 2,
                    },
                  };
                }}
                small
                onChangeSorting={onChangeCountriesSorting}
                sorting={countriesSorting}
                header={["Country", "Active", "High"]}
                body={countryTableData!}
              />
            </div>
          </Grid>
        )}
      </Grid>

      <IconButton
        onClick={props.onClickMoreMenu}
        className={classes.moreButton}
      >
        <MoreHoriz />
      </IconButton>
    </Card>
  );
};

export default SiteCard;
