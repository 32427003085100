// When cheking for new events, such as new messages, new notifications, etc,
// we make a requst to /whats-new. There, we'll see if there are any
// updates, and make more requests accordingly.

import { whatsNew } from "../api";
import { useStore } from "./store";

const poller = (store: ReturnType<typeof useStore>) => {
  const interval = 1000 * 60;
  const poll = async () => {
    const [updateErr, update] = await whatsNew();
    if (updateErr) return;
    store!.ui.update.set(update!.data);
    if (update?.data?.messages) store.data.messages.refetch();
  };
  poll();
  return setInterval(poll, interval);
};

export default poller;
