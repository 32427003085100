import React, { FC } from "react";
import { API } from "../../../api/types";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  nbrLines: {
    paddingBottom: "5px",
    paddingLeft: "4px",
  },
  dataTable: {
    "& th": {
      textAlign: "left",
      padding: "0 4px",
    },
    "& td": {
      padding: "0 4px",
    },
  },
}));

const TrendingRelatedSiteKeywordMoreInfo: FC<API.Trends.ITrendingRelatedKeywordsSiteCoverageData> = (
  props
) => {
  const classes = useStyles();

  return (
    <>
      {props.fetchError ? (
        <Typography variant="body2">Unable to fetch site coverage</Typography>
      ) : (
        <>
          <Typography variant="body2" className={classes.nbrLines}>
            Number of pages with coverage: {props.num}
          </Typography>
          {props.num! >= 1 && (
            <table className={classes.dataTable}>
              <thead>
                <tr>
                  <th>URL</th>
                  <th>Title</th>
                  <th>Post Type</th>
                </tr>
              </thead>
              <tbody>
                {props.posts!.length &&
                  props.posts!.map((post) => {
                    return (
                      <tr>
                        <td>{post.url}</td>
                        <td>{post.title}</td>
                        <td>{post.type}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

export default TrendingRelatedSiteKeywordMoreInfo;
