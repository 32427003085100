import React, { FC } from "react";
import {
  Avatar,
  Button,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import { API } from "../../api/types";

interface IAppBarMenuProps {
  user: API.IUser;

  anchorElement: Element | null;
  onLogout: () => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  userNameText: {
    lineHeight: "100%",
  },
  logOutButton: {
    marginTop: theme.spacing(2),
  },
}));

const AppBarMenu: FC<IAppBarMenuProps> = (props) => {
  const classes = useStyles();

  return (
    <Popover
      anchorEl={props.anchorElement}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      keepMounted
      open={Boolean(props.anchorElement)}
      onClose={props.onClose}
      tabIndex={undefined}
    >
      <div className={classes.container}>
        <Avatar
          className={classes.avatar}
          src={props.user.google_image_url}
        ></Avatar>
        <Typography
          className={classes.userNameText}
          variant="subtitle1"
          color="textPrimary"
        >
          {props.user.name}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {props.user.email}
        </Typography>
        <Button
          className={classes.logOutButton}
          variant="outlined"
          onClick={props.onLogout}
        >
          Log out
        </Button>
      </div>
    </Popover>
  );
};

export default AppBarMenu;
