import React, { FC } from "react";
import { Card, CircularProgress, makeStyles } from "@material-ui/core";

interface ILoadingCardProps {}

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },
}));

const LoadingCard: FC<ILoadingCardProps> = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CircularProgress />
    </Card>
  );
};

export default LoadingCard;
