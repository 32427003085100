import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React, { FC } from "react";

interface IConfirmDialogProps {
  onClickNo: () => void;
  onClickYes: () => void;
  open: boolean;
  question?: string;
}

const ConfirmDialog: FC<IConfirmDialogProps> = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogContent>
        {props.question ? props.question : "Are you sure?"}
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={props.onClickNo}>
          No
        </Button>
        <Button size="small" color="primary" onClick={props.onClickYes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
