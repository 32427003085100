import { useFormik } from "formik";

export const getError = (
  formik: ReturnType<typeof useFormik>,
  keyName: string
) => Boolean(formik.errors[keyName]) && formik.submitCount > 0;

export const getHelperText = (
  formik: ReturnType<typeof useFormik>,
  keyName: string
) =>
  Boolean(formik.errors[keyName]) &&
  formik.submitCount > 0 &&
  formik.errors[keyName];
