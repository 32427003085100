import React, { FC, useContext } from "react";
import { useFormik } from "formik";
import {
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import PTextField from "../../../components/Form/PTextField";
import { linkFunnelContext } from "../utils/contexts";
import LoadingCard from "../../../components/LoadingCard/LoadingCard";
import checkIsValidEmail from "../../../utils/checkIsValidEmail";
import { checkIsValidURL } from "../../../utils/checkIsValidURL";

const ContactForm: FC = () => {
  const { addContact, contactsStore, setContactId, linkStore } =
    useContext(linkFunnelContext)!;

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      skype: "",
      notes: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.name) errors.name = "This field is required";
      if (!checkIsValidEmail(values.email) && !checkIsValidURL(values.email))
        errors.email = "Email is neither valid email noe valid url.";
      if (!values.email) errors.email = "This field is required";
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      addContact(values);
      resetForm();
    },
  });

  const onChangeContact = async (e: any) => {
    const contactId = e.target.value as number;
    setContactId(contactId);
  };

  if (contactsStore.isLoading) return <LoadingCard />;

  return (
    <form onSubmit={formik.handleSubmit}>
      {Boolean(contactsStore.data.length) ? (
        <>
          <InputLabel id="contact-label">Select a contact</InputLabel>
          <Select
            value={linkStore.data.contact_id || null}
            onChange={onChangeContact}
            labelId="contact-label"
            style={{ width: "100%" }}
          >
            {contactsStore.data.map((contact) => {
              return (
                <MenuItem key={contact.id} value={contact.id}>
                  <Typography>{contact.name}</Typography>
                  <Box ml={1} />
                  <Typography variant="body2" color="textSecondary">
                    {contact.email}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </>
      ) : (
        <Typography>You don't have any contacts yet.</Typography>
      )}
      <Box mb={3} />
      <Typography variant="body1" color="textPrimary">
        Create new contact
      </Typography>
      <PTextField formik={formik} name="name" label="Name" />
      <PTextField formik={formik} name="email" label="Email" />
      <PTextField formik={formik} name="phone" label="Phone Number" />
      <PTextField formik={formik} name="skype" label="Skype" />
      <PTextField formik={formik} name="notes" label="Notes" />
      <Box mb={2} />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="submit" variant="outlined" color="primary">
          Add contact
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
