import React, { FC } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import Layout from "../../components/Layout";
import { useHistory } from "react-router-dom";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";

const SiteSettingsScreen: FC = () => {
  const history = useHistory();

  return (
    <Layout
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          goBackUrl="/sites/123-1231-23"
        />
      )}
    >
      <Box mb={1} />
      <Typography>This is the settings screen.</Typography>
      <Box mb={2} />
      <Button variant="contained" color="primary" onClick={history.goBack}>
        Go back
      </Button>
    </Layout>
  );
};

export default SiteSettingsScreen;
