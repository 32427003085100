import React, { FC, useEffect, useRef, useState } from "react";
import { ITableProps, IRowContext } from "./types";
import useTableStyles from "./styles";
import {
  TableContainer,
  Table as TableMUI,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

const getHeaderButtonsContainerStyles = (
  tableHeadRef: any,
  tableContainerRef: any
) => {
  return {
    top:
      tableHeadRef?.getBoundingClientRect?.()?.top +
      (tableContainerRef?.scrollTop || 0) +
      "px",
    height: tableHeadRef?.getBoundingClientRect?.()?.height - 1 + "px" || "0px",
  };
};

const Table: FC<ITableProps> = (props) => {
  const classes = useTableStyles();
  const tableHeadRef = useRef(null);
  const headerButtonsRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [showHeaderButtons, setShowHeaderButtons] = useState(false);

  useEffect(() => {
    // When rendering the header buttons, we depend on the table element,
    // which won't be rendered straight away. Therefore, we need to wait
    // until the next tick, then we can render the header buttons.
    setTimeout(() => setShowHeaderButtons(true), 0);
  }, []);

  // TODO: Type the event
  const onClickRow = (context: IRowContext) => (e: any) => {
    if (!props.onClickRow) return;
    props.onClickRow(context, e);
  };

  const onClickSortLabel = (columnIndex: number) => () => {
    const sameColumn = columnIndex === props.sorting?.columnIndex;
    if (sameColumn) {
      const newDir = props.sorting?.direction === "asc" ? "desc" : "asc";
      return props.onChangeSorting?.(columnIndex, newDir);
    }
    return props.onChangeSorting?.(columnIndex, "desc");
  };

  const renderHeaderButtonsFiller = (rowContext: IRowContext) => {
    const cellContext = { index: 0, value: "", isHeader: rowContext.isHeader };

    return (
      <TableCell
        align="right"
        padding="none"
        {...(props?.cellProps?.(cellContext, rowContext) || {})}
      >
        <div
          style={{
            width:
              (headerButtonsRef?.current as any)?.clientWidth + "px" || "0px",
          }}
        />
      </TableCell>
    );
  };

  const renderHeadCell = (rowContext: IRowContext) => (
    cell: string,
    cellIndex: number
  ) => {
    const cellContext = {
      index: cellIndex,
      value: cell,
      isHeader: true,
    };

    return (
      <TableCell
        key={cellIndex}
        {...(props?.cellProps?.(cellContext, rowContext) || {})}
      >
        <TableSortLabel
          active={props.sorting?.columnIndex === cellIndex}
          direction={props.sorting?.direction}
          onClick={onClickSortLabel(cellIndex)}
        >
          <Tooltip title={props.headerTooltips?.[cellIndex] || ""}>
            <Typography
              variant="body2"
              color={"textPrimary"}
              className={classes.headText}
              {...props?.typographyProps?.(cellContext, rowContext)}
            >
              {cell}
            </Typography>
          </Tooltip>
        </TableSortLabel>
      </TableCell>
    );
  };

  const renderBodyCell = (rowContext: IRowContext) => (
    cell: string,
    cellIndex: number
  ) => {
    const cellContext = {
      index: cellIndex,
      value: cell,
      isHeader: false,
    };

    return (
      <TableCell
        key={cellIndex}
        className={classes.bodyCell}
        {...(props?.cellProps?.(cellContext, rowContext) || {})}
      >
        <div className={classes.bodyCellContainer}>
          {cellIndex === 0 && props.renderRowIcon && (
            <div className={classes.iconContainer}>
              {props.renderRowIcon(rowContext)}
            </div>
          )}
          <Typography
            variant="body2"
            color={"textSecondary"}
            style={{
              width: "100%",
              ...(props?.typographyProps?.(cellContext, rowContext)?.style ||
                {}),
            }}
            {...props?.typographyProps?.(cellContext, rowContext)}
          >
            {cell}
          </Typography>
        </div>
      </TableCell>
    );
  };

  const renderHeadRow = (cells: string[]) => {
    return (
      <TableRow>
        {cells.map(renderHeadCell({ index: 0, isHeader: true }))}
        {renderHeaderButtonsFiller({ index: 0, isHeader: false })}
      </TableRow>
    );
  };

  const renderBodyRow = (items: any[], index: number) => {
    return (
      <TableRow
        key={index}
        onClick={onClickRow({ index, isHeader: false })}
        className={clsx({
          [classes.clickableBodyRow]: Boolean(props.onClickRow),
        })}
      >
        {items.map(renderBodyCell({ index, isHeader: false }))}
        {renderHeaderButtonsFiller({ index, isHeader: false })}
      </TableRow>
    );
  };

  const renderHeaderButtons = () => {
    if (!props.headerButtons) return null;
    return (
      <div
        ref={headerButtonsRef}
        className={classes.headerButtonsContainer}
        style={{
          top: getHeaderButtonsContainerStyles(
            tableHeadRef.current,
            tableContainerRef.current
          ).top,
          height: 57 - 1,
        }}
      >
        {props.headerButtons}
      </div>
    );
  };

  return (
    <TableContainer
      ref={tableContainerRef}
      classes={{ root: classes.tableContainer }}
    >
      <TableMUI
        size={props.small ? "small" : "medium"}
        classes={{ root: classes.table }}
        className={props?.tableClassName}
        stickyHeader
      >
        <TableHead ref={tableHeadRef}>{renderHeadRow(props.header)}</TableHead>
        {props.isLoading ? (
          <LoadingOverlay />
        ) : (
          <TableBody>{props.body.map(renderBodyRow)}</TableBody>
        )}
      </TableMUI>
      {showHeaderButtons && renderHeaderButtons()}
    </TableContainer>
  );
};

export default Table;
