import React, { FC } from "react";
import { Container as ContainerMUI, makeStyles } from "@material-ui/core";
import clsx from "clsx";
interface IContainerProps {
  padding?: "none" | "horisontal" | "vertical";
}

const useStyles = makeStyles((theme) => ({
  base: {
    position: "relative",
  },
  paddingVertical: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paddingHorisontal: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

const Container: FC<IContainerProps> = (props) => {
  const classes = useStyles();
  const paddingAll = !["none", "horisontal", "vertical"].includes(
    props.padding || ""
  );

  return (
    <ContainerMUI
      maxWidth="md"
      className={clsx(classes.base, {
        [classes.padding]: paddingAll,
        [classes.paddingHorisontal]: props.padding === "horisontal",
        [classes.paddingVertical]: props.padding === "vertical",
      })}
    >
      <>{props.children}</>
    </ContainerMUI>
  );
};

export default Container;
