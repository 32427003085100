import { IQuery } from "./types";
import { v4 as uuid } from "uuid";
import { useContext, useEffect, useState } from "react";
import { filter } from "ramda";
import { matchQuery } from "./utils";
import { API } from "../../api/types";
import { StoreContext } from "../../contexts";

const useQueryAid = (activeSite: string) => {
  const store = useContext(StoreContext);
  const savedQueries = store!.data.user.data?.profile?.savedQueries;
  const recentQueries = store!.data.user.data?.profile?.recentQueries;

  const [inputState, setInputState] = useState({
    isDirty: false,
    isOpen: false,
    value: "",
  });

  useEffect(() => {
    setInputState({ isDirty: false, isOpen: false, value: "" });
  }, [activeSite]);

  const onClickDelete = (query: IQuery) => {
    if (query.type === "saved")
      store!.data.user.set(
        {
          savedQueries: {
            ...(savedQueries || {}),
            [activeSite]: savedQueries[activeSite].filter(
              (q: IQuery) => q.id !== query.id
            ),
          },
        },
        { optimisticResponse: true }
      );
    else
      store!.data.user.set(
        {
          recentQueries: {
            ...recentQueries,
            [activeSite]: recentQueries[activeSite].filter(
              (q: IQuery) => q.id !== query.id
            ),
          },
        },
        { optimisticResponse: true }
      );
  };

  const onSearch = () => {
    if (
      Boolean(
        recentQueries?.[activeSite]
          ?.map((query: IQuery) => query.query)
          ?.includes(inputState.value)
      )
    )
      return;

    store!.data.user.set(
      {
        recentQueries: {
          ...recentQueries,
          [activeSite]: [
            { query: inputState.value, id: uuid(), type: "recent" } as any,
            ...(recentQueries?.[activeSite] || []),
          ].slice(0, 3),
        },
      },
      { optimisticResponse: true }
    );
  };

  const onClickSaveQuery = (data: {
    name: string;
    query: string;
    attributes: API.IAttribute[];
  }) => {
    setInputState({ ...inputState, value: data.query });

    store!.data.user.set(
      {
        recentQueries: {
          ...recentQueries,
          [activeSite]:
            recentQueries[activeSite]?.filter(
              (q: IQuery) => q.query !== data.query
            ) || [],
        },
      },
      { optimisticResponse: true }
    );

    store!.data.user.set(
      {
        savedQueries: {
          ...savedQueries,
          [activeSite]: [
            {
              query: data.query,
              id: uuid(),
              type: "saved",
              name: data.name,
              attributes: data.attributes,
            } as any,
            ...(savedQueries?.[activeSite] || []),
          ].slice(0, 3),
        },
      },
      { optimisticResponse: true }
    );
  };

  const filteredSavedQueries = () => {
    if (!savedQueries?.[activeSite]) return [];

    if (inputState.isDirty)
      return filter(matchQuery(inputState.value))(
        savedQueries[activeSite] || []
      );
    return savedQueries[activeSite] || [];
  };

  const filteredRecentQueries = () => {
    if (inputState.isDirty)
      return filter(matchQuery(inputState.value))(
        recentQueries?.[activeSite] || []
      );

    return recentQueries?.[activeSite] || [];
  };

  return {
    onClickDelete,
    onClickSaveQuery,
    recentQueries,
    savedQueries,
    onSearch,
    filteredSavedQueries,
    filteredRecentQueries,
    setInputState,
    inputState,
  };
};

export default useQueryAid;
