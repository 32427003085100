import {
  createStyles,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { DragIndicator } from "@material-ui/icons";
import React, { FC } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { API } from "../../api/types";

interface IAnalyticsReorderModal {
  open: boolean;
  snapshots: API.Analytics.ISnapshot[];
  onChangeOrder: (snapshots: API.Analytics.ISnapshot[]) => void;
  onClose: () => void;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  background: isDragging ? grey["300"] : "white",
  ...draggableStyle,
});

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      padding: theme.spacing(1),
      "&:hover": {
        background: grey["100"],
      },
    },

    dragIndicator: {
      marginRight: theme.spacing(1),
    },
  })
);

const AnalyticsReorderDialog: FC<IAnalyticsReorderModal> = (props) => {
  const classes = useStyles();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    props.onChangeOrder(
      reorder(props.snapshots, result.source.index, result.destination.index)
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {props.snapshots.map((snapshot, index) => (
                <Draggable
                  key={snapshot.id}
                  draggableId={String(snapshot.id)}
                  index={index}
                >
                  {(provided, _snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        _snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div className={classes.container}>
                        <DragIndicator className={classes.dragIndicator} />
                        <Typography>{snapshot.domain}</Typography>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Dialog>
  );
};

export default AnalyticsReorderDialog;
