import React, { FC } from "react";
import {
  Tooltip,
  Typography,
  Box,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import clsx from "clsx";

interface IAttributeTooltipProps {
  showTooltip: boolean;
  description?: string;
  examples?: string[];
  render: JSX.Element;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      display: "block",
    },
    bold: {
      fontWeight: "bold",
    },
  })
);

const AttributeTooltip: FC<IAttributeTooltipProps> = (props) => {
  const classes = useStyles();

  const title = props.showTooltip && (
    <>
      <Typography className={clsx(classes.text)} variant="caption">
        {props.description}
      </Typography>
      <Box mb={1} />
      <Typography
        className={clsx(classes.text, classes.bold)}
        variant="caption"
      >
        Examples
      </Typography>

      {props.examples!.map((example) => (
        <Typography
          key={example}
          className={clsx(classes.text)}
          variant="caption"
        >
          {example}
        </Typography>
      ))}
    </>
  );

  return <Tooltip title={title}>{props.render}</Tooltip>;
};

export default AttributeTooltip;
