// temporarily making a table for GSC data, but in the future, let's make SiteTable more general so we can use that everywhere.

import React, { FC } from "react";
import {
  TableContainer,
  Table as TableMUI,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

interface IPTableProps {
  header: string[];
  body: string[][];
}

const useStyles = makeStyles(
  createStyles({
    headCell: {
      backgroundColor: grey["100"],
      whiteSpace: "nowrap",
    },
  })
);

const PTable: FC<IPTableProps> = (props) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <TableMUI>
        <TableHead>
          <TableRow>
            {props.header.map((cell, index) => (
              <TableCell key={index} className={classes.headCell}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.body.map((row) => (
            <TableRow>
              {row.map((cell, index) => (
                <TableCell key={index}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableMUI>
    </TableContainer>
  );
};

export default PTable;
