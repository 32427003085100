import React, { FC, ReactHTML } from "react";

interface IConditionalElementProps {
  tag: keyof ReactHTML;
}

const ConditionalElement: FC<IConditionalElementProps & any> = (props) => {
  return React.createElement(props.tag, props, props.children);
};

export default ConditionalElement;
