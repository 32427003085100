// TODO: Either refactor this to seprate drawer and appbar, or rename?// @ts-nocheck

import {
  Box,
  makeStyles,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import DrawerMUI from "@material-ui/core/Drawer";
import {
  AccountBox,
  Assessment,
  Autorenew,
  DeleteForever,
  Description,
  Link,
  NoteAdd,
  Share,
  TrendingUp,
  Note,
} from "@material-ui/icons";
import SubjectIcon from "@material-ui/icons/Subject";
//import SsidChartIcon from "@material-ui/icons/SsidChart";
//import SsidChartIcon from '@mui/icons-material/SsidChart';
import useLocalStorage from "@rehooks/local-storage";
import to from "await-to-js";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  addDraft,
  createSite,
  getSiteParseProgress,
  parseSite,
  removeSite,
} from "../../api";
import { API } from "../../api/types";
import { IParseSiteState, StoreContext } from "../../contexts";
import AddSiteDialog from "../../screens/Sites/AddSiteDialog";
import { TransitionUp } from "../../utils/snackbar";
import { RenderAppToolbar } from "../AppBarToolbar/types";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import ParseSiteProgress from "../ParseSiteProgress/ParseSiteProgress";
import { ParseSiteIntervalManager } from "../ParseSiteProgress/utils";
import SitePicker from "./SitePicker";
import { Link as RRDLink } from "react-router-dom";
import AddDraftDialog from "../../screens/Site/AddDraftDialog";

const getSite = (sites: API.ISite[], id?: number) => {
  if (!sites || !id) return null;
  return sites.find((site) => site.id === id)!;
};

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  appBarToolbar: {
    display: "grid",
    "grid-template-columns": "1fr 1fr 1fr",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  siteSelect: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  siteSelectRoot: {
    display: "flex",
    alignItems: "center",
  },
  siteSelectContainer: {
    "flex-direction": "column",
    "align-items": "flex-start",
  },
  siteSelectLabel: {
    fontSize: ".95rem",
  },
  siteMenuItem: {
    display: "flex",
    alignItems: "center",
  },
  verfiedIcon: {
    marginRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  menuItemIcon: {
    marginRight: theme.spacing(1),
  },
  menuItemIconSmall: {
    fontSize: 18,
  },
  contentContainer: {
    padding: theme.spacing(3),
  },
  parseSiteProgressContainer: {
    marginTop: theme.spacing(1),
  },
  disabledText: {
    color: "rgba(0, 0, 0, 0.5)",
  },
}));
interface IDrawerProps {
  renderAppToolbar: RenderAppToolbar;
}

const Drawer: FC<IDrawerProps> = (props) => {
  const classes = useStyles();
  const store = useContext(StoreContext);
  const history = useHistory();
  const [addSiteDialogIsOpen, setAddSiteDialogIsOpen] = useState(false);
  const hasSites = Boolean(store?.data.sites?.data?.length);
  const { enqueueSnackbar } = useSnackbar();
  const path = history?.location?.pathname;
  const isOnOutreach = path?.includes("/outreach");
  const isOnContacts = path?.includes("/outreach/contacts");
  const isOnLinks = path?.includes("/outreach/links");
  const isOnAnalytics = path?.includes("/analytics");
  const isOnSeoProgress = path?.includes("/seo-progress");
  const firstSiteId = store!.data.sites?.data?.[0]?.id;
  const [authToken] = useLocalStorage<string>("authToken");
  const [deleteSiteModalIsOpen, setDeleteSiteModailIsOpen] = useState(false);
  const userStoreIsLoading = store!.data.user.isLoading;

  const activeSite = store!.data.user.data?.profile?.activeSite || firstSiteId;

  useEffect(() => {
    if (
      !store!.data.user.data?.profile?.activeSite &&
      authToken &&
      !store!.data.user.isLoading
    ) {
      store!.data.user.set({
        ...(store!.data.user.data?.profile || {}),
        activeSite: firstSiteId,
      });
    }
    // eslint-disable-next-line
  }, [store!.data.user.isLoading]);

  const site = getSite(store!.data.sites.data || [], activeSite);

  const [sitePickerInputValue, setSitePickerInputValue] = useState(
    getSite(store!.data.sites.data, activeSite)?.domain || ""
  );

  const onClickAddNewSite = () => {
    setSitePickerInputValue(
      getSite(store!.data.sites.data, activeSite)?.domain || ""
    );
    if (hasSites) return setAddSiteDialogIsOpen(true);
    history.push("/add-site");
    store!.ui.drawer.set(false);
  };

  const onFocusSitePicker = () => {
    setSitePickerInputValue("");
  };

  const onBlurSitePicker = () => {
    setSitePickerInputValue(
      getSite(store!.data.sites.data, activeSite)!.domain
    );
  };

  const onChangeSite = (site: API.ISite) => {
    setSitePickerInputValue(site.domain);

    store!.data.user.set({ activeSite: site.id });
    history.push("/");
  };

  const listenToParseUpdates = (siteId: number) => {
    const newState: IParseSiteState = {
      isVisible: true,
      done: 0,
      total: 1,
      pollsWithoutProgress: 0,
      lastPolled: new Date(),
    };
    store!.ui.parseSite.set(newState);
    ParseSiteIntervalManager.setState(newState);
    ParseSiteIntervalManager.start(async () => {
      //store!.parseSiteStore.setParseSiteLastPolled(new Date());
      store!.ui.parseSite.set(newState);
      const [getSiteParseProgressError, getSiteParseProgressResponse] =
        await getSiteParseProgress(String(siteId));
      if (getSiteParseProgressError) return;
      const { code, done, total } = getSiteParseProgressResponse!.data;
      const progressIsStale = done === ParseSiteIntervalManager.state.done;
      const pollsWithoutProgress = progressIsStale
        ? ParseSiteIntervalManager.state.pollsWithoutProgress + 1
        : 0;
      const isLast =
        code === 4 &&
        ParseSiteIntervalManager.state.done !==
          ParseSiteIntervalManager.state.total;
      const timedOut = pollsWithoutProgress > 10;
      const isVisible = code !== 4 || isLast || timedOut;
      let newParseSiteProgressState: IParseSiteState = {
        done: isLast ? ParseSiteIntervalManager.state.total : done || 0,
        total: total || ParseSiteIntervalManager.state.total,
        isVisible,
        pollsWithoutProgress,
        lastPolled: new Date(),
      };
      ParseSiteIntervalManager.setState({
        total,
        done,
        isVisible,
        pollsWithoutProgress,
        lastPolled: new Date(),
      });
      if (isLast || timedOut) ParseSiteIntervalManager.clear();
      return store!.ui.parseSite.set(newParseSiteProgressState);
    });
  };

  const onAddSite = async (site: API.ICreateSite) => {
    setAddSiteDialogIsOpen(false);
    const [createSiteErr, createSiteResponse] = await createSite(site);

    const message =
      createSiteErr?.response?.data?.error ||
      createSiteErr?.message ||
      "Sucessfully added page.";

    enqueueSnackbar(message, {
      variant: createSiteErr ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });
    if (createSiteErr) return;
    await store!.data.sites.refetch();
    await store!.data.user.set({ activeSite: createSiteResponse!.data.id });
    listenToParseUpdates(createSiteResponse!.data.site_id);
  };

  const onCloseSiteDialog = () => {
    setAddSiteDialogIsOpen(false);
  };

  const onClickParseSite = async () => {
    const [parseError] = await parseSite({
      site_id: site!.site_id,
    });
    const message =
      parseError?.response?.data?.error ||
      parseError?.message ||
      "Successfully initiated parsing of site.";
    enqueueSnackbar(message, {
      variant: Boolean(parseError) ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });
    if (!parseError) {
      listenToParseUpdates(site!.site_id);
    }
  };

  const onClickDeleteSite = () => {
    setDeleteSiteModailIsOpen(true);
  };

  const onClickDeleteSiteConfirm = async () => {
    const [removeSiteError] = await removeSite(site!.id);
    const message =
      removeSiteError?.response?.data?.error ||
      removeSiteError?.message ||
      "Successfully removed site.";
    enqueueSnackbar(message, {
      variant: removeSiteError ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });

    const [fetchSitesErr, fetchSitesReponse] = await to(
      store!.data.sites.refetch()!
    );
    if (fetchSitesErr) return;

    setDeleteSiteModailIsOpen(false);
    store!.data.user.set({ activeSite: fetchSitesReponse!.data[0].id });
  };

  /**
   * --- Draft stuff ---
   */

  const [createDraftOpen, setCreateDraftOpen] = useState<boolean>(false);

  const onClickAddDraft = () => {
    setCreateDraftOpen(true);
  };

  const onAddDraft = async (draftPath: string) => {
    setCreateDraftOpen(false);

    const [addDraftErr] = await addDraft({
      competitor_page_id: null,
      draft_path: draftPath,
      user_site_id: site!.id,
    });
    const message =
      addDraftErr?.response?.data?.error || "Successfully created draft.";
    enqueueSnackbar(message, {
      variant: addDraftErr ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });
  };

  const onCloseAddDraftDialog = () => setCreateDraftOpen(false);

  /**
   * --- End draft stuff ---
   */

  return (
    <div className={classes.root}>
      <AddDraftDialog
        open={createDraftOpen}
        onAdd={onAddDraft}
        onClose={onCloseAddDraftDialog}
        indexUrl={site?.index_url || ""}
        defaultRoute={""}
      />

      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.appBarToolbar}>
          {props.renderAppToolbar({
            toggle: () => store!.ui.drawer.set(!store!.ui.drawer.get()),
            isOpen: store!.ui.drawer.get(),
          })}{" "}
        </div>
      </AppBar>
      <DrawerMUI
        variant="persistent"
        anchor="left"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        open={store!.ui.drawer.get()}
      >
        <Toolbar />
        <Divider />

        <div className={classes.contentContainer}>
          <SitePicker
            activeSiteId={activeSite}
            isLoading={userStoreIsLoading}
            onPickSite={onChangeSite}
            inputValue={sitePickerInputValue}
            onChangeInputValue={setSitePickerInputValue}
            onAddSite={onClickAddNewSite}
            sites={store!.data.sites.data || []}
            onFocus={onFocusSitePicker}
            onBlur={onBlurSitePicker}
          />

          <Box mb={2} />
          <MenuItem
            component={RRDLink}
            to="/"
            selected={!isOnOutreach && !isOnAnalytics}
          >
            <Description
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            OnPage
          </MenuItem>
          <MenuItem onClick={onClickAddDraft}>
            <Box pl={1} />
            <Note
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            Create draft
          </MenuItem>
          <MenuItem component={RRDLink} to="/outreach/links">
            <Share
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            Outreach
          </MenuItem>

          <>
            <MenuItem
              selected={isOnLinks}
              component={RRDLink}
              to="/outreach/links"
            >
              <Box pl={1} />
              <Link
                color="disabled"
                className={clsx(
                  classes.menuItemIcon,
                  classes.menuItemIconSmall
                )}
              />
              <Typography variant="body2">Links</Typography>
            </MenuItem>
            <MenuItem
              component={RRDLink}
              to="/outreach/contacts"
              selected={isOnContacts}
            >
              <Box pl={1} />
              <AccountBox
                color="disabled"
                className={clsx(
                  classes.menuItemIcon,
                  classes.menuItemIconSmall
                )}
              />
              <Typography variant="body2">Contacts</Typography>
            </MenuItem>
          </>
          <MenuItem component={RRDLink} to="/todos">
            <NoteAdd
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            ToDos
          </MenuItem>
          <MenuItem component={RRDLink} to="/trends">
            <TrendingUp
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            Trends
          </MenuItem>
          <MenuItem component={RRDLink} to="/keywordtool">
            <SubjectIcon
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            KeywordTool
          </MenuItem>

          <Box mb={2} />
          <MenuItem
            onClick={onClickParseSite}
            disabled={store!.ui.parseSite.get()?.isVisible}
          >
            <Autorenew
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            Parse
          </MenuItem>
          <MenuItem onClick={onClickDeleteSite}>
            {" "}
            <DeleteForever
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            Delete
          </MenuItem>

          <Box mb={1} />
          <Divider />
          <Box mb={1} />
          <Typography variant={"body1"} className={classes.disabledText}>
            Dashboards:
          </Typography>
          <MenuItem
            component={RRDLink}
            selected={isOnAnalytics}
            to="/analytics"
          >
            <Assessment
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            Analytics
          </MenuItem>
          <MenuItem
            component={RRDLink}
            selected={isOnSeoProgress}
            to="/seo-progress"
          >
            <TrendingUp
              className={classes.menuItemIcon}
              color="disabled"
              fontSize="small"
            />{" "}
            SEO Progress
          </MenuItem>

          {store!.ui.parseSite.get()?.isVisible && (
            <div className={classes.parseSiteProgressContainer}>
              <ParseSiteProgress
                done={store!.ui.parseSite.get()?.done || 0}
                total={store!.ui.parseSite.get()?.total || 0}
                isTimeout={
                  (store!.ui.parseSite.get()?.pollsWithoutProgress || 0) > 10
                }
              />
            </div>
          )}
        </div>
      </DrawerMUI>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: store!.ui.drawer.get(),
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>

      {addSiteDialogIsOpen && (
        <AddSiteDialog onAdd={onAddSite} onClose={onCloseSiteDialog} />
      )}

      <ConfirmDialog
        open={deleteSiteModalIsOpen}
        onClickNo={() => setDeleteSiteModailIsOpen(false)}
        onClickYes={onClickDeleteSiteConfirm}
      />
    </div>
  );
};

export default Drawer;
