import React, { FC } from "react";
import { API } from "../../../api/types";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dataWrapper: {
    display: "flex",
  },
  datum: {
    flex: 1,
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "left",
    position: "relative",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #ccc",
    },
    padding: "0 10px",
  },
  datumHeading: {
    position: "absolute",
    fontSize: "10px",
    textTransform: "uppercase",
    left: "10px",
    top: "5px",
    color: "#aaa",
  },
  url: {
    paddingLeft: "10px",
    paddingBottom: "4px",
  },
}));

const KeywordToolImportDataGscRow: FC<API.KeywordTool.IGscData> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.url} variant="body2">
        {props.url}
      </Typography>
      <div className={classes.dataWrapper}>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Position</div>
          <Typography variant="body2">
            {typeof props.position != "undefined" && props.position !== null ? (
              <>{props.position}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Clicks</div>
          <Typography variant="body2">
            {typeof props.clicks != "undefined" && props.clicks !== null ? (
              <>{props.clicks}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Impressions</div>
          <Typography variant="body2">
            {typeof props.impressions != "undefined" &&
            props.impressions !== null ? (
              <>{props.impressions}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Year-Week</div>
          <Typography variant="body2">
            {typeof props.year_week != "undefined" &&
            props.year_week !== null ? (
              <>{props.year_week}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default KeywordToolImportDataGscRow;
