import React, { FC, FormEventHandler, useRef, useContext } from "react";
import Layout from "../../components/Layout";
import { logout } from "../../utils/auth";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import useQueryAid from "../../components/SearchField/hooks";
import SearchField from "../../components/SearchField/SearchField";
import QueryAid from "../../components/SearchField/QueryAid";
import { useFetch } from "../../hooks/useFetch";
import config from "../../config";
import {
  getAuthHeaders,
  addOutreachContact,
  removeOutreachContact,
} from "../../api";
import { StoreContext } from "../../contexts";
import { API } from "../../api/types";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import {
  makeStyles,
  createStyles,
  Container,
  Typography,
  Grid,
  Fab,
} from "@material-ui/core";
import { TransitionUp } from "../../utils/snackbar";
import { useSnackbar } from "notistack";
import { grey } from "@material-ui/core/colors";
import ContactDialog from "./ContactDialog";
import ContactCard from "./components/ContactCard";
import { Add } from "@material-ui/icons";

const getSite = (sites: API.ISite[], id: number) => {
  return sites?.find((site) => site?.id === id)!;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
    menuItemIcon: {
      marginRight: theme.spacing(1),
    },
    addContactButton: {
      position: "fixed",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  })
);

const ContactsScreen: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const activeSite = store!.data.user?.data?.profile?.activeSite;
  const site = getSite(store!.data.sites.data, Number(activeSite));
  const queryAidUtils = useQueryAid(activeSite as string);
  const searchFieldInputRef = useRef(null);
  const contactsStore = useFetch<API.Outreach.IContact[]>({
    axiosConfig: {
      url: `${config.api.url}/outreach/contact`,
      headers: getAuthHeaders(),
      method: "GET",
    },
    ignore: !site,
  });

  if (contactsStore.isLoading) return <LoadingOverlay />;

  const onAddContact = async (contact: API.Outreach.ICreateContact) => {
    const [err] = await addOutreachContact(contact);

    const message =
      err?.response?.data?.error ||
      err?.message ||
      "Successfully added contact.";

    enqueueSnackbar(message, {
      variant: err ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });

    if (!err) {
      store!.ui.contactDialog.set({
        isOpen: false,
        editing: null,
      });

      contactsStore.refetch();
    }
  };

  const onEditContact = async (contact: API.Outreach.ICreateContact) => {
    const [err] = await addOutreachContact(contact);

    const message = err?.response?.data?.error || err?.message;

    if (err)
      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });

    if (!err) {
      store!.ui.contactDialog.set({
        isOpen: false,
        editing: null,
      });

      contactsStore.refetch();
    }
  };

  const onDeleteContact = async () => {
    const contact = store!.ui.contactDialog.get().editing;
    const [err] = await removeOutreachContact(contact.id);

    const message = err?.response?.data?.error || err?.message;

    if (err) {
      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    if (!err) {
      store!.ui.contactDialog.set({
        isOpen: false,
        editing: null,
      });

      contactsStore.refetch();
    }
  };

  const onClickContactCard = (contact: API.Outreach.IContact) =>
    store!.ui.contactDialog.set({
      isOpen: true,
      editing: contact,
    });

  const onCloseContactDialog = () =>
    store!.ui.contactDialog.set({
      isOpen: false,
      editing: null,
    });

  const onClickAddContact = () => {
    store!.ui.contactDialog.set({
      isOpen: true,
      editing: null,
    });
  };

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    queryAidUtils.setInputState({
      isDirty: false,
      isOpen: false,
      value: queryAidUtils.inputState.value,
    });

    if (queryAidUtils.inputState.value) queryAidUtils.onSearch();
  };

  const onClickQuery = () => {};

  const onClickSearchField = () => {
    if (queryAidUtils.inputState.isOpen)
      queryAidUtils.setInputState({
        isOpen: true,
        isDirty: false,
        value: queryAidUtils.inputState.value,
      });
  };

  const onChangeSearchField = (e: React.ChangeEvent<any>) => {
    queryAidUtils.setInputState({
      isDirty: true,
      isOpen: true,
      value: e.target.value,
    });
  };

  const onFocusSearchField = () => {
    queryAidUtils.setInputState({
      isDirty: false,
      isOpen: true,
      value: queryAidUtils.inputState.value,
    });
  };

  const onClickAwayQueryAid = () =>
    queryAidUtils.setInputState({
      isOpen: false,
      isDirty: false,
      value: queryAidUtils.inputState.value,
    });

  const searchField = (
    <form onSubmit={onSubmit}>
      <SearchField
        textFieldProps={{
          onClick: onClickSearchField,
          onFocus: onFocusSearchField,
          onChange: onChangeSearchField,
          name: "searchQuery",
          value: queryAidUtils.inputState.value,
          InputProps: {
            ref: searchFieldInputRef,
          },
        }}
        onClickSaveQuery={() => {}}
      />

      <QueryAid
        onClickAway={onClickAwayQueryAid}
        anchorEl={searchFieldInputRef.current}
        onClickQuery={onClickQuery}
        onDeleteQuery={(query) => queryAidUtils.onClickDelete(query)}
        recentQueries={queryAidUtils.filteredRecentQueries()}
        savedQueries={queryAidUtils.filteredSavedQueries()}
        isOpen={
          queryAidUtils.inputState.isOpen &&
          queryAidUtils.filteredRecentQueries().length +
            queryAidUtils.filteredSavedQueries().length >
            0
        }
      />
    </form>
  );

  return (
    <>
      <Layout
        backgroundColor={grey["200"]}
        renderAppToolbar={({ toggle, isOpen }) => (
          <AppBarToolbar
            drawerIsOpen={isOpen}
            toggleDrawer={toggle}
            onClickLogout={logout}
            goBackUrl={`/sites}`}
            searchField={searchField}
          />
        )}
      >
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            {contactsStore?.data?.length ? (
              contactsStore.data.map((contact) => {
                return (
                  <Grid item xs={6} key={contact.id}>
                    <ContactCard
                      onClick={() => onClickContactCard(contact)}
                      contact={contact}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="body2"
                  color="textSecondary"
                >
                  You don't have any contacts yet.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </Layout>

      <Fab
        onClick={onClickAddContact}
        className={classes.addContactButton}
        color="primary"
        aria-label="add"
      >
        <Add />
      </Fab>

      {store!.ui.contactDialog.get().isOpen && (
        <ContactDialog
          onAdd={
            Boolean(store!.ui.contactDialog.get().editing)
              ? onEditContact
              : onAddContact
          }
          onClose={onCloseContactDialog}
          initialValues={store!.ui.contactDialog.get().editing || undefined}
          onDelete={onDeleteContact}
        />
      )}
    </>
  );
};

export default ContactsScreen;
