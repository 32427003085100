import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  ArrowRight,
  AttachMoney,
  Comment,
  Event,
  EventBusy,
  Help,
  Image,
  KeyboardArrowUp,
  Link as LinkIcon,
  MergeType,
  TextFields,
  Warning,
} from "@material-ui/icons";
import clsx from "clsx";
import { toUnicode } from "punycode";
import React, { FC } from "react";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { API } from "../../../api/types";
import { getAuthHeaders } from "../../../api/";
import { getStatusColor, getStatusPresentationName } from "../utils";
import { useFetch } from "../../../hooks/useFetch";
import config from "../../../config";
import { Skeleton } from "@material-ui/lab";
import NoteComment from "../../Link/components/NoteComment";

interface ILinkCardProps {
  onClickProceed?: () => void;
  onClickDecline?: () => void;
  onClickOpen?: (newTab?: boolean) => void;
  onClickHeader?: () => void;
  showData: boolean;
  link: API.Outreach.Link;
  disableActions?: boolean;
  disableProceedButton?: boolean;
  disableDeclineButton?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    statBoxContainer: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      flex: 1,
    },
    statBoxContainerBorderRight: {
      borderRightColor: grey["300"],
      borderRightWidth: 1,
      borderRightStyle: "solid",
    },
    card: {
      display: "flex",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      borderBottomColor: grey["300"],
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
    },
    headerContent: {
      flex: 1,
      display: "flex",
      padding: theme.spacing(2),
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerContentLeft: {
      display: "flex",
    },
    clickableHeader: {
      "&:hover": {
        backgroundColor: grey["100"],
        cursor: "pointer",
      },
    },
    dataContainer: {
      padding: theme.spacing(2),
      borderBottomWidth: 1,
      borderBottomColor: grey["200"],
      borderBottomStyle: "solid",
    },
    stats: {
      display: "flex",
      flexDirection: "row",
    },
    attributeContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    attributeValue: { wordBreak: "break-all" },
    buttonContainer: {
      display: "flex",
      marginBottom: theme.spacing(2),
      // marginBottom: theme.spacing(2),
    },
    ahrefsContainer: {
      marginLeft: theme.spacing(2),
    },
    link: {
      cursor: "pointer",
    },
    loadingContainer: {
      width: "100%",
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    expandIcon: {
      transition: "200ms ease-out",
      transform: "rotate(180deg)",
    },
    expandIconUp: {
      transform: "rotate(0deg)",
    },
    notesTooltip: {
      padding: theme.spacing(2),
      backgroundColor: "white",
      boxShadow: theme.shadows[10],
    },
    fromUrlScreenShot: {
      width: "77px",
      height: "77px",
      marginRight: "16px",
    },
    noteName: {
      fontSize: "0.9em",
      marginBottom: "4px",
    },
  })
);

const Attribute: FC<{ label: string; icon?: any; value: any }> = (props) => {
  const classes = useStyles();

  if (!props.value) return null;

  return (
    <Tooltip title={props.label} placement="left" aria-label="add">
      <div className={classes.attributeContainer}>
        {props.icon || <Box mr="26px" />}
        {props.icon && <Box mr={1} />}
        {props.value}

        <Box mb={3} />
      </div>
    </Tooltip>
  );
};

const StatBox: FC<{
  title: string;
  disableBorder?: boolean;
  values: {
    tooltip?: string;
    label?: string;
    value: string;
    source: string;
  }[];
}> = (props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.statBoxContainer, {
        [classes.statBoxContainerBorderRight]: !props.disableBorder,
      })}
    >
      <Typography variant="body2" style={{ fontWeight: "bold" }}>
        {props.title}
      </Typography>

      <Box mb={1} />

      {props.values.map(({ value, tooltip, label, source }, index) => {
        return (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            {Boolean(tooltip) && (
              <>
                <Tooltip title={tooltip || ""} style={{ marginRight: 6 }}>
                  <Help fontSize="small" style={{ color: grey["500"] }} />
                </Tooltip>
                <Box mr={1} />
                <Box mr={1} />
              </>
            )}
            <Typography style={{ marginRight: 6 }} variant="body2">
              {label}
            </Typography>
            <Typography color="primary" variant="body1">
              {value}
            </Typography>
            <Tooltip title="Source">
              <Typography
                style={{ marginLeft: "auto" }}
                color="textSecondary"
                variant="caption"
              >
                {source}
              </Typography>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

const StatusIndicator = styled.div({
  alignSelf: "stretch",
  width: 5,
});

const LinkCard: FC<ILinkCardProps> = (props) => {
  const thumbStore = useFetch({
    axiosConfig: {
      url: `${config.api.url}/storage/outreach-link/thumbnail/${props.link.id}`,
      headers: getAuthHeaders(),
    },
    ignore: !props.link.thumbnail_path,
  });

  const classes = useStyles();

  const hasExchangeUrl = Boolean(
    props.link.exchange_from_url && props.link.exchange_to_url
  );
  const hasLowerYourCommitment = Boolean(
    hasExchangeUrl ||
      props.link.exchange_anchor ||
      props.link.exchange_is_image_link ||
      props.link.exchange_is_nofollow
  );

  const isAffectedByAntiSpam =
    props.link.majestic_affected_by_anti_spam &&
    props.link.majestic_affected_by_anti_spam !== "0";

  const onClickLink = (e: any, url: string) => {
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const theirLinkElementHeader = (
    <Typography
      variant="body1"
      style={{ display: "flex", alignItems: "center" }}
    >
      <Link
        className={classes.link}
        onClick={(e: any) => onClickLink(e, props.link.from_url)}
      >
        {toUnicode(props.link.from_url)}
      </Link>{" "}
      {props.link.to_url && (
        <>
          <ArrowRight />{" "}
          <Link
            className={classes.link}
            onClick={(e: any) => onClickLink(e, props.link.to_url)}
          >
            {toUnicode(props.link.to_url)}
          </Link>
        </>
      )}
    </Typography>
  );

  const theirLinkElement = (
    <Typography
      variant="body2"
      style={{ display: "flex", alignItems: "center" }}
    >
      <Link
        className={classes.link}
        onClick={(e: any) =>
          onClickLink(e, props.link.settled_from_url || props.link.from_url)
        }
      >
        {toUnicode(props.link.settled_from_url || props.link.from_url)}
      </Link>{" "}
      {props.link.to_url && (
        <>
          <ArrowRight />{" "}
          <Link
            className={classes.link}
            onClick={(e: any) => onClickLink(e, props.link.to_url)}
          >
            {toUnicode(props.link.to_url)}
          </Link>
        </>
      )}
    </Typography>
  );

  const hasBuyPriceAndCurrency = Boolean(
    props.link.buy_price && props.link.buy_currency
  );

  return (
    <Card classes={{ root: classes.card }}>
      <div style={{ flex: 1 }}>
        <div
          className={clsx(classes.header, {
            [classes.clickableHeader]: Boolean(props.onClickHeader),
          })}
          onClick={props.onClickHeader}
        >
          <StatusIndicator
            style={{ backgroundColor: getStatusColor(props.link.status) }}
          />
          <div
            className={classes.headerContent}
            style={{
              backgroundColor: tinycolor(getStatusColor(props.link.status))
                .setAlpha(0.1)
                .toRgbString(),
            }}
          >
            <div className={classes.headerContentLeft}>
              {thumbStore.data && thumbStore.isLoading ? (
                <Skeleton
                  className={classes.fromUrlScreenShot}
                  width={77}
                  height={77}
                />
              ) : (
                thumbStore.data && (
                  <CardMedia
                    className={classes.fromUrlScreenShot}
                    image={`data:image/png;base64, ${thumbStore.data}`}
                  />
                )
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {theirLinkElementHeader}
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: "bold",
                      color: getStatusColor(props.link.status),
                    }}
                  >
                    {getStatusPresentationName(props.link.status)}
                  </Typography>

                  {isAffectedByAntiSpam && (
                    <>
                      <Box ml={2} />
                      <Warning fontSize="small" color="error" />
                      <Box mr={"4px"} />
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="body2"
                        color="error"
                      >
                        Affected By Antispam
                      </Typography>
                    </>
                  )}
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2">
                    Updated:{" "}
                    {props.link.last_notes_date
                      ? new Date(props.link.last_notes_date).toLocaleString()
                      : new Date(props.link.updated_at).toLocaleString()}
                  </Typography>
                </div>
              </div>
            </div>
            {Boolean(props.onClickOpen) && (
              <IconButton size="small">
                <KeyboardArrowUp
                  className={clsx(classes.expandIcon, {
                    [classes.expandIconUp]: props.showData,
                  })}
                />
              </IconButton>
            )}
          </div>
        </div>
        <Collapse timeout={200} in={props.showData}>
          <div className={classes.dataContainer}>
            {!props.disableActions && (
              <div className={classes.buttonContainer}>
                {!props.disableDeclineButton && (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={props.onClickDecline}
                    >
                      Decline
                    </Button>
                    <Box mr={1} />
                  </>
                )}
                {!props.disableProceedButton && (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={props.onClickProceed}
                    >
                      Next step
                    </Button>
                    <Box mr={1} />
                  </>
                )}
                {Boolean(props.onClickOpen) && (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        props.onClickOpen!();
                      }}
                    >
                      Open
                    </Button>
                    <Box mr={1} />
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        props.onClickOpen!(true);
                      }}
                    >
                      New Tab
                    </Button>
                  </>
                )}
                <Box ml={2} />

                {Boolean(props.link.notes.length) && (
                  <Tooltip
                    classes={{
                      tooltip: classes.notesTooltip,
                    }}
                    interactive
                    title={props.link.notes.slice(0, 5).map((note) => {
                      return <NoteComment note={note} key={note.id} />;
                    })}
                  >
                    <IconButton size="small">
                      <Comment />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Your Commitment
                </Typography>
                <Box mb={1} />

                {hasBuyPriceAndCurrency && (
                  <Attribute
                    value={
                      <Typography variant="body2" color="textSecondary">
                        {props.link.buy_price + " " + props.link.buy_currency}
                      </Typography>
                    }
                    label="Buy Price"
                    icon={<AttachMoney fontSize="small" />}
                  />
                )}
                {Boolean(props.link.date_bought) && (
                  <Attribute
                    value={
                      <Typography variant="body2" color="textSecondary">
                        {props.link.date_bought}
                      </Typography>
                    }
                    label="Date Bought"
                    icon={<Event fontSize="small" />}
                  />
                )}
                {Boolean(props.link.date_removed) && (
                  <Attribute
                    value={
                      <Typography variant="body2" color="textSecondary">
                        {props.link.date_removed}
                      </Typography>
                    }
                    label="Date Removed"
                    icon={<EventBusy fontSize="small" />}
                  />
                )}
                {hasLowerYourCommitment && <Box mb={2} />}
                <Attribute
                  value={
                    Boolean(
                      props.link.exchange_from_url && props.link.exchange_to_url
                    ) && (
                      <Typography
                        variant="body2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Link
                          className={classes.link}
                          onClick={(e: any) =>
                            onClickLink(e, props.link.exchange_from_url!)
                          }
                        >
                          {toUnicode(props.link.exchange_from_url as string)}
                        </Link>{" "}
                        <ArrowRight />{" "}
                        <Link
                          className={classes.link}
                          onClick={(e: any) =>
                            onClickLink(e, props.link.exchange_to_url!)
                          }
                        >
                          {toUnicode(props.link.exchange_to_url as string)}
                        </Link>
                      </Typography>
                    )
                  }
                  label="Exchange Link"
                  icon={<LinkIcon fontSize="small" />}
                />
                {Boolean(props.link.exchange_anchor) && (
                  <Attribute
                    value={
                      <Typography variant="body2" color="textSecondary">
                        {props.link.exchange_anchor}
                      </Typography>
                    }
                    label="Anchor"
                    icon={<TextFields fontSize="small" />}
                  />
                )}
                <Attribute
                  value={
                    props.link.exchange_is_nofollow && (
                      <Typography variant="body2" color="textSecondary">
                        No Follow
                      </Typography>
                    )
                  }
                  label="No Follow"
                  icon={<MergeType fontSize="small" />}
                />
                <Attribute
                  value={
                    props.link.is_image_link && (
                      <Typography variant="body2" color="textSecondary">
                        Image Link
                      </Typography>
                    )
                  }
                  label="Image Link"
                  icon={<Image fontSize="small" />}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Their Commitment
                </Typography>
                <Box mb={1} />
                <Attribute
                  value={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {theirLinkElement}
                    </div>
                  }
                  label="Exchange Link"
                  icon={<LinkIcon fontSize="small" />}
                />
                {Boolean(props.link.link_anchor) && (
                  <Attribute
                    value={
                      <Typography variant="body2" color="textSecondary">
                        {props.link.link_anchor}
                      </Typography>
                    }
                    label="Anchor Text"
                    icon={<TextFields fontSize="small" />}
                  />
                )}
                {Boolean(props.link.is_image_link) && (
                  <Attribute
                    value={
                      <Typography variant="body2" color="textSecondary">
                        {props.link.is_image_link ? "Image Link" : ""}
                      </Typography>
                    }
                    label="Image Link"
                    icon={<Image fontSize="small" />}
                  />
                )}
              </Grid>
            </Grid>
            {!props.disableActions &&
              !props.disableProceedButton &&
              Boolean(props.link.notes.length) && (
                <div>
                  {props.link.notes.map((note) => {
                    return (
                      <React.Fragment key={note.id}>
                        <Typography
                          className={classes.noteName}
                          variant="body2"
                          color="textSecondary"
                        >
                          {note.name}, {new Date(note.created_at).toString()}
                        </Typography>
                        <Typography variant="body2">{note.text}</Typography>
                        <hr />
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
          </div>
        </Collapse>
        <div className={classes.stats}>
          {props.link.status === "new" ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <StatBox
                title="URL Score"
                values={[
                  {
                    label: "TF",
                    value:
                      typeof props.link.majestic_trust_flow === "number"
                        ? String(props.link.majestic_trust_flow)
                        : "n/a",
                    tooltip: "Trust Flow from Majestic",
                    source: "Majestic",
                  },
                  {
                    label: "CF",
                    value:
                      typeof props.link.majestic_citation_flow === "number"
                        ? String(props.link.majestic_citation_flow)
                        : "n/a",
                    tooltip: "Citation Flow from Majestic",
                    source: "Majestic",
                  },
                ]}
              />
              <StatBox
                title="Ref. Domains"
                values={[
                  {
                    value:
                      typeof props.link.majestic_ref_domains === "number"
                        ? String(props.link.majestic_ref_domains)
                        : "n/a",
                    tooltip: "Referencing domains from Majestic",
                    source: "Majestic",
                  },
                ]}
              />
              <StatBox
                disableBorder
                title="Ext. Backlinks"
                values={[
                  {
                    value:
                      typeof props.link.majestic_ext_back_links === "number"
                        ? String(props.link.majestic_ext_back_links)
                        : "n/a",
                    tooltip: "External backlinks from Majestic.",
                    source: "Majestic",
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default LinkCard;
