import React, { FC, useState } from "react";
import { API } from "../../../api/types";
import {
  CircularProgress,
  Collapse,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { KeyboardArrowUp } from "@material-ui/icons";
import { useFetch } from "../../../hooks/useFetch";
import { getAuthHeaders } from "../../../api";
import config from "../../../config";
import TrendingRelatedSiteKeywordMoreInfo from "./TrendingRelatedSiteKeywordMoreInfo";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dataWrapper: {
    display: "flex",
  },
  datum: {
    flex: 1,
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "left",
    position: "relative",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #ccc",
    },
    padding: "0 10px",
  },
  datumHeading: {
    position: "absolute",
    fontSize: "10px",
    textTransform: "uppercase",
    left: "10px",
    top: "5px",
    color: "#aaa",
  },
  seed: {
    fontWeight: "bold",
  },
  pageDetailsLink: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  bold: {
    fontWeight: "bold",
  },
  moreBar: {
    display: "flex",
    padding: "5px 0",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
  },
  expandIcon: {
    transition: "200ms ease-out",
    transform: "rotate(180deg)",
  },
  expandIconUp: {
    transform: "rotate(0deg)",
  },
  circularWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

const TrendingRelatedSiteKeyword: FC<API.Trends.ITrendingRelatedSiteKeywordsRelatedData> = (
  props
) => {
  const classes = useStyles();
  const history = useHistory();

  const gotoPageDetails = (userSiteId: number, pageId: number) => {
    history.push(`/sites/${userSiteId}/pages/${pageId}`);
  };

  const [moreOpen, setMoreOpen] = useState(Boolean(props.site_coverage));
  const [doFetchMore, setDoFetchMore] = useState(false);

  const siteCoverage = useFetch<API.Trends.ITrendingRelatedKeywordsSiteCoverageData>(
    {
      axiosConfig: {
        headers: getAuthHeaders(),
        url: `${config.api.url}/trends/keyword-coverage/${props.id}`,
      },
      ignore: true,
    }
  );

  const OpenMore = () => {
    if (moreOpen) {
      setMoreOpen(false);
    } else {
      setMoreOpen(true);

      if (
        typeof props.site_coverage == "undefined" ||
        (props.site_coverage == null && !doFetchMore)
      ) {
        setDoFetchMore(true);
        siteCoverage.refetch();
      }
    }
  };

  return (
    <>
      <div>
        <a
          href={"https://trends.google.com" + props.link}
          target="_blank"
          rel="noreferrer"
        >
          {props.query}
        </a>
        <span>
          {" "}
          (<span className={classes.seed}>{props.seed}</span> - {props.url})
        </span>
      </div>
      <div>
        <a
          href={
            "https://www.google.com/search?q=" +
            props.query?.replaceAll(" ", "+")
          }
          target="_blank"
          rel="noreferrer"
        >
          Google search
        </a>
      </div>
      <div className={classes.dataWrapper}>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Increase</div>
          <Typography variant="body2">
            {props.formattedValue === "Breakout" ? (
              <>
                {props.formattedValue} (+
                {new Intl.NumberFormat("en-US").format(props.value!)}%)
              </>
            ) : (
              props.formattedValue
            )}
          </Typography>
        </div>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Volume</div>
          <Typography variant="body2">
            {typeof props.searches != "undefined" && props.searches !== null ? (
              <>{props.searches}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
        {/*
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Competition level</div>
          <Typography variant="body2">
            {typeof props.competition != "undefined" &&
            props.competition !== null ? (
              <>{props.competition}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
        */}
        <div className={classes.datum}>
          <div className={classes.datumHeading}>CPC</div>
          <Typography variant="body2">
            {typeof props.CPC != "undefined" && props.CPC !== null ? (
              <>{props.CPC}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
      </div>
      {typeof props.ranking_pages != "undefined" &&
        props.ranking_pages !== null &&
        props.ranking_pages.length > 0 && (
          <div>
            {props.ranking_pages.map((ranking_page, index) => {
              return ranking_page.has_access ? (
                <div
                  className={classes.pageDetailsLink}
                  onClick={() =>
                    gotoPageDetails(
                      ranking_page.user_site_id,
                      ranking_page.page_id
                    )
                  }
                  key={index}
                >
                  {ranking_page.url} - (
                  <span className={classes.bold}>Pos:</span>{" "}
                  {Math.round(ranking_page.position * 10) / 10}{" "}
                  <span className={classes.bold}>Week:</span>{" "}
                  {ranking_page.year_week})
                </div>
              ) : (
                <div key={index}>
                  {ranking_page.url} - (
                  <span className={classes.bold}>Pos:</span>{" "}
                  {Math.round(ranking_page.position * 10) / 10}{" "}
                  <span className={classes.bold}>Week:</span>{" "}
                  {ranking_page.year_week})
                </div>
              );
            })}
          </div>
        )}
      <div
        className={classes.moreBar}
        style={{ display: "flex" }}
        onClick={() => OpenMore()}
      >
        <Typography variant="body2">More</Typography>
        <IconButton size="small">
          <KeyboardArrowUp
            className={clsx(classes.expandIcon, {
              [classes.expandIconUp]: moreOpen,
            })}
          />
        </IconButton>
      </div>
      <Collapse timeout={200} in={moreOpen}>
        {props.site_coverage ? (
          <TrendingRelatedSiteKeywordMoreInfo {...props.site_coverage} />
        ) : (
          <>
            {!siteCoverage.data ? (
              <div className={classes.circularWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <TrendingRelatedSiteKeywordMoreInfo {...siteCoverage.data} />
            )}
          </>
        )}
      </Collapse>
    </>
  );
};

export default TrendingRelatedSiteKeyword;
