import { Box, Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { addGoogleScope } from "../../api";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import Container from "../../components/Container/Container";
import Layout from "../../components/Layout";
import { logout } from "../../utils/auth";
import { grantAnalyticsAccess } from "./utils";

interface IGrantAnalyticsScreenProps {
  onGrantAccess: () => void;
}

const GrantAnalyticsScreen: FC<IGrantAnalyticsScreenProps> = (props) => {
  const onClickGrantAnalyticsAccess = async () => {
    const [
      grantAnalyticsAccessErr,
      grantAnalyticsAccessRes,
    ] = await grantAnalyticsAccess();
    if (grantAnalyticsAccessErr) return;
    const [addGoogleScopeErr] = await addGoogleScope({
      code: grantAnalyticsAccessRes!.code,
      scope: "https://www.googleapis.com/auth/analytics.readonly",
    });
    if (addGoogleScopeErr) return;
    props.onGrantAccess();
  };

  return (
    <Layout
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          searchField={<React.Fragment />}
          onClickLogout={logout}
        />
      )}
    >
      <Container>
        <Typography>
          In order to use this feature, you need to grant us the permission to
          use Google Analytics data.
        </Typography>
        <Box mb={1} />
        <Button
          color="primary"
          variant="outlined"
          onClick={onClickGrantAnalyticsAccess}
        >
          Grant analytics access
        </Button>
      </Container>
    </Layout>
  );
};

export default GrantAnalyticsScreen;
