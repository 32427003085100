import { useState } from "react";
import { getAuthHeaders } from "../api";
import { API } from "../api/types";
import config from "../config";
import { IParseSiteState } from "../contexts";
import { useFetch } from "../hooks/useFetch";
import useUserSettings from "../hooks/useUserSettings";

interface IUseStoreParams {
  authToken: string | null;
}

export const useStore = (params: IUseStoreParams) => {
  const sites = useFetch<API.ISite[]>(
    {
      axiosConfig: {
        url: `${config.api.url}/sites`,
        headers: getAuthHeaders(),
      },
      ignore: !Boolean(params.authToken),
    },
    [params.authToken]
  );
  const messages = useFetch({
    axiosConfig: {
      url: `${config.api.url}/messages`,
      headers: getAuthHeaders(),
    },
    ignore: !Boolean(params.authToken),
  });
  const user = useUserSettings({ ignore: !Boolean(params.authToken) }, [
    params.authToken,
  ]);
  const [drawerIsOpen, setDrawerIsOpen] = useState(true);
  const [messageDrawerIsOpen, setMessageDrawerIsOpen] = useState(false);
  const [parseSiteState, setParseSiteState] = useState<IParseSiteState | null>(
    null
  );
  const [updateData, setUpdateData] = useState<false | { messages: number }>(
    false
  );
  const [contactDialogState, setContactDialogState] = useState<{
    isOpen: boolean;
    editing: any | null;
  }>({
    isOpen: false,
    editing: null,
  });
  const [addLinkDialogState, setAddLinkDialogState] = useState<{
    isOpen: boolean;
    editing: any | null;
  }>({
    isOpen: false,
    editing: null,
  });
  return {
    data: {
      sites,
      messages,
      user,
    },
    ui: {
      drawer: {
        get: () => drawerIsOpen,
        set: setDrawerIsOpen,
      },
      messageDrawer: {
        get: () => messageDrawerIsOpen,
        set: setMessageDrawerIsOpen,
      },
      parseSite: {
        get: () => parseSiteState,
        set: setParseSiteState,
      },
      update: {
        get: () => updateData,
        set: setUpdateData,
      },
      contactDialog: {
        get: () => contactDialogState,
        set: setContactDialogState,
      },
      addLinkDialog: {
        get: () => addLinkDialogState,
        set: setAddLinkDialogState,
      },
    },
  };
};
