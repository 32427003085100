import React, { FC } from "react";
import { Box, LinearProgress, makeStyles, Typography } from "@material-ui/core";

interface IParseSiteProgressProps {
  done: number;
  total: number;
  isTimeout: boolean;
}

const useStyles = makeStyles({
  progressBar: {
    height: 7,
    borderRadius: 3,
  },
  progressDeterminate: {
    transition: "none",
  },
});

const ParseSiteProgress: FC<IParseSiteProgressProps> = (props) => {
  const classes = useStyles();
  const isDone = props.done === props.total;

  if (props.isTimeout) {
    return (
      <Typography variant="body2" color="textSecondary">
        Something went wrong trying to parse the site. Please try again.
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        {isDone
          ? "Successfully parsed site."
          : `Site is being parsed...${
              props.done ? ` (${props.done} / ${props.total})` : ""
            }`}
      </Typography>
      <Box mb={1} />
      <LinearProgress
        classes={{ bar1Determinate: classes.progressDeterminate }}
        className={classes.progressBar}
        variant="determinate"
        value={(props.done / props.total) * 100}
      />
    </>
  );
};

export default ParseSiteProgress;
