export const checkIsValidURL = (url: string) => {
  try {
    let urlVar = new URL(url);

    if (urlVar.host) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};
