import React, { FC } from "react";
import {
  makeStyles,
  createStyles,
  CircularProgress,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const StatusNew: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress />
      <Box mr={2} />
      <Typography>Fetching required data...</Typography>
    </div>
  );
};

export default StatusNew;
