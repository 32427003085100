import { AppContainer } from "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core";
import App from "./App";
import { SnackbarProvider } from "notistack";

import theme from "./theme";
import localStoragePurger from "./utils/localStoragePurger";
import { BrowserRouter } from "react-router-dom";

const render = () =>
  ReactDOM.render(
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <AppContainer>
            <App />
          </AppContainer>
        </SnackbarProvider>
      </MuiThemeProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );

localStoragePurger();
render();

// Webpack Hot Module Replacement API
if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    render();
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
