import React, { FC } from "react";
import {
  ClickAwayListener,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { IQuery } from "./types";

interface IQueryAidProps {
  anchorEl: any;

  recentQueries: IQuery[];
  savedQueries: IQuery[];

  isOpen: boolean;

  onDeleteQuery: (query: IQuery) => void;
  onClickQuery: (query: IQuery) => void;
  onClickAway: () => void;
}

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      zIndex: theme.zIndex.drawer + 2,
      width: 550,
    },
    paper: {
      paddingBottom: theme.spacing(2),
    },
    popover: {},
    paddedContent: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    addSiteButton: {
      marginTop: theme.spacing(1),
    },
    menuItem: {
      display: "flex",
    },
    menuItemQuery: {
      flex: 1,
      overflow: "hidden",
      "text-overflow": "ellipsis",
    },
    menuItemSelected: {
      "text-decoration": "none",
      "background-color": "rgba(0, 0, 0, 0.04)",
    },
    inputAdornment: {
      pointerEvents: "none",
    },
    iconButton: {
      marginLeft: "auto",
    },
  };
});

const Section: FC<{
  label: string;
  queries: IQuery[];
  onClickQuery: (query: IQuery) => void;
  onClickDeleteQuery: (query: IQuery) => void;
}> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.paddedContent}
      >
        {props.label}
      </Typography>

      {props.queries.map((query) => {
        return (
          <MenuItem
            key={query.id}
            onClick={() => props.onClickQuery(query)}
            className={classes.menuItem}
          >
            <Search
              fontSize="small"
              color="disabled"
              className={classes.icon}
            />

            <div className={classes.menuItemQuery}>
              {query.name || query.query}
              {query.name && (
                <Typography variant="body2" color="textSecondary">
                  {query.query}
                </Typography>
              )}
            </div>

            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                props.onClickDeleteQuery(query);
              }}
              className={classes.iconButton}
              size="small"
            >
              <Close fontSize="small" />
            </IconButton>
          </MenuItem>
        );
      })}
    </>
  );
};

const QueryAid: FC<IQueryAidProps> = (props) => {
  const classes = useStyles();

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={props.onClickAway}>
      <Popper
        className={classes.popper}
        anchorEl={props.anchorEl}
        open={props.isOpen}
      >
        <Paper className={classes.paper}>
          {Boolean(props.recentQueries.length) && (
            <Section
              label="Recent queries"
              queries={props.recentQueries}
              onClickQuery={props.onClickQuery}
              onClickDeleteQuery={props.onDeleteQuery}
            />
          )}
          {Boolean(props.savedQueries.length) && (
            <Section
              label="Saved queries"
              queries={props.savedQueries}
              onClickQuery={props.onClickQuery}
              onClickDeleteQuery={props.onDeleteQuery}
            />
          )}
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default QueryAid;
