// A hook used to track keypresses. When tracking keypresses in the browser, you
// you make use of "addEventListener", which requires you to unsubscribe
// from the event when it's no longer relevant, which is why a hook
// is perfect for this use case.

import { useEffect } from "react";

const useKeypress = (keyCodeToFunc: { [key: string]: () => void }) => {
  const onKeyUp = (e: KeyboardEvent) => keyCodeToFunc[e.key]?.();

  useEffect(() => {
    window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  });
};

export default useKeypress;
