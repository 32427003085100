import React, { FC, useContext } from "react";
import {
  IconButton,
  makeStyles,
  Avatar,
  Link,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../contexts";
import AppBarMenu from "./AppBarToolbarMenu";
import { Menu as MenuIcon, Message } from "@material-ui/icons";
import { deepPurple } from "@material-ui/core/colors";

interface IAppBarProps {
  searchField?: JSX.Element;
  goBackUrl?: string;
  drawerIsOpen: boolean;

  onClickLogout: () => void;
  toggleDrawer: () => void;
}

const Column = styled.div({
  display: "flex",
  alignItems: "center",
});

const useStyles = makeStyles((theme) => ({
  rootColorDefault: {
    backgroundColor: "white",
  },
  title: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  leftColumn: {
    justifyContent: "flex-start",
  },
  rightColumn: {
    justifyContent: "flex-end",
  },
  middleColumn: {
    justifyContent: "center",
  },
  messageNotificationContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    background: deepPurple["500"],
    width: 20,
    height: 20,
    borderRadius: 99,
  },
  messageNotificationText: {
    color: "white",
    fontWeight: "bold",
  },
}));

// TODO: Make title optional
const AppBarToolbar: FC<IAppBarProps> = (props) => {
  const classes = useStyles();
  const [
    menuAnchorElement,
    setMenuAnchorElement,
  ] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const store = useContext(StoreContext);
  const unreadMessages =
    ((store!.ui.update.get() as { messages: number }) || {})?.messages || 0;

  const onClickMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMenuAnchorElement(event.currentTarget);
  const onClickMenuClose = () => setMenuAnchorElement(null);

  return (
    <>
      <Column className={classes.leftColumn}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.toggleDrawer}
          edge="start"
        >
          <MenuIcon htmlColor="white" />
        </IconButton>

        <Link
          className={classes.title}
          variant="h5"
          onClick={() => history.push("/")}
        >
          Procontent
        </Link>
      </Column>

      <Column className={classes.middleColumn}>
        {props.searchField || <div />}
      </Column>

      {store!.data.user.data && (
        <>
          <Column className={classes.rightColumn}>
            <IconButton onClick={() => store!.ui.messageDrawer.set(true)}>
              {Boolean(unreadMessages) && (
                <div className={classes.messageNotificationContainer}>
                  <Typography
                    className={classes.messageNotificationText}
                    variant="body2"
                  >
                    {unreadMessages}
                  </Typography>
                </div>
              )}
              <Message htmlColor="white" />
            </IconButton>

            <IconButton
              edge="end"
              aria-haspopup="true"
              onClick={onClickMenuOpen}
            >
              <Avatar src={store!.data.user?.data?.google_image_url} />
            </IconButton>
          </Column>

          <AppBarMenu
            anchorElement={menuAnchorElement}
            onClose={onClickMenuClose}
            onLogout={props.onClickLogout}
            user={store!.data.user.data}
          />
        </>
      )}
    </>
  );
};

export default AppBarToolbar;
