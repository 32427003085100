import React, { FC } from "react";
import {
  createStyles,
  FormControl,
  makeStyles,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useFormik } from "formik";
import { addTodoNote } from "../../api";
import { TransitionUp } from "../../utils/snackbar";
import { useSnackbar } from "notistack";
import { API } from "../../api/types";
import { grey } from "@material-ui/core/colors";

interface IAddTodoDialogProps {
  open: boolean;
  onClose: () => void;
  data: API.ITodoNoteDialogData;
  pageStore?: any;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {},
    url: {
      color: grey["500"],
    },
    textAreaWrapper: {
      width: "100%",
    },
  })
);

const AddTodoDialog: FC<IAddTodoDialogProps> = (props) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      todo_text: "",
    },
    validate: (values) => {
      return {};
    },
    onSubmit: (values, formik) => {
      onSendTodo({ text: values.todo_text });
      formik.resetForm();
    },
  });

  const onAddTodo = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const onSendTodo = async (createTodoData: { text: string }) => {
    const [err] = await addTodoNote({
      ...createTodoData,
      page_id: props.data.pageId,
    });

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    if (!err) {
      formik.setFieldValue("page_id", props.data.pageId);
      formik.setFieldValue("todo_text", "");
      props.onClose();
      if (props.pageStore !== undefined) {
        props.pageStore.refetch();
      }
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <form onSubmit={onAddTodo} className={styles.form}>
        <DialogTitle>
          Add ToDo Note
          <Typography variant="body2" className={styles.url}>
            {props.data.url}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl className={styles.textAreaWrapper}>
            <TextareaAutosize
              id="todo-text"
              name="todo_text"
              rowsMin={5}
              aria-label="Todo note"
              placeholder="Write ToDo here"
              onChange={formik.handleChange}
              value={formik.values.todo_text}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTodoDialog;
