import React, { FC } from "react";
import { API } from "../../../../api/types";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { formatDistanceToNow } from "date-fns";
import TrendingRelatedPageKeyword from "../../../Trends/components/TrendingRelatedPageKeyword";

const useStyles = makeStyles((theme) =>
  createStyles({
    keywordHeading: {
      fontSize: "18px",
    },
  })
);

const Trends: FC<API.Trends.ITrendingRelatedKeywordsPageData> = (props) => {
  const classes = useStyles();

  return (
    <>
      {typeof props.lastparsed != "undefined" && props.lastparsed !== null ? (
        <>
          <Typography variant="body2">
            Last parsed:{" "}
            {formatDistanceToNow(new Date(props.lastparsed!), {
              addSuffix: true,
            })}
          </Typography>
          {props.keywords.map((keyword) => {
            return (
              <>
                <div className={classes.keywordHeading}>{keyword.keyword}</div>
                {keyword.related.map((related) => {
                  return <TrendingRelatedPageKeyword {...related} />;
                })}
              </>
            );
          })}
        </>
      ) : (
        <Typography variant="body2">
          No trending related keywords for page. Only specified page keywords
          (starred keywords) will be checked for related trending keywords and
          not all keywords will yield a result in Google Trends
        </Typography>
      )}
    </>
  );
};

export default Trends;
