import { createContext } from "react";
import { useStore } from "./utils/store";

export interface IParseSiteState {
  done: number;
  total: number;
  isVisible: boolean;
  pollsWithoutProgress: number;
  lastPolled: Date;
}

export const StoreContext = createContext<ReturnType<typeof useStore> | null>(
  null
);
