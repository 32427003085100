import React, { FC } from "react";
import { Typography, Box, Button, makeStyles } from "@material-ui/core";
import PTextField from "../../../components/Form/PTextField";
import { useFormik } from "formik";
import { API } from "../../../api/types";
import Note from "./Note";
import { grey } from "@material-ui/core/colors";

interface INotesProps {
  onAddNote?: (data: { note: string }) => void;
  onDeleteNote?: (note: API.Outreach.ILinkComment) => void;
  notes: API.Outreach.ILinkComment[];
}

const useStyles = makeStyles((theme) => ({
  notesDivider: {
    border: "1px solid",
    borderColor: grey["700"],
  },
}));

const Notes: FC<INotesProps> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      note: "",
    },
    onSubmit: (values, { resetForm }) => {
      props.onAddNote!(values);
      resetForm();
    },
  });

  return (
    <>
      {props.notes.map((note, index) => {
        //const isLast = index === props.notes.length - 1;
        return (
          <React.Fragment key={note.id}>
            {index > 0 && <Box mb={2} />}
            <Note note={note} onDeleteNote={props.onDeleteNote} />
            <Box mb={2} />
            <hr className={classes.notesDivider} />
            {/*!isLast && <Box mb={2} />*/}
          </React.Fragment>
        );
      })}

      {Boolean(!props.notes.length) && (
        <Typography color="textSecondary" variant="body2">
          No notes added yet.
        </Typography>
      )}

      {Boolean(props.onAddNote) && (
        <>
          <Box mb={3} />
          <form
            onSubmit={formik.handleSubmit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <PTextField multiline formik={formik} name="note" label="Note" />
            <Box mb={1} />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginLeft: "auto" }}
            >
              Add note
            </Button>
          </form>
        </>
      )}
    </>
  );
};

export default Notes;
