import React, { FC, useMemo, useState } from "react";
import Layout from "../../components/Layout";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";
import {
  Box,
  Button,
  Card,
  Container,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFetch } from "../../hooks/useFetch";
import { API } from "../../api/types";
import { getAuthHeaders } from "../../api";
import config from "../../config";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { green, grey, red } from "@material-ui/core/colors";
import clsx from "clsx";
import { useFormik } from "formik";
import { TransitionUp } from "../../utils/snackbar";
import { useSnackbar } from "notistack";
import CompetitorPages from "./components/CompetitorPages";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dashboardItemCard: {
    padding: "16px",
  },
  notAvailableText: {
    color: grey["500"],
  },
  rankingContainer: {
    display: "flex",
    overflow: "auto",
  },
  contentBox: {
    flex: 1,
    minWidth: 65,
  },
  weekData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  datumInc: {
    color: green["700"],
  },
  datumDec: {
    color: red["700"],
  },
  bgEven: {
    backgroundColor: "#f2f2f2",
  },
  searchForm: {
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  searchTextField: {
    marginRight: theme.spacing(1),
    backgroundColor: "#fff",
  },
}));

//const isOperator = (string: string) => [":", "<", ">"].includes(string);

const validateSearchQuery = (searchQuery: string) => {
  if (searchQuery === "") return true;

  if (searchQuery.length <= 2) {
    return false;
  }

  return true;

  /*
  return searchQuery
    .replace(/ /g, "")
    .split(",")
    .every((string) => {
      const regex = /(?<attribute>[^(:<>)]+)(?<operator>.)(?<value>.*)$/;
      const { operator, value, attribute } = string.match(regex)!.groups! as {
        attribute: string;
        operator: string;
        value: string;
      };

      return (
        operator && isOperator(operator) && attribute && value !== undefined
      );
    });
   */
};

/*
const attributeTranslationMap: any = {
  week: "year_week",
  pos: "position",
  imps: "impressions",
};

const escapeRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const matchStringQuery = (query: string | number, value: string) =>
  Boolean(
    new RegExp(
      "^" +
      escapeRegExp(String(query).replace(/"/g, "")).replace(/%/g, ".*") +
      "$"
    ).exec(value)?.length
  );

const getCompiledQuery = (searchQuery: string) => {
  return searchQuery
    .replace(/ /g, "")
    .split(",")
    .map((string) => {
      const regex = /(?<attribute>[^(:<>)]+)(?<operator>.)(?<value>.*)$/;
      const { operator, value, attribute } = string.match(regex)!.groups! as {
        attribute: string;
        operator: string;
        value: string;
      };

      return {
        attribute:
          attributeTranslationMap?.[attribute.toLowerCase()] ||
          attribute.toLowerCase(),
        value: isNaN(Number(value)) ? value : Number(value),
        operator,
      };
    })
    .filter(({ attribute }) => {
      return attribute !== "sort";
    });
};

const getSortValue = (searchQuery: string) => {
  const regex = /sort:(?<value>(.*?))(,| |$)/g;
  return regex.exec(searchQuery)?.groups?.value?.replace(/"/g, "");
};

const getSortSettingsFromValue = (sortValue: string) => {
  const direction: "DESC" | "ASC" = sortValue.startsWith("-") ? "DESC" : "ASC";

  return {
    direction,
    attribute: sortValue.replace("-", ""),
  };
};
*/

const getFilteredEntries = (
  searchQuery: string,
  entries: API.SeoProgress.IDashboardItem[]
) => {
  if (searchQuery === "") return entries;

  const filteredEntries = entries.filter((entry) => {
    return entry.page.url.toLowerCase().includes(searchQuery.toLowerCase());
  });

  //console.log(entries);
  //console.log(searchQuery);
  return filteredEntries;

  /*
  const compiledQuery = getCompiledQuery(searchQuery);
  const filteredPages = entries.filter((entry) => {
    return entry.week_data.some((week) => {
      return compiledQuery.every((condition) => {
        const value = (week as any)[condition.attribute];
        if (condition.operator === ":")
          return matchStringQuery(condition.value as string, value);
        if (condition.operator === "<") return condition.value > value;
        if (condition.operator === ">") return condition.value < value;
        return true;
      });
    });
  });
  return filteredPages;
  */
};

const SeoProgressScreen: FC = () => {
  const dataStore = useFetch<API.SeoProgress.IDashboardData>({
    axiosConfig: {
      headers: getAuthHeaders(),
      url: `${config.api.url}/seovalues/dashbord/pages`,
    },
  });

  const classes = useStyles();

  /*
  const [sortSettings, setSortSettings] = useState<{
    attribute: string;
    direction: "ASC" | "DESC";
  }>({
    direction: "DESC",
    attribute: "",
  });
   */

  const { enqueueSnackbar } = useSnackbar();

  const [searchQuery, setSearchQuery] = useState("");
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values) => {
      if (!validateSearchQuery(values.search))
        enqueueSnackbar("Invalid search query or too few characters", {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        });
      else {
        setSearchQuery(values.search);
        //setGSCDataLimit(10);
        //const sortValue = getSortValue(values.search);
        //if (sortValue) setSortSettings(getSortSettingsFromValue(sortValue));
        //else setSortSettings({ direction: "DESC", attribute: "" });
      }
    },
  });

  const entries = dataStore.data;
  const filteredEntries = useMemo(() => {
    const filteredPages = getFilteredEntries(searchQuery, entries || []);
    //if (sortSettings.attribute) return sortPages(sortSettings, filteredPages);
    return filteredPages;
    //}, [searchQuery, entries, sortSettings]);
  }, [searchQuery, entries]);

  if (!dataStore.data) return <LoadingOverlay />;

  return (
    <>
      <Layout
        backgroundColor={grey["200"]}
        renderAppToolbar={({ toggle, isOpen }) => (
          <AppBarToolbar
            drawerIsOpen={isOpen}
            toggleDrawer={toggle}
            searchField={<React.Fragment />}
            onClickLogout={logout}
          />
        )}
      >
        <Container maxWidth="md" className={classes.container}>
          <form onSubmit={formik.handleSubmit} className={classes.searchForm}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Search"
              className={classes.searchTextField}
              value={formik.values.search}
              onChange={(e) => formik.setFieldValue("search", e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
            >
              Search
            </Button>
          </form>

          {filteredEntries.map((dashboardItem, index) => {
            return (
              <React.Fragment key={index}>
                <Card className={classes.dashboardItemCard}>
                  <Typography variant="h5" color="textSecondary">
                    {dashboardItem.page.url}
                  </Typography>
                  <div className={classes.rankingContainer}>
                    <div className={`${classes.contentBox}`}>
                      <div>
                        <Typography variant="body2">Week</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">Links</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">Ref. Dom</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">CF</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">TF</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">Imps</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">Clicks</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">Keywords</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">SEO val.</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">Cli. Links</Typography>
                      </div>
                    </div>
                    {dashboardItem.week_data.map((week, index) => {
                      return (
                        <div
                          className={clsx(
                            classes.weekData,
                            classes.contentBox,
                            {
                              [classes.bgEven]: Boolean(index % 2 === 0),
                            }
                          )}
                          key={String(week.week)}
                        >
                          <div>
                            <Typography
                              variant="body2"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {String(week.week)}
                            </Typography>
                          </div>
                          {week.majestic.no_result ? (
                            <React.Fragment>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {week.majestic.previous &&
                              week.majestic.previous.ext_back_links !==
                                week.majestic.ext_back_links ? (
                                week.majestic.previous.ext_back_links >
                                week.majestic.ext_back_links! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.majestic.ext_back_links}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.majestic.ext_back_links}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.majestic.ext_back_links}
                                  </Typography>
                                </div>
                              )}

                              {week.majestic.previous &&
                              week.majestic.previous.ref_domains !==
                                week.majestic.ref_domains ? (
                                week.majestic.previous.ref_domains >
                                week.majestic.ref_domains! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.majestic.ref_domains}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.majestic.ref_domains}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.majestic.ref_domains}
                                  </Typography>
                                </div>
                              )}

                              {week.majestic.previous &&
                              week.majestic.previous.citation_flow !==
                                week.majestic.citation_flow ? (
                                week.majestic.previous.citation_flow >
                                week.majestic.citation_flow! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.majestic.citation_flow}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.majestic.citation_flow}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.majestic.citation_flow}
                                  </Typography>
                                </div>
                              )}

                              {week.majestic.previous &&
                              week.majestic.previous.trust_flow !==
                                week.majestic.trust_flow ? (
                                week.majestic.previous.trust_flow >
                                week.majestic.trust_flow! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.majestic.trust_flow}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.majestic.trust_flow}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.majestic.trust_flow}
                                  </Typography>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                          {week.gsc.no_result ? (
                            <React.Fragment>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {week.gsc.previous &&
                              week.gsc.previous.impressions !==
                                week.gsc.impressions ? (
                                week.gsc.previous.impressions >
                                week.gsc.impressions! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.gsc.impressions}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.gsc.impressions}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.gsc.impressions}
                                  </Typography>
                                </div>
                              )}

                              {week.gsc.previous &&
                              week.gsc.previous.clicks !== week.gsc.clicks ? (
                                week.gsc.previous.clicks > week.gsc.clicks! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.gsc.clicks}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.gsc.clicks}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.gsc.clicks}
                                  </Typography>
                                </div>
                              )}

                              {week.gsc.previous &&
                              week.gsc.previous.entries !== week.gsc.entries ? (
                                week.gsc.previous.entries >
                                week.gsc.entries! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.gsc.entries}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.gsc.entries}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.gsc.entries}
                                  </Typography>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                          {week.clickster.no_result ? (
                            <React.Fragment>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                              <div className={classes.notAvailableText}>
                                <Typography variant="body2">-</Typography>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {week.clickster.previous &&
                              week.clickster.previous.seo_value !==
                                week.clickster.seo_value ? (
                                week.clickster.previous.seo_value >
                                week.clickster.seo_value! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.clickster.seo_value?.toFixed(2)}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.clickster.seo_value?.toFixed(2)}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.clickster.seo_value?.toFixed(2)}
                                  </Typography>
                                </div>
                              )}

                              {week.clickster.previous &&
                              week.clickster.previous.nbr_links !==
                                week.clickster.nbr_links ? (
                                week.clickster.previous.nbr_links >
                                week.clickster.nbr_links! ? (
                                  <div className={classes.datumDec}>
                                    <Typography variant="body2">
                                      {week.clickster.nbr_links}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div className={classes.datumInc}>
                                    <Typography variant="body2">
                                      {week.clickster.nbr_links}
                                    </Typography>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <Typography variant="body2">
                                    {week.clickster.nbr_links}
                                  </Typography>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Card>
                {dashboardItem.page.nbr_competitors > 0 && (
                  <React.Fragment>
                    <Box mt={1} />
                    <CompetitorPages pageId={dashboardItem.page.id} />
                  </React.Fragment>
                )}
                <Box mt={3} />
              </React.Fragment>
            );
          })}
        </Container>
      </Layout>
    </>
  );
};

export default SeoProgressScreen;
