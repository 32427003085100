import {
  Box,
  Button,
  Container,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  addOutreachLink,
  countOutreachLinksByDomain,
  getAuthHeaders,
} from "../../api";
import { API } from "../../api/types";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import Layout from "../../components/Layout";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import SearchField from "../../components/SearchField/SearchField";
import config from "../../config";
import { StoreContext } from "../../contexts";
import { useFetch } from "../../hooks/useFetch";
import { logout } from "../../utils/auth";
import { checkIsValidURL } from "../../utils/checkIsValidURL";
import { getError, getHelperText } from "../../utils/form";
import { TransitionUp } from "../../utils/snackbar";
import LinkCard from "./components/LinkCard";
import StatusFilter from "./StatusFilter";
import { getNextStatus } from "./utils";
import useInterval from "@use-it/interval";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";

const getStatusCountMap = (links: API.Outreach.Link[]) => {
  if (!links) return {};

  return links.reduce((acc, link) => {
    return {
      ...acc,
      [link.status]:
        typeof (acc as any)[link.status] === "number"
          ? (acc as any)[link.status] + 1
          : 1,
    };
  }, {});
};

const getActiveStatus = (searchQuery: string) => {
  return /status:(?<status>.*?)(,| |$)/g.exec(searchQuery)?.groups?.status;
};

const generateSearchQueryOnStatusChange = (
  searchQuery: string,
  status: API.Outreach.Link["status"]
) => {
  const searchQueryIsEmpty = searchQuery === "";
  if (searchQueryIsEmpty) return `status:${status}`;
  const currentStatus = getActiveStatus(searchQuery);
  if (currentStatus === status)
    return searchQuery.replace(/,?status:.*?(,| |$)/g, "");
  if (currentStatus)
    return searchQuery.replace(
      /(status:)(?<status>.*?)(,| |$)/g,
      `$1${status}$3`
    );
  return `${searchQuery},status:${status}`;
};

const escapeRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const matchStringQuery = (query: string | number, value: string) =>
  Boolean(
    new RegExp(
      "^" +
        escapeRegExp(String(query).replace(/"/g, "")).replace(/%/g, ".*") +
        "$"
    ).exec(value)?.length
  );

const isOperator = (string: string) => [":", "<", ">"].includes(string);

const validateSearchQuery = (searchQuery: string) => {
  if (searchQuery === "") return true;

  return searchQuery
    .replace(/ /g, "")
    .split(",")
    .every((string) => {
      const regex = /(?<attribute>[^(:|<|>)]+)(?<operator>.)(?<value>.*)$/;
      const { operator, value, attribute } = string.match(regex)!.groups! as {
        attribute: string;
        operator: string;
        value: string;
      };

      return (
        operator && isOperator(operator) && attribute && value !== undefined
      );
    });
};

const getFilteredLinks = (searchQuery: string, links: API.Outreach.Link[]) => {
  if (searchQuery === "") return links;

  const compiledQuery = searchQuery
    .replace(/ /g, "")
    .split(",")
    .map((string) => {
      const regex = /(?<attribute>[^(:|<|>)]+)(?<operator>.)(?<value>.*)$/;
      const { operator, value, attribute } = string.match(regex)!.groups! as {
        attribute: string;
        operator: string;
        value: string;
      };

      return {
        attribute,
        value: isNaN(Number(value)) ? value : Number(value),
        operator,
      };
    })
    .filter(({ attribute }) => {
      return attribute !== "sort";
    });

  const filteredLinks = links.filter((link) => {
    return compiledQuery.every((condition) => {
      const value = (link as any)[condition.attribute];

      if (condition.operator === ":")
        return matchStringQuery(condition.value as string, value);
      if (condition.operator === "<") return condition.value > value;
      if (condition.operator === ">") return condition.value < value;

      return true;
    });
  });

  return filteredLinks;
};

const sortLinks = (
  sortSettings: { direction: "ASC" | "DESC"; attribute: string },
  links: API.Outreach.Link[]
) => {
  const { attribute, direction } = sortSettings;

  return links.sort((a: any, b: any) => {
    if (b[attribute] === a[attribute]) return b.from_url > a.from_url ? 1 : -1;
    return (
      (b[attribute] > a[attribute] ? 1 : -1) * (direction === "ASC" ? -1 : 1)
    );
  });
};

const getSortValue = (searchQuery: string) => {
  const regex = /sort:(?<value>(.*?))(,| |$)/g;
  return regex.exec(searchQuery)?.groups?.value?.replace(/"/g, "");
};

const getSortSettingsFromValue = (sortValue: string) => {
  const direction: "DESC" | "ASC" = sortValue.startsWith("-") ? "DESC" : "ASC";

  return {
    direction,
    attribute: sortValue.replace("-", ""),
  };
};

const getSite = (sites: API.ISite[], id: number) => {
  return sites?.find((site) => site?.id === id)!;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: "relative",
      height: "calc(100vh - 64px)",
      overflow: "auto",
    },
    linksContainer: {
      paddingTop: theme.spacing(2),
    },
    menuItemIcon: {
      marginRight: theme.spacing(1),
    },
    addLinkButton: {
      position: "fixed",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
    addLinkBar: {
      top: 0,
      position: "sticky",
      width: "100%",
      backgroundColor: "white",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      display: "flex",
      justifyContent: "center",
      zIndex: theme.zIndex.appBar,
      boxShadow: theme.shadows[2],
    },
    addProspectButton: {
      height: 40,
    },
    addLinkInput: {
      width: 350,
      marginRight: theme.spacing(),
    },
    sortFormControl: {
      marginLeft: "auto",
      marginBottom: theme.spacing(2),
      minWidth: 240,
    },
  })
);

const LinksScreen: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const activeSite = store!.data.user?.data?.profile?.activeSite;
  const site = getSite(store!.data.sites.data, Number(activeSite));
  const searchFieldInputRef = useRef(null);
  const history = useHistory();
  const [linkIdToOpen, setLinkIdToOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [sortSettings, setSortSettings] = useState<{
    attribute: string;
    direction: "ASC" | "DESC";
  }>({
    direction: "DESC",
    attribute: "",
  });
  const [domainConfirmIsOpen, setDomainConfirmIsOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      from_url: "",
    },
    validate: (values) => {
      if (!checkIsValidURL(values.from_url))
        return {
          from_url: "The url is not valid",
        };
      return {};
    },
    onSubmit: (values, formik) => {
      onAddLink(values);
      formik.resetForm();
    },
  });

  const searchFieldFormik = useFormik({
    initialValues: {
      searchQuery: "",
    },
    onSubmit: (values) => {
      if (!validateSearchQuery(values.searchQuery))
        enqueueSnackbar("Invalid search query", {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        });
      else {
        const sortValue = getSortValue(values.searchQuery);
        if (sortValue) setSortSettings(getSortSettingsFromValue(sortValue));
        else setSortSettings({ direction: "DESC", attribute: "" });
        setSearchQuery(values.searchQuery);
      }
    },
  });

  const linksStore = useFetch<API.Outreach.Link[]>(
    {
      axiosConfig: {
        url: `${config.api.url}/outreach/link/all/${activeSite as string}`,
        headers: getAuthHeaders(),
        method: "GET",
      },
      ignore: !site,
    },
    [activeSite]
  );

  const links = useMemo(() => {
    const filteredLinks = getFilteredLinks(searchQuery, linksStore?.data || []);
    if (sortSettings.attribute) return sortLinks(sortSettings, filteredLinks);
    return filteredLinks;
  }, [searchQuery, linksStore, sortSettings]);

  useInterval(() => {
    if (linksStore?.data?.some?.((link) => link.status === "new"))
      linksStore.refetch();
  }, 10000);

  const onAddLink = async (createLinkData: API.Outreach.ICreateLink) => {
    const [err] = await addOutreachLink({
      ...createLinkData,
      status: "new",
      user_site_id: activeSite,
    });

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    if (!err) {
      linksStore.refetch();
      formik.setFieldValue("from_url", "");
    }
  };

  const onClickStatus = (status: API.Outreach.Link["status"]) => {
    searchFieldFormik.setFieldValue(
      "searchQuery",
      generateSearchQueryOnStatusChange(
        searchFieldFormik.values.searchQuery,
        status
      )
    );
    searchFieldFormik.submitForm();
  };

  useEffect(
    () => {
      onClickStatus("analyze_pending");
    },
    // eslint-disable-next-line
    []
  );

  if (linksStore.isLoading && !linksStore.data) return <LoadingOverlay />;

  const onClickLinkHeader = (link: API.Outreach.Link) => {
    setLinkIdToOpen({
      ...linkIdToOpen,
      [link.id]: !linkIdToOpen[link.id],
    });
  };

  const onClickOpenLink = (link: API.Outreach.Link, newTab = false) => {
    console.log("in onClickOpenLink");
    if (newTab) {
      window.open(`/outreach/links/${link.id}`, "_blank");
    } else {
      history.push(`/outreach/links/${link.id}`);
    }
  };

  const onClickProceeddLink = async (link: API.Outreach.Link) => {
    const [err] = await addOutreachLink({
      ...link,
      status: getNextStatus(link, true),
    });

    const message =
      err?.response?.data?.error ||
      err?.message ||
      `Successfully moved link to ${getNextStatus(link, true)}`;

    enqueueSnackbar(message, {
      variant: err ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });

    linksStore.refetch();
  };

  const onClickDeclineLink = async (link: API.Outreach.Link) => {
    const [err] = await addOutreachLink({
      ...link,
      status: getNextStatus(link, false),
    });

    const message =
      err?.response?.data?.error ||
      err?.message ||
      `Successfully declined link`;

    enqueueSnackbar(message, {
      variant: err ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });

    linksStore.refetch();
  };

  const onAddNewProspect = async (e: any) => {
    e.preventDefault();

    const [errDomainCheck, domainCount] = await countOutreachLinksByDomain({
      user_site_id: activeSite,
      from_url: formik.values.from_url,
    });

    if (!errDomainCheck && domainCount?.data.count > 0) {
      setDomainConfirmIsOpen(true);
    } else {
      formik.handleSubmit();
    }
  };

  // const onSubmit: FormEventHandler = (e) => {
  //   e.preventDefault();

  //   // queryAidUtils.setInputState({
  //   //   isDirty: false,
  //   //   isOpen: false,
  //   //   value: queryAidUtils.inputState.value,
  //   // });

  //   // if (queryAidUtils.inputState.value) queryAidUtils.onSearch();
  // };

  // const onClickQuery = () => {};

  // const onClickSearchField = () => {
  //   if (queryAidUtils.inputState.isOpen)
  //     queryAidUtils.setInputState({
  //       isOpen: true,
  //       isDirty: false,
  //       value: queryAidUtils.inputState.value,
  //     });
  // };

  // const onChangeSearchField = (e: React.ChangeEvent<any>) => {
  //   queryAidUtils.setInputState({
  //     isDirty: true,
  //     isOpen: true,
  //     value: e.target.value,
  //   });
  // };

  // const onFocusSearchField = () => {
  //   queryAidUtils.setInputState({
  //     isDirty: false,
  //     isOpen: true,
  //     value: queryAidUtils.inputState.value,
  //   });
  // };

  // console.log();

  // const onClickAwayQueryAid = () =>
  //   queryAidUtils.setInputState({
  //     isOpen: false,
  //     isDirty: false,
  //     value: queryAidUtils.inputState.value,
  //   });

  const searchField = (
    <form onSubmit={searchFieldFormik.handleSubmit}>
      <SearchField
        textFieldProps={{
          onChange: searchFieldFormik.handleChange,
          name: "searchQuery",
          InputProps: {
            ref: searchFieldInputRef,
          },
          value: searchFieldFormik.values.searchQuery,
        }}
        onClickSaveQuery={() => {}}
      />

      {/* <QueryAid
        onClickAway={onClickAwayQueryAid}
        anchorEl={searchFieldInputRef.current}
        onClickQuery={onClickQuery}
        onDeleteQuery={(query) => queryAidUtils.onClickDelete(query)}
        recentQueries={queryAidUtils.filteredRecentQueries()}
        savedQueries={queryAidUtils.filteredSavedQueries()}
        isOpen={
          queryAidUtils.inputState.isOpen &&
          queryAidUtils.filteredRecentQueries().length +
            queryAidUtils.filteredSavedQueries().length >
            0
        }
      /> */}
    </form>
  );
  return (
    <Layout
      backgroundColor={grey["200"]}
      renderAppToolbar={({ toggle, isOpen }) => (
        <AppBarToolbar
          drawerIsOpen={isOpen}
          toggleDrawer={toggle}
          onClickLogout={logout}
          goBackUrl={`/sites}`}
          searchField={searchField}
        />
      )}
    >
      <div className={classes.container}>
        <form className={classes.addLinkBar} onSubmit={onAddNewProspect}>
          <Container
            maxWidth="md"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div>
              <TextField
                value={formik.values.from_url}
                name="from_url"
                onChange={formik.handleChange}
                variant="outlined"
                label="URL"
                size="small"
                className={classes.addLinkInput}
                helperText={getHelperText(formik as any, "from_url")}
                error={getError(formik as any, "from_url")}
              />
              <Button
                type="submit"
                variant="outlined"
                className={classes.addProspectButton}
                color="primary"
              >
                Add
              </Button>
            </div>

            <StatusFilter
              activeStatus={
                (getActiveStatus(
                  searchFieldFormik.values.searchQuery
                ) as any) || null
              }
              onClickStatus={onClickStatus}
              statusCountMap={getStatusCountMap(linksStore.data) as any}
            />
          </Container>
        </form>
        <Container maxWidth="md" className={classes.linksContainer}>
          {links.length ? (
            links.map((link) => {
              return (
                <React.Fragment key={link.id}>
                  <LinkCard
                    disableDeclineButton={
                      link.status === "declined" ||
                      link.status === "analyze_no_go"
                    }
                    disableProceedButton={link.status !== "analyze_pending"}
                    onClickProceed={() => onClickProceeddLink(link)}
                    onClickDecline={() => onClickDeclineLink(link)}
                    onClickOpen={(newTab = false) =>
                      onClickOpenLink(link, newTab)
                    }
                    onClickHeader={() => onClickLinkHeader(link)}
                    showData={linkIdToOpen[link.id]}
                    link={link}
                  />
                  <Box mb={2} />
                </React.Fragment>
              );
            })
          ) : (
            <>
              <Typography align="center" variant="body2" color="textSecondary">
                No links found.
              </Typography>
            </>
          )}
        </Container>
      </div>
      <ConfirmDialog
        open={domainConfirmIsOpen}
        question={
          "A outreach link for the domain already exists. Still Proceed?"
        }
        onClickNo={() => setDomainConfirmIsOpen(false)}
        onClickYes={() => {
          setDomainConfirmIsOpen(false);
          formik.handleSubmit();
        }}
      />
    </Layout>
  );
};

export default LinksScreen;
