import axios, { AxiosError, AxiosResponse } from "axios";
import config from "../config";
import { API } from "./types";
import to from "await-to-js";
interface IVerifyGoogleAuthResponseData {
  token: string;
}

// API utility functions
export const getAuthToken = () => localStorage.getItem("authToken") as string;
export const getAuthHeaders = () => ({
  Authorization: `Bearer ${getAuthToken()}`,
});

// API functions
export const verifyGoogleAuth = (
  token: string
): Promise<AxiosResponse<IVerifyGoogleAuthResponseData>> =>
  axios.post(`${config.api.url}/verify-google-auth`, { idtoken: token });

export const verifyGoogleAuthOffline = (
  code: string
): Promise<AxiosResponse<IVerifyGoogleAuthResponseData>> =>
  axios.post(`${config.api.url}/google-login`, { code });

export const getSites = (): Promise<AxiosResponse<any>> => {
  return axios.get(`${config.api.url}/sites`, { headers: getAuthHeaders() });
};

export const createSite = (siteData: API.ICreateSite) => {
  return to<AxiosResponse<API.ISite>, AxiosError>(
    axios.post<API.ISite>(`${config.api.url}/sites/create`, siteData, {
      headers: getAuthHeaders(),
    })
  );
};

export const removeSite = (userSiteId: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.delete(`${config.api.url}/sites/user-site/${userSiteId}`, {
      headers: getAuthHeaders(),
    })
  );

export const getThumbnails = async (sites: API.ISite[]) => {
  const thumbnails: { [key: string]: string } = {};
  for (const site of sites) {
    try {
      thumbnails[site.id] = (await getThumbnail(site.thumbnail_path)).data;
    } catch (e) {}
  }
  return thumbnails;
};

export const getThumbnail = (path: string): Promise<AxiosResponse<any>> =>
  axios.get(`${config.api.url}/storage/site-thumbs/${path}`, {
    headers: getAuthHeaders(),
  });

export const linkPage = (data: {
  page_id: number;
  competitor_page_id?: number;
  competitor_url?: string;
}) =>
  to<AxiosResponse<null>, AxiosError>(
    axios.post<null>(`${config.api.url}/pages/competitor/connect`, data, {
      headers: getAuthHeaders(),
    })
  );

export const removeLink = (data: {
  user_page_id: number;
  competitor_id: number;
  competitor_page_id: number;
}) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/pages/competitor/delete-connection`, data, {
      headers: getAuthHeaders(),
    })
  );

export const verifySite = (data: { user_site_id: number }) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/sites/verify`, data, {
      headers: getAuthHeaders(),
    })
  );

export const parseSite = (data: { site_id: number }) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/sites/parse`, data, {
      headers: getAuthHeaders(),
    })
  );

export const parsePage = (data: { page_id: number }) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/pages/parse`, data, {
      headers: getAuthHeaders(),
    })
  );

export const starPage = (data: { id: number }) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/pages/star`, data, {
      headers: getAuthHeaders(),
    })
  );

export const unstarPage = (pageId: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.delete(`${config.api.url}/pages/star/${pageId}`, {
      headers: getAuthHeaders(),
    })
  );

export const getSiteParseProgress = (siteId: string) =>
  to<AxiosResponse<{ code: number; total: number; done: number }>, AxiosError>(
    axios.get<{ code: number; total: number; done: number }>(
      `${config.api.url}/sites/nbr-parsed-pages/${siteId}`,
      {
        headers: getAuthHeaders(),
      }
    )
  );

export const addKeyword = (data: {
  page_id: number;
  user_site_id: number;
  keywords: string[];
}) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/pages/keywords`, data, {
      headers: getAuthHeaders(),
    })
  );

export const removeKeyword = (keywordId: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.delete(`${config.api.url}/pages/keywords/${keywordId}`, {
      headers: getAuthHeaders(),
    })
  );

export const addDraft = (data: {
  user_site_id: number;
  competitor_page_id: number | null;
  draft_path: string;
}) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/pages/create-draft`, data, {
      headers: getAuthHeaders(),
    })
  );

export const openedMessageDrawer = () =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/messages/opened-drawer`, null, {
      headers: getAuthHeaders(),
    })
  );

export const readMessage = (id: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(
      `${config.api.url}/messages/read`,
      { id },
      {
        headers: getAuthHeaders(),
      }
    )
  );

export const whatsNew = () =>
  to<AxiosResponse<any>, AxiosError>(
    axios.get(`${config.api.url}/updates/whats-new`, {
      headers: getAuthHeaders(),
    })
  );

export const countOutreachLinksByDomain = (domainData: any) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(
      `${config.api.url}/outreach/link/note/check-domain`,
      domainData,
      {
        headers: getAuthHeaders(),
      }
    )
  );

export const addOutreachLink = (outreachLink: any) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/outreach/link/save`, outreachLink, {
      headers: getAuthHeaders(),
    })
  );

export const removeOutreachLink = (linkId: string) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.delete(`${config.api.url}/outreach/link/${linkId}`, {
      headers: getAuthHeaders(),
    })
  );

export const addOutreachLinkComment = (data: API.Outreach.ICreateLinkComment) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/outreach/link/note/save`, data, {
      headers: getAuthHeaders(),
    })
  );

export const removeOutreachLinkComment = (noteId: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.delete(`${config.api.url}/outreach/link/note/${noteId}`, {
      headers: getAuthHeaders(),
    })
  );

export const addOutreachContact = (contact: API.Outreach.ICreateContact) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/outreach/contact/save`, contact, {
      headers: getAuthHeaders(),
    })
  );

export const removeOutreachContact = (contactId: string) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.delete(`${config.api.url}/outreach/contact/${contactId}`, {
      headers: getAuthHeaders(),
    })
  );

export const changeOutreachLinkStatus = (
  linkId: number,
  status: API.Outreach.Link["status"]
) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(
      `${config.api.url}/outreach/link/save`,
      { id: linkId, status },
      { headers: getAuthHeaders() }
    )
  );

export const addTodoNote = (todoNote: any) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/todo/item/add`, todoNote, {
      headers: getAuthHeaders(),
    })
  );

export const setTodoNoteDone = (todoNoteId: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(
      `${config.api.url}/todo/item/done`,
      { id: todoNoteId },
      {
        headers: getAuthHeaders(),
      }
    )
  );

export const saveUserSettings = (settings: any) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(
      `${config.api.url}/auth/profile`,
      { profile: settings },
      {
        headers: getAuthHeaders(),
      }
    )
  );

export const getUser = () =>
  to<AxiosResponse<any>, AxiosError>(
    axios.get(`${config.api.url}/auth/user`, { headers: getAuthHeaders() })
  );

export const addGoogleScope = (data: { code: string; scope: string }) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/auth/add-google-scope`, data, {
      headers: getAuthHeaders(),
    })
  );

export const addAnalyticsSite = (data: {
  user_site_id: number;
  google_account_id: string;
  google_web_property_id: string;
}) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/analytics/dashboard/sites/add`, data, {
      headers: getAuthHeaders(),
    })
  );

export const deleteAnalyticsSite = (siteId: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.delete(`${config.api.url}/analytics/dashboard/sites/${siteId}`, {
      headers: getAuthHeaders(),
    })
  );

export const refetchGSCWeek = (yearWeek: string, siteId: number) =>
  to<AxiosResponse<any>, AxiosError>(
    axios.post(
      `${config.api.url}/searchconsole/searchanalytics/refetch-week`,
      { year_week: yearWeek, site_id: siteId },
      { headers: getAuthHeaders() }
    )
  );

export const uploadKeywordToolCsvFile = (data: {
  file: File;
  user_site_id: number;
}) => {
  const formData = new FormData();
  formData.append("file", data.file, data.file.name);
  formData.append("user_site_id", data.user_site_id.toString());

  return to<AxiosResponse<any>, AxiosError>(
    axios.post(`${config.api.url}/keywordtool/upload`, formData, {
      headers: getAuthHeaders(),
    })
  );
};

export const uploadNoteAttachment = (data: { file: File; note_id: number }) => {
  const formData = new FormData();
  formData.append("file", data.file, data.file.name);
  formData.append("note_id", data.note_id.toString());

  return to<AxiosResponse<any>, AxiosError>(
    axios.post(
      `${config.api.url}/outreach/link/note/upload-attachment`,
      formData,
      {
        headers: getAuthHeaders(),
      }
    )
  );
};

export const downloadNoteAttachment = (data: {
  note_id: number;
  file_name: string;
}) => {
  axios({
    url: `${config.api.url}/outreach/link/note/get-attachment/${data.note_id}`,
    method: "GET",
    responseType: "blob", // important
    headers: getAuthHeaders(),
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", data.file_name);
    document.body.appendChild(link);
    link.click();
  });
};
