import React, { FC } from "react";
import {
  createStyles,
  makeStyles,
  Paper,
  Popover,
  useTheme,
} from "@material-ui/core";

interface ISettingsManager {
  anchorElement: Element | null;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    popover: {
      zIndex: theme.zIndex.tooltip,
    },
    paper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    label: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  })
);

const SettingsManager: FC<ISettingsManager> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Popover
      className={classes.popover}
      open={props.open}
      anchorEl={props.anchorElement}
      onClose={props.onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{
        horizontal: "center",
        vertical: -theme.spacing(1),
      }}
    >
      <Paper className={classes.paper}>{props.children}</Paper>
    </Popover>
  );
};

export default SettingsManager;
