import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import Layout from "../../components/Layout";
import { useFetch } from "../../hooks/useFetch";
import { logout } from "../../utils/auth";
import config from "../../config";
import {
  addAnalyticsSite,
  deleteAnalyticsSite,
  getAuthHeaders,
} from "../../api";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import GrantAnalyticsScreen from "./GrantAnalyticsScreen";
import useInterval from "@use-it/interval";
import SiteCard from "./SiteCard";
import { grey } from "@material-ui/core/colors";
import { Add } from "@material-ui/icons";
import AddSiteDialog from "./AddSiteDialog";
import { API } from "../../api/types";
import { useSnackbar } from "notistack";
import { TransitionUp } from "../../utils/snackbar";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { differenceInSeconds } from "date-fns";
import { useHistory } from "react-router";
import AnalyticsReorderDialog from "./AnalyticsReorderDialog";
import { StoreContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  addSiteButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const getNewElements = (oldArray: any[], newArray: any[]) => {
  const oldArraySet = new Set(oldArray);
  return newArray.reduce((acc, value) => {
    if (!oldArraySet.has(value)) return [...acc, value];
    return acc;
  }, []);
};

const getMissingElements = (oldArray: any[], newArray: any[]) => {
  const newArraySet = new Set(newArray);
  return oldArray.reduce((acc, val) => {
    if (!newArraySet.has(val)) return [...acc, val];
    return acc;
  }, []);
};

const AnalyticsScreen: FC = () => {
  const store = useContext(StoreContext);
  const [menuContext, setMenuContext] = useState<{
    element: any;
    activeSnapshot: null | API.Analytics.ISnapshot;
  }>({
    element: null,
    activeSnapshot: null,
  });
  const history = useHistory();
  const [snapshotRefreshDate, setSnapshotRefreshDate] = useState<null | Date>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [addSiteDialogIsOpen, setAddSiteDialogIsOpen] = useState(false);
  const [reorderDialogIsOpen, setReorderDialogIsOpen] = useState(false);
  const [
    activeSnapshotToDelete,
    setActiveSnapshotToDelete,
  ] = useState<API.Analytics.ISnapshot | null>(null);
  const hasAnalyticsScopeStore = useFetch({
    axiosConfig: {
      url: `${config.api.url}/auth/has-analytics-scope`,
      headers: getAuthHeaders(),
    },
  });
  const availableSitesStore = useFetch(
    {
      axiosConfig: {
        url: `${config.api.url}/analytics/dashboard/available-sites`,
        headers: getAuthHeaders(),
      },
      ignore: !hasAnalyticsScopeStore?.data?.has_analytics_scope,
    },
    [hasAnalyticsScopeStore?.data]
  );
  const snapshotsStore = useFetch<API.Analytics.ISnapshot[]>(
    {
      axiosConfig: {
        url: `${config.api.url}/analytics/dashboard/snapshots`,
        headers: getAuthHeaders(),
      },
      ignore: !hasAnalyticsScopeStore?.data?.has_analytics_scope,
    },
    [hasAnalyticsScopeStore?.data]
  );
  const disableReorder = (snapshotsStore?.data?.length || 0) <= 1;
  const hasSites = snapshotsStore?.data?.length > 0;
  const [snapshotOrder, setSnapshotOrder] = useState<null | number[]>(null);
  useInterval(() => {
    const secondsSinceAddingSite = snapshotRefreshDate
      ? differenceInSeconds(snapshotRefreshDate, new Date())
      : Infinity;

    const hasSnapshots = Boolean(snapshotsStore?.data?.length);

    if (
      (hasAnalyticsScopeStore?.data?.has_analytics_scope && hasSnapshots) ||
      secondsSinceAddingSite <= 60
    )
      snapshotsStore.refetch();
  }, 10000);

  const snapshotsOrdered = useMemo(() => {
    return (snapshotOrder || [])
      .map((id) =>
        (snapshotsStore?.data || []).find((snapshot) => snapshot.id === id)
      )
      .filter(Boolean) as API.Analytics.ISnapshot[];
  }, [snapshotOrder, snapshotsStore?.data]);

  useEffect(() => {
    const snapshotStoreIds = (snapshotsStore.data || []).map(
      (snapshot) => snapshot.id
    );

    const savedSnapshotOrder: number[] = store!.data.user?.data?.profile
      ?.analyticsCardOrder;

    if (!snapshotOrder && savedSnapshotOrder)
      return setSnapshotOrder(savedSnapshotOrder);

    const newSnapshotIds: number[] = getNewElements(
      snapshotOrder || [],
      snapshotStoreIds
    );

    const removedSnapshotIds: number[] = getMissingElements(
      snapshotOrder || [],
      snapshotStoreIds
    );

    const newSnapshotOrder = [
      ...(snapshotOrder || []).filter((id) => !removedSnapshotIds.includes(id)),
      ...newSnapshotIds,
    ];

    store!.data.user.set({
      analyticsCardOrder: newSnapshotOrder,
    });

    setSnapshotOrder(newSnapshotOrder);
    // eslint-disable-next-line
  }, [snapshotsStore.data]);

  const onGrantAccess = () => {
    setSnapshotRefreshDate(new Date());
    hasAnalyticsScopeStore.refetch();
  };
  const onClickAddSiteButton = () => {
    if (!availableSitesStore?.data?.length)
      return enqueueSnackbar("There are currently no sites available.", {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    setAddSiteDialogIsOpen(true);
  };
  const onAddSite = async (site: API.Analytics.IAvailableSite) => {
    setAddSiteDialogIsOpen(false);
    const [err] = await addAnalyticsSite({
      google_account_id: site.google_account_id,
      google_web_property_id: site.google_web_property_id,
      user_site_id: site.id,
    });
    if (err) {
      const message = err?.response?.data?.error || err?.message;
      return enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }
    setSnapshotRefreshDate(new Date());
    snapshotsStore.refetch();
    availableSitesStore.refetch();
  };
  const onCloseSiteDialog = () => setAddSiteDialogIsOpen(false);
  const onClickDeleteSite = async (snapshot: API.Analytics.ISnapshot) => {
    const [err] = await deleteAnalyticsSite(snapshot.dashbord_site_id);
    setActiveSnapshotToDelete(null);
    if (err) return;
    snapshotsStore.refetch();
    availableSitesStore.refetch();
  };
  const onClickPage = (pageId: number, siteId: number) => {
    if (siteId !== store!.data.user.data?.profile?.activeSite)
      store!.data.user.set(
        {
          activeSite: siteId,
        },
        { optimisticResponse: true }
      );
    history.push(`/sites/${siteId}/pages/${pageId}`);
  };
  const onClickReorder = () => {
    setMenuContext({ ...menuContext, element: null });
    setReorderDialogIsOpen(true);
  };
  const onChangeOrder = (snapshots: API.Analytics.ISnapshot[]) => {
    store!.data.user.set({
      analyticsCardOrder: snapshots.map((snapshot) => snapshot.id),
    });
    setSnapshotOrder(snapshots.map((snapshot) => snapshot.id));
  };
  const onClickMoreMenu = (e: any, snapshot: API.Analytics.ISnapshot) => {
    setMenuContext({
      activeSnapshot: snapshot,
      element: e.currentTarget,
    });
  };
  const onCloseMenu = () =>
    setMenuContext({ element: null, activeSnapshot: null });

  const onClickDelete = () => {
    setActiveSnapshotToDelete(menuContext.activeSnapshot);
    setMenuContext({ element: null, activeSnapshot: null });
  };

  if (
    hasAnalyticsScopeStore.isLoading ||
    (!snapshotsStore.data && snapshotsStore.isLoading)
  )
    return <LoadingOverlay />;
  if (!hasAnalyticsScopeStore.data.has_analytics_scope)
    return <GrantAnalyticsScreen onGrantAccess={onGrantAccess} />;
  return (
    <>
      <Layout
        backgroundColor={grey["200"]}
        renderAppToolbar={({ toggle, isOpen }) => (
          <AppBarToolbar
            drawerIsOpen={isOpen}
            toggleDrawer={toggle}
            searchField={<React.Fragment />}
            onClickLogout={logout}
          />
        )}
      >
        <div className={classes.container}>
          <Grid spacing={2} container>
            {hasSites ? (
              snapshotsOrdered!.map?.((snapshot) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SiteCard
                      onClickPage={onClickPage}
                      snapshot={snapshot}
                      onClickMoreMenu={(e) => onClickMoreMenu(e, snapshot)}
                    />
                  </Grid>
                );
              })
            ) : (
              <>
                <Box mt={1} />
                <Typography
                  style={{ width: "100%" }}
                  align="center"
                  variant="body2"
                  color="textSecondary"
                >
                  There are no sites added yet.
                </Typography>
              </>
            )}
          </Grid>
        </div>
      </Layout>
      <Fab
        onClick={onClickAddSiteButton}
        className={classes.addSiteButton}
        color="primary"
        aria-label="add"
        disabled={availableSitesStore.isLoading}
      >
        {availableSitesStore.isLoading ? (
          <CircularProgress style={{ color: "white" }} size={"18px"} />
        ) : (
          <Add />
        )}
      </Fab>

      {addSiteDialogIsOpen && (
        <AddSiteDialog
          availableSites={availableSitesStore.data}
          onAdd={onAddSite as any}
          onClose={onCloseSiteDialog}
        />
      )}

      <ConfirmDialog
        open={Boolean(activeSnapshotToDelete)}
        onClickNo={() => setActiveSnapshotToDelete(null)}
        onClickYes={() => onClickDeleteSite(activeSnapshotToDelete!)}
      />

      {snapshotsStore.data?.length > 0 && (
        <AnalyticsReorderDialog
          snapshots={snapshotsOrdered}
          onChangeOrder={onChangeOrder}
          open={reorderDialogIsOpen}
          onClose={() => setReorderDialogIsOpen(false)}
        />
      )}

      <Menu
        anchorEl={menuContext.element}
        keepMounted
        open={Boolean(menuContext.element)}
        onClose={onCloseMenu}
      >
        {!disableReorder && (
          <MenuItem onClick={onClickReorder}>Reorder</MenuItem>
        )}
        <MenuItem onClick={onClickDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default AnalyticsScreen;
