// When developing, we might want to purge certain local storage
// keys. If the version of the package.json is bumped and you
// include a key inside the "keysToPurge" array, that key will
// be removed when a user opens the app again.

import { version } from "../../package.json";

const keysToPurge = ["filters"];

const localStoragePurger = () => {
  if (localStorage.getItem("version") === version) return;
  keysToPurge.forEach((key) => localStorage.removeItem(key));
  localStorage.setItem("version", version);
};

export default localStoragePurger;
