import React, { FC } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  makeStyles,
  createStyles,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
  })
);

const LinkComponent: FC<{ title?: string; description?: string }> = (props) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        {props.title && (
          <div className={classes.header}>
            {props.description && (
              <>
                <Tooltip placement="left" title="Hej">
                  <IconButton size="small">
                    <Info />
                  </IconButton>
                </Tooltip>
                <Box mr={1} />
              </>
            )}
            <Typography variant="h6">{props.title}</Typography>
          </div>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
};

export default LinkComponent;
