// A hook for easier fetching resources.
// Caveat: Can't use api funtions from /api/index

import { useEffect, useRef, useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface IUseFetchConfig {
  axiosConfig: AxiosRequestConfig;
  // You can't conditionally run hooks, but you might not want to
  // fetch a resource no matter what. You might want to only
  // fetch a resoure if the user if authenticated, for example.
  // If this is the case, you can make use of the ignore parameter,
  // which will prevent the request from happening if it's set to
  // true.
  ignore?: boolean;
}

export interface IUseFetchReturn<T = any> {
  data: T;
  isLoading: boolean;
  error: any;
  refetch: () => Promise<AxiosResponse<T>>;
  set: (cb: (previousData: any) => any) => void;
}

export const useFetch = <T = any>(
  config: IUseFetchConfig,
  deps?: any[]
): IUseFetchReturn<T> => {
  const [state, setState] = useState<{
    data: T | any;
    isLoading: boolean;
    error: any;
  }>({
    data: null,
    isLoading: !config.ignore,
    error: null,
  });

  const fetch = () => {
    setState({ data: state.data, isLoading: true, error: null });
    return axios(config.axiosConfig)
      .then((response) => {
        setState({ data: response.data, isLoading: false, error: null });
        return response;
      })
      .catch((err) => {
        setState({ data: null, isLoading: false, error: err });
        return err;
      });
  };

  const set = (cb: (previousData: any) => any) => {
    setState({
      ...state,
      data: cb(state.data),
    });
  };

  // eslint-disable-next-line
  useEffect(
    config.ignore
      ? () => {}
      : () => {
          fetch();
        },
    deps || []
  );

  return {
    data: state.data,
    isLoading: state.isLoading,
    error: state.error,
    refetch: fetch,
    set,
  };
};

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
