import {
  ClickAwayListener,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, KeyboardArrowDown } from "@material-ui/icons";
import clsx from "clsx";
import { toUnicode } from "punycode";
import React, { FC, FormEventHandler, useRef, useState } from "react";
import { API } from "../../api/types";
import useKeypress from "../../hooks/useKeypress";

interface ISitePickerProps {
  sites: API.ISite[];
  inputValue: string;
  activeSiteId?: number;
  isLoading: boolean;
  disableGrouping?: boolean;

  onAddSite?: () => void;
  onPickSite: (site: API.ISite) => void;
  onChangeInputValue: (newValue: string) => void;
  onFocus: () => void;
  onBlur: () => void;
}

const useStyles = makeStyles((theme) => ({
  popover: {},
  paddedContent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  favicon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  addSiteButton: {
    marginTop: theme.spacing(1),
  },
  popper: {
    zIndex: theme.zIndex.modal + 1,
    width: 200,
  },
  menuItemSelected: {
    "text-decoration": "none",
    "background-color": "rgba(0, 0, 0, 0.04)",
  },
  inputAdornment: {
    pointerEvents: "none",
  },
  textField: {
    // width: "100%",
  },
}));

const Section: FC<{
  disableGrouping: boolean;
  sites: API.ISite[];
  label: string;
  activeSite: API.ISite;
  onPickSite: (site: API.ISite) => void;
}> = (props) => {
  const classes = useStyles();

  return (
    <>
      {!props.disableGrouping && (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.paddedContent}
        >
          {props.label}
        </Typography>
      )}

      {props.sites.map((site) => {
        return (
          <MenuItem
            key={site.id}
            onClick={() => props.onPickSite(site)}
            className={clsx({
              [classes.menuItemSelected]: site.id === props.activeSite?.id,
            })}
          >
            <img
              alt={`${site.domain} favicon`}
              className={classes.favicon}
              src={`https://www.google.com/s2/favicons?domain=${site.domain}`}
            />
            {toUnicode(site.domain)}
          </MenuItem>
        );
      })}
    </>
  );
};

const getSiteIndex = (sites: API.ISite[], id: number) =>
  sites.findIndex((site) => site.id === id);

const search = (searchString: string) => (site: API.ISite) =>
  toUnicode(site.domain).includes(searchString);
const isVerified = (site: API.ISite) => site.is_verified;
const isNotVerified = (site: API.ISite) => !site.is_verified;

const SitePicker: FC<ISitePickerProps> = (props) => {
  const classes = useStyles();
  const textFieldRef = useRef(null);
  const [popperIsVisible, setPopperIsVisible] = useState(false);
  const [activeSiteIndex, setActiveSiteIndex] = useState(
    getSiteIndex(props.sites, props.activeSiteId || -1) || 0
  );

  const sites = props.sites
    .filter(search(props.inputValue))
    .sort((a, b) => (a.is_verified ? -1 : 1));

  const activeSite: API.ISite | undefined = sites[activeSiteIndex];

  const verifiedSites = sites.filter(isVerified);
  const unVerifiedSites = sites.filter(isNotVerified);

  useKeypress({
    ArrowUp: () => setActiveSiteIndex(Math.max(0, activeSiteIndex - 1)),
    ArrowDown: () =>
      setActiveSiteIndex(Math.min(activeSiteIndex + 1, sites.length)),
  });

  const onFocusTextField = () => {
    props.onFocus();
    setActiveSiteIndex(0);
    setPopperIsVisible(true);
  };

  const onAddSite = () => {
    setPopperIsVisible(false);
    props.onAddSite!();
  };

  const onSubmit: FormEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    (textFieldRef as any).current.querySelector("input").blur();
    if (!activeSite) return onAddSite();
    onPickSite(activeSite);
  };

  const onPickSite = (site: API.ISite) => {
    setPopperIsVisible(false);
    props.onPickSite(site);
  };

  const onClickOutsidePopper = () => {
    props.onBlur();
    if (popperIsVisible) setPopperIsVisible(false);
  };

  const onChangeInputValue = (newValue: string) => {
    if (activeSiteIndex) setActiveSiteIndex(0);
    props.onChangeInputValue(newValue);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextField
          className={classes.textField}
          disabled={props.isLoading}
          onChange={(e) => onChangeInputValue(e.target.value)}
          value={props.isLoading ? "Loading..." : toUnicode(props.inputValue)}
          innerRef={textFieldRef}
          onFocus={onFocusTextField}
          label="Site"
          placeholder="Search site"
          InputProps={{
            endAdornment: (
              <InputAdornment
                classes={{ root: classes.inputAdornment }}
                position="end"
              >
                <KeyboardArrowDown />
              </InputAdornment>
            ),
            autoComplete: "off",
          }}
          size="medium"
          variant="outlined"
        />
      </form>

      <Popper
        className={classes.popper}
        anchorEl={textFieldRef?.current}
        open={popperIsVisible}
      >
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={onClickOutsidePopper}
        >
          <Paper>
            {Boolean(verifiedSites.length) && (
              <Section
                disableGrouping={Boolean(props.disableGrouping)}
                onPickSite={onPickSite}
                activeSite={activeSite}
                label="Verified"
                sites={verifiedSites.filter((site) => site.is_verified)}
              />
            )}
            {Boolean(unVerifiedSites.length) && (
              <Section
                disableGrouping={Boolean(props.disableGrouping)}
                onPickSite={onPickSite}
                activeSite={activeSite}
                label="Not verified"
                sites={unVerifiedSites.filter((site) => !site.is_verified)}
              />
            )}
            {Boolean(props.onAddSite) && (
              <MenuItem
                onClick={onAddSite}
                className={clsx(classes.addSiteButton, {
                  [classes.menuItemSelected]: !activeSite,
                })}
              >
                <Add
                  color="disabled"
                  fontSize="small"
                  className={classes.icon}
                />
                Add site
              </MenuItem>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default SitePicker;
