// Utility components for snackbar transitions

import React from "react";
import { Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

export const TransitionUp = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};
export const TransitionDown = (props: TransitionProps) => {
  return <Slide {...props} direction="down" />;
};
export const TransitionLeft = (props: TransitionProps) => {
  return <Slide {...props} direction="left" />;
};
export const TransitionRight = (props: TransitionProps) => {
  return <Slide {...props} direction="right" />;
};
