import { createTheme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

// This can be configured to override styling of Material UI.
const theme = createTheme({
  palette: {
    primary: { main: "#5E35B1" },
    common: {
      white: "#fff",
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: { backgroundColor: "#3B3D5E" },
    },
    MuiLink: {
      root: {
        color: blue["900"],
      },
    },
  },
});

export default theme;
