import React, { FC, useContext } from "react";
import { linkFunnelContext } from "../utils/contexts";
import LoadingCard from "../../../components/LoadingCard/LoadingCard";
import HorizontalTable from "../../../components/HorizontalTable/HorizontalTable";
import { checkIsValidURL } from "../../../utils/checkIsValidURL";

const ContactForm: FC = () => {
  const { contactsStore, linkStore } = useContext(linkFunnelContext)!;
  if (contactsStore.isLoading) return <LoadingCard />;

  const contact = linkStore.data.contact!;

  return (
    <HorizontalTable
      rows={[
        {
          label: "Name",
          value: contact.name,
        },
        {
          label: "Email",
          value: contact.email,
          renderValue: (value) => {
            if (checkIsValidURL(value)) {
              return (
                <a href={value} target="_blank" rel="noreferrer">
                  {value}
                </a>
              );
            } else {
              return <a href={"mailto:" + value}>{value}</a>;
            }
          },
        },
        {
          label: "Skype",
          value: contact.skype,
        },
        {
          label: "Phone",
          value: contact.phone,
        },
        {
          label: "Notes",
          value: contact.notes,
        },
      ]}
    />
    // <div>
    //   <
    //   <PTextField formik={formik} name="name" label="Name" />
    //   <PTextField formik={formik} name="email" label="Email" />
    //   <PTextField formik={formik} name="phone" label="Phone Number" />
    //   <PTextField formik={formik} name="skype" label="Skype" />
    //   <PTextField formik={formik} name="notes" label="Notes" />
    // </div>
  );
};

export default ContactForm;
