import React, { FC } from "react";
import {
  Button,
  Chip,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";

interface IKeywordsProps {
  keywords: string[];
  onAddKeyword: (keyword: string) => void;
  onRemoveKeyword: (keyword: string) => void;
}

const useStyles = makeStyles((theme) => ({
  addKeywordButton: {
    height: 48,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  addKeywordContainer: {
    display: "flex",
  },
  addSiteField: {
    maxWidth: 600,
    flex: 1,
  },
  keywordsContainer: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

const Keywords: FC<IKeywordsProps> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: { keyword: "" },
    validate: async (values) => {
      // TODO: Add validation
      return {};
    },
    onSubmit: async (values, { resetForm, setErrors }) => {
      props.onAddKeyword(values.keyword);
      resetForm();
    },
  });

  return (
    <>
      <form
        className={classes.addKeywordContainer}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          placeholder="Keyword"
          className={classes.addSiteField}
          size="small"
          variant="filled"
          label="Keyword"
          onChange={formik.handleChange}
          name="keyword"
          value={formik.values["keyword"]}
        />
        <Button
          className={classes.addKeywordButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          Add
        </Button>
      </form>
      <div className={classes.keywordsContainer}>
        {props.keywords.length ? (
          props.keywords.map((keyword) => {
            return (
              <Chip
                key={keyword}
                className={classes.chip}
                label={keyword}
                onDelete={() => props.onRemoveKeyword(keyword)}
              />
            );
          })
        ) : (
          <Typography variant="body2" color="textSecondary">
            There are no keywords.
          </Typography>
        )}
      </div>
    </>
  );
};

export default Keywords;
