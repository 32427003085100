import React, { FC, useContext } from "react";
import { useFormik } from "formik";
import { Typography, Button, Box, Grid } from "@material-ui/core";
import PTextField from "../../../components/Form/PTextField";
import PDate from "../../../components/Form/PDate";
import PSwitch from "../../../components/Form/PSwitch";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { linkFunnelContext } from "../utils/contexts";
import PSelect from "../../../components/Form/PSelect";

const dateFormat = "Y-MM-dd";

const YourCommitmentForm: FC = () => {
  const { linkStore, setLink } = useContext(linkFunnelContext)!;

  const formik = useFormik({
    initialValues: {
      buy_price: linkStore.data.buy_price || 0,
      buy_currency: linkStore.data.buy_currency || "",
      date_bought: linkStore.data.date_bought || null,
      date_expires: linkStore.data.date_expires || null,
      exchange_from_url: linkStore.data.exchange_from_url || "",
      exchange_to_url: linkStore.data.exchange_to_url || "",
      exchange_anchor: linkStore.data.exchange_anchor || "",
      exchange_is_nofollow: linkStore.data.exchange_is_nofollow || 0,
      exchange_is_image_link: linkStore.data.exchange_is_image_link || 0,
    },
    onSubmit: (values) => {
      setLink(values);
    },
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h6">Purchase</Typography>
            <PTextField formik={formik} label="Buy Price" name="buy_price" />
            <Box mb={1} />
            <PSelect
              formik={formik}
              label="Currency"
              name="buy_currency"
              options={[
                { label: "SEK", value: "SEK" },
                { label: "USD", value: "USD" },
                { label: "EUR", value: "EUR" },
              ]}
            />
            <PDate
              dateFormat={dateFormat}
              formik={formik}
              label="Date Bought"
              name="date_bought"
            />
            <PDate
              dateFormat={dateFormat}
              formik={formik}
              label="Date Expires"
              name="date_expires"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Exchange</Typography>
            <PTextField
              formik={formik}
              label="URL From"
              name="exchange_from_url"
            />
            <PTextField formik={formik} label="URL To" name="exchange_to_url" />
            <PTextField
              formik={formik}
              label="Anchor Text"
              name="exchange_anchor"
            />
            <Box mt={1} />
            <PSwitch
              formik={formik}
              label="No Follow"
              name="exchange_is_nofollow"
            />
            <div />
            <PSwitch
              formik={formik}
              label="Image Link"
              name="exchange_is_image_link"
            />
          </Grid>
        </Grid>
        <div style={{ display: "flex" }}>
          <Button
            type="submit"
            style={{ marginLeft: "auto" }}
            variant="outlined"
            color="primary"
          >
            Save
          </Button>
        </div>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default YourCommitmentForm;
