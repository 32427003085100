import React, { FC } from "react";
import {
  Typography,
  TextField,
  makeStyles,
  createStyles,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import clsx from "clsx";
import { toUnicode } from "punycode";

interface ISEOPreviewProps {
  title: string;
  url: string;
  description: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
    },
    textField: {
      maxWidth: 600,
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginBottom: theme.spacing(),
    },
    text: {
      maxWidth: 580,
      fontFamily: "arial,sans-serif",
    },
    title: {
      color: "rgb(26, 13, 171)",
      fontSize: 20,
      cursor: "pointer",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    description: {
      color: "rgb(77, 81, 86)",
      fontSize: 14,
    },
    //url: {},
    path: {
      display: "inline-block",
      color: "rgb(95, 99, 104)",
      fontSize: 14,
    },
    domain: {
      display: "inline-block",
      color: "rgb(32, 33, 36)",
      marginRight: 4,
      fontSize: 14,
    },
  })
);

const truncate = (text: string) => {
  const limit = 156;
  if (text.length <= limit) return text;
  return text.substr(0, text.lastIndexOf(" ", limit)) + "...";
};

const getHost = (url: string) => new URL(url).host;

const getArrowSeparatedPath = (url: string) => {
  return new URL(url).pathname.split("/").map(decodeURIComponent).join(" › ");
};

const SEOPreview: FC<ISEOPreviewProps> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      title: props.title,
      description: props.description,
    },
    onSubmit: () => {},
  });

  const domain = toUnicode(getHost(props.url));
  const path = getArrowSeparatedPath(props.url);

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
          variant="filled"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          placeholder="Title"
          className={classes.textField}
          size="small"
          label="Title"
          helperText={`Characters: ${formik.values.title.length}`}
        />
        <TextField
          variant="filled"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          placeholder="Description"
          className={classes.textField}
          size="small"
          label="Description"
          multiline
          helperText={`Characters: ${formik.values.description?.length || 0}`}
        />
        <Divider className={classes.divider} />
        <Typography className={classes.text}>
          <Typography className={classes.domain}>{domain}</Typography>
          <Typography className={classes.path}>{path}</Typography>
        </Typography>
        <Typography
          onClick={() => window.open(props.url, "_blank")}
          className={clsx(classes.title, classes.text)}
        >
          {formik.values.title}
        </Typography>
        <Typography className={clsx(classes.description, classes.text)}>
          {truncate(formik.values.description || "")}
        </Typography>
      </form>
    </>
  );
};

export default SEOPreview;
