// useUserSettings is similar to /src/hooks/useFetch,
// altough it's specifically made for fetching the user
// and setting user settings. The main difference
// is the set method and having optimistic response
// support.

import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { getUser, saveUserSettings } from "../api";
import { API } from "../api/types";

interface IUseUserSettingsConfig {
  ignore?: boolean;
}

export interface IUserUserSettingsReturn {
  isLoading: boolean;
  error: null | Error;
  refetch: () => Promise<AxiosResponse<API.IUser> | AxiosError>;
  set: (
    settings: any,
    // Optimstic response will set the state before the request has been
    // resolved to make the user experience smoother. In case of an error,
    // the state will be set back. This is inspired by:
    // https://www.apollographql.com/docs/react/performance/optimistic-ui/
    options?: { optimisticResponse?: boolean }
  ) => Promise<AxiosResponse<API.IUser> | AxiosError>;
  data: null | API.IUser;
}

const useUserSettings = (
  config: IUseUserSettingsConfig,
  deps?: any[]
): IUserUserSettingsReturn => {
  const [state, setState] = useState<{
    data: any;
    isLoading: boolean;
    error: null | Error;
  }>({
    data: null,
    isLoading: !config.ignore,
    error: null,
  });

  const fetch = async () => {
    setState({
      ...state,
      isLoading: true,
      error: null,
    });

    const [err, res] = await getUser();

    if (err) {
      setState({
        ...state,
        isLoading: false,
        error: err,
      });

      return err;
    }

    setState({
      ...state,
      isLoading: false,
      data: res!.data,
      error: null,
    });

    return res!;
  };

  const set = async (
    settings: any,
    options?: { optimisticResponse?: boolean }
  ) => {
    let previousData = null;

    if (options?.optimisticResponse) {
      previousData = state.data;
      setState({
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          profile: {
            ...(state.data.profile || {}),
            ...settings,
          },
        },
      });
    } else setState({ ...state, isLoading: true, error: null });

    const [err, res] = await saveUserSettings({
      ...(state?.data?.profile || {}),
      ...settings,
    });
    if (err) {
      setState({ ...state, isLoading: false, data: previousData, error: null });
      return res!;
    }
    setState({ ...state, isLoading: false, data: res!.data, error: null });
    return res!;
  };

  // eslint-disable-next-line
  useEffect(
    config.ignore
      ? () => {}
      : () => {
          fetch();
        },
    deps || []
  );

  return {
    isLoading: state.isLoading,
    data: state.data,
    error: state.error,
    refetch: fetch,
    set,
  };
};

export default useUserSettings;
