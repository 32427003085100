import React, { FC } from "react";
import { API } from "../../../../api/types";
import {
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { green, red, grey, yellow } from "@material-ui/core/colors";
import clsx from "clsx";
import { Star } from "@material-ui/icons";

interface IQueryProps {
  query: API.ISearchConsoleEntries;
  weekResult: any;
  onClickStar: () => void;
  isStarred: boolean;
  openKeywordAnalyzeDialog: (
    content: API.ISearchConsoleKeywordAnalyzeData
  ) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    queryContainer: {
      display: "flex",
    },
    notAvailableText: {
      color: grey["500"],
    },
    rankingContainer: {
      display: "flex",
      overflow: "auto",
    },
    contentBox: {
      flex: 1,
      minWidth: 65,
    },
    weekData: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    datum: {
      width: "100%",
      textAlign: "center",
    },
    datumInc: {
      color: green["700"],
    },
    datumDec: {
      color: red["700"],
    },
    datumSearchResult: {
      backgroundColor: yellow["100"],
    },
    bgEven: {
      backgroundColor: "#f2f2f2",
    },
    refreshButton: {
      fontSize: 18,
      marginLeft: 4,
      color: "rgba(0, 0, 0, 0.4)",
    },
    refreshButtonOwner: {
      color: "rgba(0,0,0,.7)",
      cursor: "pointer",
      "&:hover": {
        color: "rgba(0,0,0,1)",
      },
    },
    starButton: {
      marginLeft: theme.spacing(0.5),
      color: grey["400"],
    },
    starButtonStarred: {
      color: theme.palette.primary.main,
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    week: {
      cursor: "pointer",
      textDecoration: "underline",
    },
  })
);

//const getMaximum = (
//queryData: API.ISearchConsoleEntries["data"],
//attribute: { label: string; value: string; color: string }
//) => {
//return Math.max(
//...queryData
//.filter((data) => !data.no_result)
//.map((data) => (data as any)[attribute.value])
//);
//};

//const getGraphData = (
//queryData: API.ISearchConsoleEntries["data"],
//attribute: { label: string; value: string; color: string },
//map: (value: number) => number,
//invertData?: boolean
//) => {
//const maximum = getMaximum(queryData, attribute) || 1;

//return queryData
//.slice()
//.reverse()
//.map((data) => {
//const y = map(
//invertData
//? 1 - ((data as any)[attribute.value] || 0) / maximum
//: ((data as any)[attribute.value] || 0) / maximum
//);
//return {
//x: String(data.year_week),
//y,
//noResult: data.no_result,
//attribute,
//maximum,
//actualValue: ((data as any)[attribute.value] || 0) / maximum,
//};
//});
//};

//const getAmountOfAxises = (amountOfDatasets: number) => {
//if (amountOfDatasets === 1) return 1;
//if (amountOfDatasets === 2) return 2;

//return 0;
//};

//const getTickFormat = (
//attribute: { label: string; value: string; color: string },
//maximum: number,
//value: number
//) => {
//const isInversed = attribute.value === "position";
//if (isInversed) return Math.round((1 - value) * maximum * 10) / 10;
//return Math.round(value * maximum * 10) / 10;
//};

const Query: FC<IQueryProps> = (props) => {
  const classes = useStyles();

  //const weekToNoResultMap = props.query.data.reduce((acc, val) => {
  //if (val.no_result)
  //return {
  //...acc,
  //[String(val.year_week)]: true,
  //};
  //return acc;
  //}, {});

  //const noResultWeeks = props.query.data.reduce((acc, val) => {
  //if (val.no_result) {
  //if (!acc) return String(val.year_week);
  //return `${acc}, ${String(val.year_week)}`;
  //}

  //return acc;
  //}, "");

  //const amountOfDatasets = Object.values(props.activeAttributes).filter(Boolean)
  //.length;
  //const amountOfAxises = getAmountOfAxises(amountOfDatasets);
  //const activeAttributesArray = props.attributes.filter((attribute) =>
  //Boolean(props.activeAttributes[attribute.value])
  //);

  //const graph = (
  //<>
  //{Boolean(noResultWeeks) && (
  //<Typography variant="body2" color="textSecondary">
  //Notice: No results were found for: {noResultWeeks}
  //</Typography>
  //)}
  //<FormControl component="fieldset">
  //<FormGroup row onChange={props.onChangeAttribute}>
  //{props.attributes.map((attribute) => {
  //return (
  //<FormControlLabel
  //value={attribute.value}
  //control={
  //<Checkbox
  //style={{ color: attribute.color }}
  //checked={props.activeAttributes[attribute.value]}
  ///>
  //}
  //label={attribute.label}
  ///>
  //);
  //})}
  //</FormGroup>
  //</FormControl>
  //<V.VictoryChart
  //containerComponent={
  //<V.VictoryVoronoiContainer
  //voronoiDimension="x"
  //labels={({ datum }) => {
  //return `${datum.attribute.label}: ${
  //Math.round(
  //(typeof datum.actualValue === "number"
  //? datum.actualValue
  //: datum.y) *
  //datum.maximum *
  //10
  //) / 10
  //}`;
  //}}
  //labelComponent={
  //<V.VictoryTooltip
  //centerOffset={{ y: 25 }}
  //style={{ fontSize: 7 }}
  //cornerRadius={1}
  ///>
  //}
  ///>
  //}
  //padding={{
  //top: 12,
  //bottom: 20,
  //left: amountOfDatasets === 0 || amountOfDatasets > 2 ? 12 : 32,
  //right: amountOfDatasets < 2 || amountOfDatasets > 2 ? 12 : 32,
  //}}
  //theme={V.VictoryTheme.material}
  //height={100}
  //>
  //{amountOfAxises > 0 && (
  //<V.VictoryAxis
  //tickFormat={(value) => {
  //const maximum = getMaximum(
  //props.query.data,
  //activeAttributesArray[0]
  //);
  //return getTickFormat(activeAttributesArray[0], maximum, value);
  //}}
  //dependentAxis
  //style={{
  //axis: { stroke: activeAttributesArray?.[0].color },
  //tickLabels: {
  //fontSize: 6,
  //fill: activeAttributesArray?.[0].color,
  //},
  //}}
  ///>
  //)}
  //{amountOfAxises === 2 && (
  //<V.VictoryAxis
  //tickFormat={(value) => {
  //const maximum = getMaximum(
  //props.query.data,
  //activeAttributesArray[1]
  //);
  //return getTickFormat(activeAttributesArray[1], maximum, value);
  //}}
  //orientation="right"
  //dependentAxis
  //style={{
  //axis: { stroke: activeAttributesArray?.[1].color },
  //tickLabels: {
  //fontSize: 6,
  //fill: activeAttributesArray?.[1].color,
  //},
  //}}
  ///>
  //)}
  //<V.VictoryAxis
  //style={{
  //tickLabels: {
  //fontSize: 6,
  //display: (context) => {
  //if ((weekToNoResultMap as any)[context.text]) return "none";
  //return "block;";
  //},
  //},
  //}}
  ///>

  //{activeAttributesArray.map((attribute) => {
  //const dataIsInverted = attribute.value === "position";

  //const map =
  //attribute.value === "position"
  //? (value: number) => {
  //if (value === 1) return 0;
  //return value;
  //}
  //: (value: number) => value;

  //return (
  //<V.VictoryGroup color={attribute.color}>
  //<V.VictoryLine
  //style={{ data: { strokeWidth: 1 } }}
  //data={getGraphData(
  //props.query.data,
  //attribute,
  //map,
  //dataIsInverted
  //)}
  ///>
  //</V.VictoryGroup>
  //);
  //})}
  //</V.VictoryChart>
  //</>
  //);

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography variant="h5" color="textSecondary">
          {props.query.query}
        </Typography>
        <IconButton
          className={clsx(classes.starButton, {
            [classes.starButtonStarred]: props.isStarred,
          })}
          size="small"
          onClick={props.onClickStar}
        >
          <Star />
        </IconButton>
      </div>

      <div className={classes.rankingContainer}>
        <div className={`${classes.contentBox}`}>
          <div>
            <Typography variant="body2">Week</Typography>
          </div>
          <div>
            <Typography variant="body2">Pos</Typography>
          </div>
          <div>
            <Typography variant="body2">Clicks</Typography>
          </div>
          <div>
            <Typography variant="body2">Imps</Typography>
          </div>
          <div>
            <Typography variant="body2">CTR</Typography>
          </div>
        </div>
        {props.query.data.map((week, index) => {
          const attributes: string[] =
            props.weekResult[`${props.query.query}-${week.year_week}`] || [];
          return (
            <div
              className={clsx(classes.weekData, classes.contentBox, {
                [classes.bgEven]: Boolean(index % 2 === 0),
              })}
              key={String(week.year_week)}
            >
              <div>
                {week.no_result ? (
                  <Typography variant="body2">
                    {String(week.year_week)}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    className={classes.week}
                    onClick={() =>
                      props.openKeywordAnalyzeDialog({
                        geo: week.geo,
                        query: props.query.query,
                      })
                    }
                  >
                    {String(week.year_week)}
                    {week.geo && week.geo!.length ? "*" : ""}
                  </Typography>
                )}
              </div>
              {week.no_result ? (
                <React.Fragment>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {week.previous && week.previous.position !== week.position ? (
                    week.previous.position < week.position! ? (
                      <div
                        className={clsx(classes.datumDec, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "position"
                          ),
                        })}
                      >
                        <Typography variant="body2">{week.position}</Typography>
                      </div>
                    ) : (
                      <div
                        className={clsx(classes.datumInc, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "position"
                          ),
                        })}
                      >
                        <Typography variant="body2">{week.position}</Typography>
                      </div>
                    )
                  ) : (
                    <div
                      className={clsx(classes.datum, {
                        [classes.datumSearchResult]: attributes.includes(
                          "position"
                        ),
                      })}
                    >
                      <Typography variant="body2">{week.position}</Typography>
                    </div>
                  )}
                  {week.previous && week.previous.clicks !== week.clicks ? (
                    week.previous.clicks > week.clicks! ? (
                      <div
                        className={clsx(classes.datumDec, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "clicks"
                          ),
                        })}
                      >
                        <Typography variant="body2">{week.clicks}</Typography>
                      </div>
                    ) : (
                      <div
                        className={clsx(classes.datumInc, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "clicks"
                          ),
                        })}
                      >
                        <Typography variant="body2">{week.clicks}</Typography>
                      </div>
                    )
                  ) : (
                    <div
                      className={clsx(classes.datum, {
                        [classes.datumSearchResult]: attributes.includes(
                          "clicks"
                        ),
                      })}
                    >
                      <Typography variant="body2">{week.clicks}</Typography>
                    </div>
                  )}
                  {week.previous &&
                  week.previous.impressions !== week.impressions ? (
                    week.previous.impressions > week.impressions! ? (
                      <div
                        className={clsx(classes.datumDec, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "impressions"
                          ),
                        })}
                      >
                        <Typography variant="body2">
                          {week.impressions}
                        </Typography>
                      </div>
                    ) : (
                      <div
                        className={clsx(classes.datumInc, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "impressions"
                          ),
                        })}
                      >
                        <Typography variant="body2">
                          {week.impressions}
                        </Typography>
                      </div>
                    )
                  ) : (
                    <div
                      className={clsx(
                        {
                          [classes.datumSearchResult]: attributes.includes(
                            "impressions"
                          ),
                        },
                        classes.datum
                      )}
                    >
                      <Typography variant="body2">
                        {week.impressions}
                      </Typography>
                    </div>
                  )}
                  {week.previous && week.previous.ctr !== week.ctr ? (
                    week.previous.ctr > week.ctr! ? (
                      <div
                        className={clsx(classes.datumDec, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "ctr"
                          ),
                        })}
                      >
                        <Typography variant="body2">{week.ctr}</Typography>
                      </div>
                    ) : (
                      <div
                        className={clsx(classes.datumInc, classes.datum, {
                          [classes.datumSearchResult]: attributes.includes(
                            "ctr"
                          ),
                        })}
                      >
                        <Typography variant="body2">{week.ctr}</Typography>
                      </div>
                    )
                  ) : (
                    <div
                      className={clsx(classes.datum, {
                        [classes.datumSearchResult]: attributes.includes("ctr"),
                      })}
                    >
                      <Typography variant="body2">{week.ctr}</Typography>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Query;
