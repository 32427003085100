import {
  createStyles,
  makeStyles,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";
import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addOutreachContact,
  addOutreachLink,
  addOutreachLinkComment,
  getAuthHeaders,
  removeOutreachLinkComment,
} from "../../api";
import { API } from "../../api/types";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import Layout from "../../components/Layout";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import config from "../../config";
import { useFetch } from "../../hooks/useFetch";
import useInterval from "@use-it/interval";
import { logout } from "../../utils/auth";
import { TransitionUp } from "../../utils/snackbar";
import { getNextStatus, getPreviousStatus } from "../Links/utils";
import StatusAccepted from "./components/StatusAccepted";
import StatusAnalyzePending from "./components/StatusAnalyzePending";
import StatusContact from "./components/StatusContact";
import StatusNegotiating from "./components/StatusNegotiating";
import StatusNew from "./components/StatusNew";
import { IFormData, linkFunnelContext } from "./utils/contexts";
import StatusAwaitingResponse from "./components/StatusAwaitingResponse";
import StatusOnHold from "./components/StatusOnHold";

interface ILinkScreenQueryParams {
  linkId: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: "relative",
      height: "calc(100vh - 64px)",
      overflow: "auto",
    },
    stepButtonCompleted: {
      color: `${green["700"]}!important`,
    },
    stepper: {
      zIndex: theme.zIndex.appBar,
      flex: 1,
      position: "sticky",
      top: 0,
      boxShadow: theme.shadows[1],
    },
  })
);
const getActiveStep = (
  link: API.Outreach.Link,
  previewStepIndex?: number | null
) => {
  const { status } = link;
  if (typeof previewStepIndex === "number") return previewStepIndex;
  if (["new", "analyze_pending", "analyze_no_go"].includes(status)) return 0;
  if (status === "contact") return 1;
  if (status === "awaiting_response") return 2;
  if (status === "negotiating") return 3;
  if (status === "on_hold") return 4;
  return 5;
};

const getLastStepName = (link: API.Outreach.Link) => {
  if (link.status === "declined") return "Declined";
  return "Accepted";
};

const getFirstStepName = (link: API.Outreach.Link) => {
  if (link.status === "analyze_pending") return "Analyzing";
  if (link.status === "analyze_no_go") return "Declined";

  return "Analyzing";
};

const getError = (stepIndex: number, status: API.Outreach.Link["status"]) => {
  if (stepIndex === 0 && status === "analyze_no_go") return true;
  if (stepIndex === 5 && status === "declined") return true;
  return false;
};

const getCompleted = (
  stepIndex: number,
  currentStepIndex: number,
  status: API.Outreach.Link["status"]
) => {
  if (stepIndex === currentStepIndex && status === "accepted") return true;
  if (currentStepIndex > stepIndex) return true;

  return false;
};

const getDisabled = (
  stepIndex: number,
  currentStepIndex: number,
  activeStep: number
) => {
  if (stepIndex === activeStep) return true;
  return stepIndex > currentStepIndex;
};

const getSpecificRequestBody = (
  status: API.Outreach.Link["status"],
  formData: IFormData
) => {
  if (status === "contact") {
    return {
      contact_id: formData.analyze_pending.contact_id,
    };
  }

  return {};
};

const LinkScreen: FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { linkId } = useParams<ILinkScreenQueryParams>();

  const [previewStepIndex, setPreviewStepIndex] = useState<number | null>(null);
  const isPreview = Boolean(previewStepIndex);
  const onClickStepButton = (index: number, actualStep: number) => () => {
    if (index === actualStep) return setPreviewStepIndex(null);
    setPreviewStepIndex(index);
  };
  const linkStore = useFetch<API.Outreach.Link>({
    axiosConfig: {
      url: `${config.api.url}/outreach/link/${linkId}`,
      headers: getAuthHeaders(),
    },
  });

  const contactsStore = useFetch<API.Outreach.IContact[]>({
    axiosConfig: {
      url: `${config.api.url}/outreach/contact`,
      headers: getAuthHeaders(),
    },
  });

  useInterval(() => {
    if (linkStore.data.status !== "new") return;
    linkStore.refetch();
  }, 5000);

  const isDeclined = ["declined", "analyze_no_go"].includes(
    linkStore?.data?.status
  );

  const [formData, setFormData] = useState<IFormData>({
    analyze_pending: { contact_id: linkStore?.data?.contact_id || null },
  });

  if (linkStore.isLoading && !linkStore.data) return <LoadingOverlay />;

  const actualStep = getActiveStep(linkStore?.data);
  const activeStep = getActiveStep(linkStore?.data, previewStepIndex);

  const onClickProceed = (link: API.Outreach.Link) => async () => {
    const [err] = await addOutreachLink({
      ...linkStore.data,
      ...getSpecificRequestBody(link.status, formData),
      status: getNextStatus(link, true),
    });

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      return enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    linkStore.refetch();
  };

  const onClickPrevious = (link: API.Outreach.Link) => async () => {
    const [err] = await addOutreachLink({
      ...linkStore.data,
      ...getSpecificRequestBody(link.status, formData),
      status: getPreviousStatus(link),
    });

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      return enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    linkStore.refetch();
  };

  const onClickCancel = (link: API.Outreach.Link) => async () => {
    const [err] = await addOutreachLink({
      ...linkStore.data,
      status: getNextStatus(link, false),
    });
    if (err) {
      const message = err?.response?.data?.error || err?.message;

      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
      return;
    }
    linkStore.refetch();
  };

  const onClickHold = (link: API.Outreach.Link) => async () => {
    const [err] = await addOutreachLink({
      ...linkStore.data,
      status: "on_hold",
    });
    if (err) {
      const message = err?.response?.data?.error || err?.message;

      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
      return;
    }
    linkStore.refetch();
  };

  const addContact = async (contact: API.Outreach.ICreateContact) => {
    const [err, res] = await addOutreachContact(contact);
    if (err) {
      const message = err?.response?.data?.error || err?.message;

      return enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }
    await setContactId(res!.data.id);
    await contactsStore.refetch();
    linkStore.refetch();
  };

  const addNote = async (note: API.Outreach.ICreateLinkComment) => {
    const [err] = await addOutreachLinkComment(note);
    if (err) {
      const message = err?.response?.data?.error || err?.message;

      return enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }
    linkStore.refetch();
  };

  const deleteNote = async (noteId: number) => {
    const [err] = await removeOutreachLinkComment(noteId);
    if (err) {
      const message = err?.response?.data?.error || err?.message;

      return enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }
    linkStore.refetch();
  };

  const setLink = async (data: any) => {
    const [err] = await addOutreachLink({
      ...linkStore.data,
      ...data,
    });

    const message =
      err?.response?.data?.error || err?.message || "Successfully saved data.";

    enqueueSnackbar(message, {
      variant: err ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });

    linkStore.refetch();
  };

  const setContactId = async (contactId: number) => {
    const [err] = await addOutreachLink({
      ...linkStore.data,
      contact_id: contactId,
    });

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      return enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    linkStore.refetch();
  };

  const renderStatusComponent = (link: API.Outreach.Link, index: number) => {
    if (index === 0 && link.status === "new") return <StatusNew />;
    if (index === 0) return <StatusAnalyzePending />;
    if (index === 1) return <StatusContact />;
    if (index === 2) return <StatusAwaitingResponse />;
    if (index === 3) return <StatusNegotiating />;
    if (index === 4) return <StatusOnHold />;
    if (index === 5 && link.status === "accepted") return <StatusAccepted />;
    if (index === 5 && link.status === "declined") return <StatusAccepted />;
    return <Typography>Error: Status was not found.</Typography>;
  };

  if (linkStore.error || contactsStore.error)
    return (
      <Layout
        backgroundColor={grey["200"]}
        renderAppToolbar={({ toggle, isOpen }) => (
          <AppBarToolbar
            drawerIsOpen={isOpen}
            toggleDrawer={toggle}
            onClickLogout={logout}
            goBackUrl={`/outreach/links`}
          />
        )}
      >
        <Typography>
          {(linkStore.error || contactsStore.error).message}
        </Typography>
      </Layout>
    );

  return (
    <linkFunnelContext.Provider
      value={{
        isDeclined,
        isPreview,
        contactsStore,
        linkStore,
        addContact,
        addNote,
        deleteNote,
        setLink,
        setFormData,
        setContactId,
        formData,
        onClickCancel,
        onClickProceed,
        onClickPrevious,
        onClickHold,
      }}
    >
      <Layout
        backgroundColor={grey["200"]}
        renderAppToolbar={({ toggle, isOpen }) => (
          <AppBarToolbar
            drawerIsOpen={isOpen}
            toggleDrawer={toggle}
            onClickLogout={logout}
            goBackUrl={`/outreach/links`}
          />
        )}
      >
        <div className={classes.container}>
          <Stepper activeStep={actualStep} className={classes.stepper}>
            {[
              getFirstStepName(linkStore.data),
              "Contact",
              "Awaiting Response",
              "Negotiating",
              "On Hold",
              getLastStepName(linkStore.data),
            ].map((label, index) => (
              <Step
                key={label}
                completed={getCompleted(
                  index,
                  actualStep,
                  linkStore.data.status
                )}
              >
                <StepButton
                  disabled={getDisabled(index, actualStep, activeStep)}
                  onClick={onClickStepButton(index, actualStep)}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        completed: classes.stepButtonCompleted,
                      },
                    }}
                    error={getError(index, linkStore.data.status)}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: activeStep === index ? "bold" : "normal",
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>

          {renderStatusComponent(linkStore.data, activeStep)}
        </div>
      </Layout>
    </linkFunnelContext.Provider>
  );
};

export default LinkScreen;
