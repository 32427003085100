import { Box, Button, createStyles, makeStyles } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { API } from "../../../api/types";
import Container from "../../../components/Container/Container";
import LinkCard from "../../Links/components/LinkCard";
import { linkFunnelContext } from "../utils/contexts";
import LinkComponent from "./LinkComponent";
import Notes from "./Notes";
import ContactDetails from "./ContactDetails";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
  })
);

const StatusAwaitingResponse: FC = () => {
  const classes = useStyles();
  const {
    linkStore,
    addNote,
    deleteNote,
    isPreview,
    onClickProceed,
    onClickCancel,
  } = useContext(linkFunnelContext)!;
  const isSettled = ["accepted", "analyze_no_go", "declined"].includes(
    linkStore.data.status
  );

  const onAddNote = (data: { note: string }) => {
    addNote({ text: data.note, link_id: linkStore.data.id });
  };

  const onDeleteNote = (note: API.Outreach.ILinkComment) => {
    deleteNote(note.id);
  };

  return (
    <Container>
      <LinkCard showData link={linkStore.data} disableActions />
      <Box mb={2} />
      <LinkComponent title="Notes">
        <Notes
          onDeleteNote={onDeleteNote}
          onAddNote={onAddNote}
          notes={linkStore.data.notes}
        />
      </LinkComponent>
      {Boolean(linkStore.data.contact) && (
        <>
          <Box mb={2} />
          <LinkComponent title="Contact">
            <ContactDetails />
          </LinkComponent>
        </>
      )}
      {!isSettled && !isPreview && (
        <>
          <Box mb={2} />
          <div className={classes.buttonContainer}>
            <Button
              variant="text"
              color="primary"
              onClick={onClickCancel(linkStore.data)}
            >
              Decline
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              color="primary"
              onClick={onClickProceed(linkStore.data)}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default StatusAwaitingResponse;
