import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  createStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import { getError, getHelperText } from "../../utils/form";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { API } from "../../api/types";

interface IAddLinkDialogProps {
  onClose: () => void;
  onAdd: (values: API.Outreach.ICreateContact, isEdit: boolean) => void;
  onDelete: () => void;
  initialValues?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
    },
    dialog: {},
    buttons: {
      marginTop: "auto",
    },
  })
);

const PTextField: FC<{
  label: string;
  name: string;
  formik: any;
  multiline?: boolean;
}> = (props) => {
  return (
    <TextField
      multiline={props.multiline}
      margin="dense"
      label={props.label}
      fullWidth
      onChange={(e) => props.formik.setFieldValue(props.name, e.target.value)}
      name={props.name}
      value={props.formik.values[props.name]}
      helperText={getHelperText(props.formik as any, props.name)}
      error={getError(props.formik as any, props.name)}
    />
  );
};

const ContactDialog: FC<IAddLinkDialogProps> = (props) => {
  const isEditing = Boolean(props.initialValues);
  const classes = useStyles();
  const formik = useFormik({
    // TODO: Add validation
    validate: async () => {
      return {};
    },
    onSubmit: (values) => {
      props.onAdd(values as any, Boolean(props.initialValues));
    },
    initialValues: props.initialValues
      ? props.initialValues
      : ({
          name: "",
          email: "",
          phone: "",
          skype: "",
        } as API.Outreach.ICreateContact),
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    formik.submitForm();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={true}
        onClose={props.onClose}
        className={classes.dialog}
      >
        <form onSubmit={onSubmit}>
          <DialogTitle id="form-dialog-title">
            {isEditing ? "Edit Contact" : "Add Contact"}
          </DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <PTextField formik={formik} name="name" label="Name" />
              <PTextField formik={formik} name="email" label="Email" />
              <PTextField formik={formik} name="phone" label="Phone Number" />
              <PTextField formik={formik} name="skype" label="Skype" />
              <PTextField
                formik={formik}
                name="notes"
                label="Notes"
                multiline
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            {isEditing && (
              <Button onClick={props.onDelete} color="primary">
                Delete
              </Button>
            )}
            <Button
              style={{ marginLeft: "auto" }}
              onClick={props.onClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {isEditing ? "Apply" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default ContactDialog;
