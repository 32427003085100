import React, { FC } from "react";
import { API } from "../../../api/types";
import { makeStyles, Typography } from "@material-ui/core";
import KeywordToolImportDataGscRow from "./KeywordToolImportDataGscRow";

const useStyles = makeStyles((theme) => ({
  dataWrapper: {
    display: "flex",
  },
  datum: {
    flex: 1,
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "left",
    position: "relative",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #ccc",
    },
    padding: "0 10px",
  },
  datumHeading: {
    position: "absolute",
    fontSize: "10px",
    textTransform: "uppercase",
    left: "10px",
    top: "5px",
    color: "#aaa",
  },
  gsc: {
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  keyword: {
    fontWeight: "bold",
    fontStyle: "italic",
  },
}));

const KeywordToolImportDataRow: FC<API.KeywordTool.IImportData> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.dataWrapper}>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Keyword</div>
          <Typography className={classes.keyword} variant="body2">
            {props.keyword}
          </Typography>
        </div>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>Volume</div>
          <Typography variant="body2">
            {typeof props.volume != "undefined" && props.volume !== null ? (
              <>{props.volume}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
        <div className={classes.datum}>
          <div className={classes.datumHeading}>CPC</div>
          <Typography variant="body2">
            {typeof props.cpc != "undefined" && props.cpc !== null ? (
              <>{props.cpc}</>
            ) : (
              <>-</>
            )}
          </Typography>
        </div>
      </div>
      {props.gsc && props.gsc.length && (
        <>
          <Typography className={classes.gsc} variant="body2">
            GSC
          </Typography>
          {props.gsc.map((gscData) => {
            return (
              <React.Fragment key={gscData.id}>
                <KeywordToolImportDataGscRow {...gscData} />
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default KeywordToolImportDataRow;
