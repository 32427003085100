import React, { FC, useContext, useState } from "react";
import {
  Card,
  createStyles,
  makeStyles,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import AppBarToolbar from "../../components/AppBarToolbar/AppBarToolbar";
import { logout } from "../../utils/auth";
import LinkCompetitors from "./components/Link/Link";
import { useFetch } from "../../hooks/useFetch";
import config from "../../config";
import {
  addKeyword,
  getAuthHeaders,
  linkPage,
  removeKeyword,
  removeLink,
  setTodoNoteDone,
} from "../../api";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { API } from "../../api/types";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../contexts";
import { toUnicode } from "punycode";
import { TransitionUp } from "../../utils/snackbar";
import { useSnackbar } from "notistack";
import { identity, pipe, sortBy } from "ramda";
import GSC from "./components/GSC/GSC";
import Container from "../../components/Container/Container";
import Keywords from "./components/Keywords/Keywords";
import { grey } from "@material-ui/core/colors";
import { DragIndicator, UnfoldLess, UnfoldMore } from "@material-ui/icons";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import SEOPreview from "./components/SEOPreview/SEOPreview";
import Majestic from "./components/Majestic/Majestic";
import Todos from "./components/Todos/Todos";
import AddTodoDialog from "../../components/AddTodoDialog/AddTodoDialog";
import Trends from "./components/Trends/Trends";

export interface IPageScreenQueryParams {
  siteId: string;
  pageId: string;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    card: { flex: 1, marginLeft: theme.spacing(1) },
    cardContent: { padding: theme.spacing(2), paddingTop: 0 },
    componentContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    cardHeader: {
      display: "flex",
      flexDirection: "row",
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    cardTitle: {
      marginLeft: theme.spacing(1),
    },
    container: {
      height: "calc(100vh - 64px)",
      overflow: "auto",
    },
  })
);

const getDomain = (url: string) => {
  try {
    return pipe(
      (url: string) => new URL(url),
      (url) => url.hostname,
      toUnicode,
      (url) => url.replace(/www\./g, "")
    )(url);
  } catch (error) {
    return "";
  }
};

interface IComponent {
  id: string;
}

interface IComponentSettings {
  isMinimized: boolean;
}

// TODO: Improve typing
const getInitialComponentSettings = (
  components: IComponent[]
): { [key: string]: IComponentSettings } => {
  return components.reduce((acc, value) => {
    return {
      ...acc,
      [value.id]: {
        isMinimized: false,
      },
    };
  }, {});
};

const getSite = (sites: API.ISite[], id: number) => {
  return sites?.find((site) => site.id === id)!;
};

const ComponentContainer: FC<{
  title: string;
  isMinimized: boolean;
  onClickToggle: () => void;
  index: number;
  dragHandleProps: any;
}> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.componentContainer}>
      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          <IconButton size="small" {...props.dragHandleProps}>
            <DragIndicator />
          </IconButton>
          <IconButton size="small" onClick={props.onClickToggle}>
            {props.isMinimized ? <UnfoldMore /> : <UnfoldLess />}
          </IconButton>
          <Typography className={classes.cardTitle} variant="h6">
            {props.title}
          </Typography>
        </div>
        <div className={classes.cardContent}>
          <Box mb={props.isMinimized ? 0 : 2} />
          {!props.isMinimized && props.children}
        </div>
      </Card>
    </div>
  );
};

// TODO: Refactor this
const initialComponentOrder = [
  { id: "competing-pages" },
  { id: "keywords" },
  { id: "gsc" },
  { id: "seo-preview" },
  { id: "majestic" },
  { id: "todos" },
  { id: "trends" },
];

const PageScreen: FC = () => {
  const theme = useTheme();
  const params = useParams<IPageScreenQueryParams>();
  const history = useHistory();
  const store = useContext(StoreContext);
  const site = getSite(store!.data.sites.data, Number(params.siteId));
  /* ToDo notes stuff */
  const [addTodoDialogIsOpen, setAddTodoDialogIsOpen] = useState(false);
  const initialTodoDialogData = { pageId: 0, url: "" };
  const [todoDialogData, setTodoDialogData] = useState(initialTodoDialogData);
  const onCloseAddTodoDialog = () => setAddTodoDialogIsOpen(false);
  const onClickAddTodo = (data: API.ITodoNoteDialogData) => {
    setTodoDialogData(data);
    setAddTodoDialogIsOpen(true);
  };
  /* End ToDo notes stuff */
  const componentOrder =
    store!.data.user.data?.profile?.componentOrder ||
    initialComponentOrder.map((c) => c.id);
  initialComponentOrder.forEach((element) => {
    if (!componentOrder.includes(element.id)) {
      componentOrder.push(element.id);
    }
  });
  const componentSettings =
    store!.data.user.data?.profile?.componentSettings ||
    getInitialComponentSettings(initialComponentOrder);
  initialComponentOrder.forEach((element) => {
    if (!componentSettings.hasOwnProperty(element.id)) {
      componentSettings[element.id] = {
        isMinimized: false,
      };
    }
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const pageStore = useFetch<API.IPage>({
    axiosConfig: {
      headers: getAuthHeaders(),
      url: `${config.api.url}/pages/${params.pageId}/${site.id}`,
    },
  });

  const [activeComponent, setActiveComponent] = useState<{
    element: any;
    component: any;
  }>({ element: null, component: null });

  const getComponentWrapperStyle = (draggableStyles: any) => {
    return {
      ...draggableStyles,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    };
  };

  if (!pageStore.data) return <LoadingOverlay />;

  // const hasKeywords = pageData?.keywords?.keywords?.length;

  const isCompetitor = site.site_id !== pageStore.data.site_id;
  const hasGSCData = Boolean(pageStore.data.search_console_data);
  const hasMajesticData = Boolean(pageStore.data.majestic);
  const hasKeywords = pageStore.data?.keywords?.keywords?.length > 0;

  const onRemoveLink = async (link: API.ILink) => {
    const [removeLinkError] = await removeLink({
      user_page_id: pageStore.data.id,
      competitor_page_id: link.page_id,
      competitor_id: link.competitor_id,
    });

    const message =
      removeLinkError?.response?.data?.error ||
      removeLinkError?.message ||
      "Successfully removed link.";

    enqueueSnackbar(message, {
      variant: removeLinkError ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });

    if (removeLinkError) return;

    return pageStore.refetch();
  };

  const onGotoLink = (link: API.ILink) => {
    window.open(link.url, "_blank");
  };

  const onClickToggle = (componentId: string) => {
    store!.data.user.set(
      {
        componentSettings: {
          ...componentSettings,
          [componentId]: {
            isMinimized: !componentSettings[componentId].isMinimized,
          },
        },
      },
      { optimisticResponse: true }
    );
  };

  const onCloseComponentActionMenu = () =>
    setActiveComponent({ element: null, component: null });

  const toggleVisiblityForComponent = () => {
    store!.data.user.set(
      {
        componentSettings: {
          ...componentSettings,
          [activeComponent.component.id]: {
            isMinimized: !componentSettings[activeComponent.component.id]
              .isMinimized,
          },
        },
      },
      { optimisticResponse: true }
    );

    setActiveComponent({ element: null, component: null });
  };

  const onAddLink = async ({
    competitor_page_id,
    competitor_url,
  }: {
    competitor_page_id?: number;
    page_id: number;
    competitor_url?: string;
  }) => {
    const [linkPageErr] = await linkPage({
      page_id: pageStore.data.id,
      competitor_url: competitor_url,
      competitor_page_id: competitor_page_id,
    });

    const successMessage = "Successfully linked page.";
    const errorMessage =
      linkPageErr?.response?.data?.error || linkPageErr?.message;
    const message = errorMessage || successMessage;

    enqueueSnackbar(message, {
      variant: linkPageErr ? "error" : "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      TransitionComponent: TransitionUp,
    });

    pageStore.refetch();
  };

  const onAddKeyword = async (keyword: string) => {
    const [addKeywordErr] = await addKeyword({
      keywords: [...(pageStore.data?.keywords?.keywords || []), keyword],
      page_id: pageStore.data.id,
      user_site_id: site.id,
    });

    if (addKeywordErr)
      enqueueSnackbar(
        addKeywordErr?.response?.data?.error || addKeywordErr.message,
        {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        }
      );

    pageStore.refetch();
  };

  const onRemoveKeyword = async (keyword: string) => {
    if (pageStore.data.keywords.keywords.length === 1) {
      const [removeKeywordErr] = await removeKeyword(
        pageStore.data.keywords.id
      );

      if (removeKeywordErr)
        enqueueSnackbar(
          removeKeywordErr?.response?.data?.error || removeKeywordErr.message,
          {
            variant: "error",
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            TransitionComponent: TransitionUp,
          }
        );

      return pageStore.refetch();
    }

    const [addKeywordErr] = await addKeyword({
      keywords: pageStore.data.keywords.keywords.filter(
        (_keyword) => _keyword !== keyword
      ),
      page_id: pageStore.data.id,
      user_site_id: site.id,
    });

    if (addKeywordErr)
      enqueueSnackbar(
        addKeywordErr?.response?.data?.error || addKeywordErr.message,
        {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          TransitionComponent: TransitionUp,
        }
      );

    pageStore.refetch();
  };

  const onTodoSetDone = async (id: number) => {
    const [err] = await setTodoNoteDone(id);

    if (err) {
      const message = err?.response?.data?.error || err?.message;

      enqueueSnackbar(message, {
        variant: err ? "error" : "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        TransitionComponent: TransitionUp,
      });
    }

    pageStore.refetch();
  };

  const componentActionMenu = (
    <Menu
      open={Boolean(activeComponent.element)}
      anchorEl={activeComponent.element}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: -theme.spacing(6), horizontal: "center" }}
      onClose={onCloseComponentActionMenu}
    >
      <MenuItem onClick={toggleVisiblityForComponent}>
        {componentSettings[activeComponent?.component?.id]?.isMinimized
          ? "Expand"
          : "Collapse"}
      </MenuItem>
    </Menu>
  );

  const components = {
    "competing-pages": (dragHandleProps: any) => (
      <ComponentContainer
        title="Competing Pages"
        isMinimized={componentSettings["competing-pages"].isMinimized}
        onClickToggle={() => onClickToggle("competing-pages")}
        index={0}
        dragHandleProps={dragHandleProps}
      >
        <LinkCompetitors
          page={pageStore.data}
          onAddLink={onAddLink}
          onRemoveLink={onRemoveLink}
          onGotoLink={onGotoLink}
        />
      </ComponentContainer>
    ),
    keywords: (dragHandleProps: any) => (
      <ComponentContainer
        title="Keywords"
        isMinimized={componentSettings["keywords"].isMinimized}
        onClickToggle={() => onClickToggle("keywords")}
        index={1}
        dragHandleProps={dragHandleProps}
      >
        <Keywords
          onAddKeyword={onAddKeyword}
          onRemoveKeyword={onRemoveKeyword}
          keywords={
            hasKeywords
              ? sortBy(identity)(pageStore.data.keywords.keywords)
              : []
          }
        />
      </ComponentContainer>
    ),
    gsc: (dragHandleProps: any) => (
      <ComponentContainer
        title="Google Search Console"
        isMinimized={componentSettings["gsc"].isMinimized}
        index={2}
        onClickToggle={() => onClickToggle("gsc")}
        dragHandleProps={dragHandleProps}
      >
        {hasGSCData ? (
          <GSC pageStore={pageStore} site={site} />
        ) : (
          <Typography variant="body2" color="textSecondary">
            There is no GSC data for this page.
          </Typography>
        )}
      </ComponentContainer>
    ),
    "seo-preview": (dragHandleProps: any) => (
      <ComponentContainer
        title="SERP Snippet"
        isMinimized={componentSettings["seo-preview"].isMinimized}
        index={3}
        onClickToggle={() => onClickToggle("seo-preview")}
        dragHandleProps={dragHandleProps}
      >
        <SEOPreview
          title={pageStore.data.title}
          description={pageStore.data.description}
          url={pageStore.data.url}
        />
      </ComponentContainer>
    ),
    majestic: (dragHandleProps: any) => (
      <ComponentContainer
        title="Majestic"
        isMinimized={componentSettings["majestic"].isMinimized}
        index={4}
        onClickToggle={() => onClickToggle("majestic")}
        dragHandleProps={dragHandleProps}
      >
        {hasMajesticData ? (
          <Majestic page={pageStore.data} />
        ) : (
          <Typography variant="body2" color="textSecondary">
            There is no Majestic data for this page.
          </Typography>
        )}
      </ComponentContainer>
    ),
    todos: (dragHandleProps: any) => (
      <ComponentContainer
        title="Todos"
        isMinimized={componentSettings["todos"].isMinimized}
        index={4}
        onClickToggle={() => onClickToggle("todos")}
        dragHandleProps={dragHandleProps}
      >
        <Todos
          pageId={pageStore.data.id}
          pageUrl={pageStore.data.url}
          notes={pageStore.data.todos}
          onTodoSetDone={onTodoSetDone}
          onClickAddTodo={onClickAddTodo}
        />
      </ComponentContainer>
    ),
    trends: (dragHandleProps: any) => (
      <ComponentContainer
        title="Trending Related Keywords"
        isMinimized={componentSettings["trends"].isMinimized}
        index={4}
        onClickToggle={() => onClickToggle("trends")}
        dragHandleProps={dragHandleProps}
      >
        {typeof pageStore.data.trending !== "undefined" ? (
          <Trends {...pageStore.data.trending} />
        ) : (
          <Typography variant="body2">
            No trending related keywords for page. Only specified page keywords
            (starred keywords) will be checked for related trending keywords and
            not all keywords will yield a result in Google Trends
          </Typography>
        )}
      </ComponentContainer>
    ),
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination!.index;

    store!.data.user.set(
      {
        componentOrder: reorder(componentOrder, sourceIndex, destinationIndex),
      },
      { optimisticResponse: true }
    );
  };

  return (
    <>
      <Layout
        backgroundColor={grey["200"]}
        renderAppToolbar={({ toggle, isOpen }) => (
          <AppBarToolbar
            drawerIsOpen={isOpen}
            toggleDrawer={toggle}
            onClickLogout={logout}
            goBackUrl={`/sites/${params.siteId}`}
          />
        )}
      >
        <AddTodoDialog
          open={addTodoDialogIsOpen}
          onClose={onCloseAddTodoDialog}
          data={todoDialogData}
          pageStore={pageStore}
        />
        <div className={classes.container}>
          <Breadcrumb
            paths={[
              { label: toUnicode(site?.domain), url: "/" },
              ...(isCompetitor
                ? [{ label: getDomain(pageStore.data.url), url: null }]
                : []),
              {
                label: decodeURIComponent(
                  pageStore.data.url.replace(/.*\//g, "")
                ),
                url: null,
              },
            ]}
            onClickPath={history.push}
            styles={{ position: "sticky", top: 0 }}
          />

          <Container padding="horisontal">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {componentOrder.map(
                        (componentId: string, index: number) => {
                          const component = (components as any)[componentId];

                          return (
                            <Draggable
                              key={componentId}
                              draggableId={componentId}
                              index={index}
                            >
                              {(provided) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getComponentWrapperStyle(
                                      provided.draggableProps.style
                                    )}
                                  >
                                    {component(provided.dragHandleProps)}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        }
                      )}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </Container>
        </div>
      </Layout>

      {componentActionMenu}
    </>
  );
};

export default PageScreen;
