import React, { FC, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { API } from "../../../api/types";
import { getAuthHeaders } from "../../../api";
import config from "../../../config";
import { Button, Card, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { green, grey, red } from "@material-ui/core/colors";

interface ICompetitorPagesProps {
  pageId: number;
}

const useStyles = makeStyles((theme) => ({
  rankingContainer: {
    display: "flex",
    overflow: "auto",
  },
  contentBox: {
    flex: 1,
    minWidth: 65,
  },
  competitorPageCard: {
    padding: "16px",
    borderRadius: 0,
  },
  weekData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  datumInc: {
    color: green["700"],
  },
  datumDec: {
    color: red["700"],
  },
  bgEven: {
    backgroundColor: "#f2f2f2",
  },
  notAvailableText: {
    color: grey["500"],
  },
}));

const CompetitorPages: FC<ICompetitorPagesProps> = (props) => {
  const classes = useStyles();

  const [competitorsOpen, setCompetitorsOpen] = useState(false);
  //const []

  const dataStore = useFetch<API.SeoProgress.ICompetitorPages>({
    axiosConfig: {
      headers: getAuthHeaders(),
      url: `${config.api.url}/seovalues/dashbord/competitors/${props.pageId}`,
    },
    ignore: true,
  });

  const onClickShowCompetitors = () => {
    setCompetitorsOpen(true);
    dataStore.refetch();
  };

  return (
    <>
      {!competitorsOpen && (
        <Button
          type="submit"
          color="primary"
          onClick={() => onClickShowCompetitors()}
        >
          Show Competitors Data
        </Button>
      )}
      {dataStore.data && (
        <>
          {dataStore.data.map((competitor) => {
            return (
              <Card
                className={classes.competitorPageCard}
                key={competitor.page.id}
              >
                <Typography variant="h5" color="textSecondary">
                  {competitor.page.url}
                </Typography>
                <div className={classes.rankingContainer}>
                  <div className={`${classes.contentBox}`}>
                    <div>
                      <Typography variant="body2">Week</Typography>
                    </div>
                    <div>
                      <Typography variant="body2">Links</Typography>
                    </div>
                    <div>
                      <Typography variant="body2">Ref. Dom</Typography>
                    </div>
                    <div>
                      <Typography variant="body2">CF</Typography>
                    </div>
                    <div>
                      <Typography variant="body2">TF</Typography>
                    </div>
                  </div>
                  {competitor.week_data.map((week, index) => {
                    return (
                      <div
                        className={clsx(classes.weekData, classes.contentBox, {
                          [classes.bgEven]: Boolean(index % 2 === 0),
                        })}
                        key={String(week.week)}
                      >
                        <div>
                          <Typography
                            variant="body2"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {String(week.week)}
                          </Typography>
                        </div>
                        {week.majestic.no_result ? (
                          <React.Fragment>
                            <div className={classes.notAvailableText}>
                              <Typography variant="body2">-</Typography>
                            </div>
                            <div className={classes.notAvailableText}>
                              <Typography variant="body2">-</Typography>
                            </div>
                            <div className={classes.notAvailableText}>
                              <Typography variant="body2">-</Typography>
                            </div>
                            <div className={classes.notAvailableText}>
                              <Typography variant="body2">-</Typography>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {week.majestic.previous &&
                            week.majestic.previous.ext_back_links !==
                              week.majestic.ext_back_links ? (
                              week.majestic.previous.ext_back_links >
                              week.majestic.ext_back_links! ? (
                                <div className={classes.datumDec}>
                                  <Typography variant="body2">
                                    {week.majestic.ext_back_links}
                                  </Typography>
                                </div>
                              ) : (
                                <div className={classes.datumInc}>
                                  <Typography variant="body2">
                                    {week.majestic.ext_back_links}
                                  </Typography>
                                </div>
                              )
                            ) : (
                              <div>
                                <Typography variant="body2">
                                  {week.majestic.ext_back_links}
                                </Typography>
                              </div>
                            )}

                            {week.majestic.previous &&
                            week.majestic.previous.ref_domains !==
                              week.majestic.ref_domains ? (
                              week.majestic.previous.ref_domains >
                              week.majestic.ref_domains! ? (
                                <div className={classes.datumDec}>
                                  <Typography variant="body2">
                                    {week.majestic.ref_domains}
                                  </Typography>
                                </div>
                              ) : (
                                <div className={classes.datumInc}>
                                  <Typography variant="body2">
                                    {week.majestic.ref_domains}
                                  </Typography>
                                </div>
                              )
                            ) : (
                              <div>
                                <Typography variant="body2">
                                  {week.majestic.ref_domains}
                                </Typography>
                              </div>
                            )}

                            {week.majestic.previous &&
                            week.majestic.previous.citation_flow !==
                              week.majestic.citation_flow ? (
                              week.majestic.previous.citation_flow >
                              week.majestic.citation_flow! ? (
                                <div className={classes.datumDec}>
                                  <Typography variant="body2">
                                    {week.majestic.citation_flow}
                                  </Typography>
                                </div>
                              ) : (
                                <div className={classes.datumInc}>
                                  <Typography variant="body2">
                                    {week.majestic.citation_flow}
                                  </Typography>
                                </div>
                              )
                            ) : (
                              <div>
                                <Typography variant="body2">
                                  {week.majestic.citation_flow}
                                </Typography>
                              </div>
                            )}

                            {week.majestic.previous &&
                            week.majestic.previous.trust_flow !==
                              week.majestic.trust_flow ? (
                              week.majestic.previous.trust_flow >
                              week.majestic.trust_flow! ? (
                                <div className={classes.datumDec}>
                                  <Typography variant="body2">
                                    {week.majestic.trust_flow}
                                  </Typography>
                                </div>
                              ) : (
                                <div className={classes.datumInc}>
                                  <Typography variant="body2">
                                    {week.majestic.trust_flow}
                                  </Typography>
                                </div>
                              )
                            ) : (
                              <div>
                                <Typography variant="body2">
                                  {week.majestic.trust_flow}
                                </Typography>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            );
          })}
        </>
      )}
    </>
  );
};

export default CompetitorPages;
