import React, { FC } from "react";
import { AppBar, Link, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  },
  link: {
    cursor: "pointer",
    display: "inline",
    fontSize: theme.typography.body1.fontSize,
  },
  separator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
  disabled: {
    color: theme.palette.text.primary,
    cursor: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

interface IPath {
  label: string;
  url: string | null;
}

interface IBreadcrumbProps {
  onClickPath: (url: string) => void;
  paths: IPath[];

  styles?: React.CSSProperties;
}

const Breadcrumb: FC<IBreadcrumbProps> = (props) => {
  const classes = useStyles();

  const onClickPath = (path: IPath) => {
    if (!path.url) return;
    props.onClickPath(path.url);
  };

  return (
    <AppBar
      className={classes.appBar}
      position="static"
      color="transparent"
      style={props.styles || {}}
    >
      {props.paths.map((path, index) => {
        const isLast = index === props.paths.length - 1;

        return (
          <React.Fragment key={path.url}>
            <Link
              className={clsx(classes.link, {
                [classes.disabled]: Boolean(!path.url),
              })}
              onClick={() => onClickPath(path)}
            >
              {path.label}
            </Link>
            {!isLast && (
              <Typography color="textSecondary" className={classes.separator}>
                /
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </AppBar>
  );
};

export default Breadcrumb;
