import React, { FC } from "react";
import { RenderAppToolbar } from "./AppBarToolbar/types";
import Drawer from "../components/Drawer/Drawer";
import { createGlobalStyle } from "styled-components";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import MessageDrawer from "./MessageDrawer/MessageDrawer";

const GlobalStyles = createGlobalStyle<{
  backgroundColor?: CSSProperties["backgroundColor"];
}>((props) => ({
  body: {
    backgroundColor: props.backgroundColor || "white",
  },
}));

interface ILayoutProps {
  renderAppToolbar: RenderAppToolbar;
  backgroundColor?: CSSProperties["backgroundColor"];
}

const Layout: FC<ILayoutProps> = (props) => {
  return (
    <>
      <GlobalStyles backgroundColor={props.backgroundColor} />
      <Drawer renderAppToolbar={props.renderAppToolbar}>
        {props.children}
      </Drawer>

      <MessageDrawer />
    </>
  );
};

export default Layout;
