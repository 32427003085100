// SUGGESTION: When using multiple conditions this component can easily be messy. Instead, a
// major improvment would be to to refactor the parameters to something like
// this: {[path: string]: boolean}. That way, you'd never need to nest this
// comoponent.

import React, { FC } from "react";
import { useHistory } from "react-router-dom";

// If condition is met, the user will be redirected to the spcified redirectUrl
interface IProtectedRouteProps {
  condition: boolean;
  redirectUrl: string;
}

const ConditionalRedirect: FC<IProtectedRouteProps> = (props) => {
  const history = useHistory();
  if (props.condition) history.push(props.redirectUrl);
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default ConditionalRedirect;
