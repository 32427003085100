import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { getError, getHelperText } from "../../utils/form";

interface IAddDraftDialogProps {
  onClose: () => void;
  onAdd: (draftPath: string) => void;
  open: boolean;
  indexUrl: string;
  defaultRoute: string;
}

const useStyles = makeStyles({
  formControl: {
    width: 400,
  },
  indexUrlText: {
    whiteSpace: "nowrap",
  },
});

const AddDraftDialog: FC<IAddDraftDialogProps> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    onSubmit: (values, { resetForm }) => {
      props.onAdd(values.draftPath);
      resetForm();
    },
    initialValues: { draftPath: props.defaultRoute },
    enableReinitialize: true,
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    formik.submitForm();
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">Add draft</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.indexUrlText}
              autoFocus
              margin="dense"
              label="Route"
              fullWidth
              onChange={(e) =>
                formik.setFieldValue("draftPath", e.target.value)
              }
              name="draftPath"
              value={formik.values.draftPath}
              error={getError(formik as any, "draftPath")}
              helperText={getHelperText(formik as any, "draftPath")}
              InputProps={{
                startAdornment: (
                  <Typography color="textSecondary">
                    {props.indexUrl}/
                  </Typography>
                ),
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddDraftDialog;
