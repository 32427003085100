import React, { useContext } from "react";
import {
  makeStyles,
  createStyles,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import LinkComponent from "./LinkComponent";
import Notes from "./Notes";
import YourCommitmentForm from "./YourCommitmentForm";
import { linkFunnelContext } from "../utils/contexts";
import { API } from "../../../api/types";
import ContactDetails from "./ContactDetails";
import TheirCommitmentForm from "./TheirCommitmentForm";
import Container from "../../../components/Container/Container";
import LinkCard from "../../Links/components/LinkCard";
import AnchorDistribution from "./AnchorDistribution";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
  })
);

const StatusOnHold = () => {
  const classes = useStyles();
  const { linkStore, addNote, deleteNote, onClickCancel, onClickPrevious } =
    useContext(linkFunnelContext)!;

  const isSettled = ["accepted", "analyze_no_go", "declined"].includes(
    linkStore.data.status
  );

  const onAddNote = (data: { note: string }) => {
    addNote({ text: data.note, link_id: linkStore.data.id });
  };

  const onDeleteNote = (note: API.Outreach.ILinkComment) => {
    deleteNote(note.id);
  };

  return (
    <Container>
      <LinkCard link={linkStore.data} showData disableActions />
      <Box mb={2} />
      <LinkComponent title="Contact">
        {Boolean(linkStore.data.contact) ? (
          <ContactDetails />
        ) : (
          <Typography variant="body2" color="textSecondary">
            No contact
          </Typography>
        )}
      </LinkComponent>
      <Box mb={2} />
      {Boolean(linkStore.data.page_backlinks?.length) && (
        <>
          <LinkComponent title="Anchor Distribution">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AnchorDistribution />
            </div>
          </LinkComponent>
          <Box mb={2} />
        </>
      )}
      <LinkComponent title="Your Commitment">
        <YourCommitmentForm />
      </LinkComponent>
      <Box mb={2} />
      <LinkComponent title="Their Commitment">
        <TheirCommitmentForm />
      </LinkComponent>
      <Box mb={2} />
      <LinkComponent title="Notes">
        <Notes
          onDeleteNote={onDeleteNote}
          onAddNote={onAddNote}
          notes={linkStore.data.notes}
        />
      </LinkComponent>
      {!isSettled && (
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={onClickCancel(linkStore.data)}
          >
            Decline
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            onClick={onClickPrevious(linkStore.data)}
          >
            Negotiate Again
          </Button>
        </div>
      )}
    </Container>
  );
};

export default StatusOnHold;
