import React, { FC } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { green, grey, red } from "@material-ui/core/colors";
import { API } from "../../../../api/types";
import { Refresh } from "@material-ui/icons";

interface ITotalsProps {
  data: API.ISearchConsoleTotalsEntry[];
  canRefetch: boolean;
  onClickRefresh: (yearWeek: string) => void;
  amountOfKeywords: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    queryContainer: {
      display: "flex",
    },
    notAvailableText: {
      color: grey["500"],
    },
    rankingContainer: {
      display: "flex",
      overflow: "auto",
    },
    contentBox: {
      flex: 1,
      minWidth: 65,
    },
    weekData: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    datumInc: {
      color: green["700"],
    },
    datumDec: {
      color: red["700"],
    },
    bgEven: {
      backgroundColor: "#f2f2f2",
    },
    refreshButton: {
      fontSize: 18,
      marginLeft: 4,
      color: "rgba(0, 0, 0, 0.4)",
    },
    refreshButtonOwner: {
      color: "rgba(0,0,0,.7)",
      cursor: "pointer",
      "&:hover": {
        color: "rgba(0,0,0,1)",
      },
    },
  })
);

const Totals: FC<ITotalsProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Totals
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {props.amountOfKeywords} keywords
      </Typography>

      <Box mb={1} />
      {/*{props.type === "standard" ? standard : graph}*/}

      <div className={classes.rankingContainer}>
        <div className={`${classes.contentBox}`}>
          <div>
            <Typography variant="body2">Week</Typography>
          </div>
          <div>
            <Typography variant="body2">Pos</Typography>
          </div>
          <div>
            <Typography variant="body2">Clicks</Typography>
          </div>
          <div>
            <Typography variant="body2">Imps</Typography>
          </div>
          <div>
            <Typography variant="body2">CTR</Typography>
          </div>
          <div>
            <Typography variant="body2">Keywords</Typography>
          </div>
        </div>
        {props.data.map((week, index) => {
          const isFirst = index === 0;

          return (
            <div
              className={clsx(classes.weekData, classes.contentBox, {
                [classes.bgEven]: Boolean(index % 2 === 0),
              })}
              key={String(week.year_week)}
            >
              <div>
                <Typography
                  variant="body2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {String(week.year_week)}
                  {!isFirst && (
                    <Tooltip
                      title={
                        props.canRefetch
                          ? ""
                          : "You need to be an owner of the site in order to refetch a week."
                      }
                    >
                      <Refresh
                        onClick={() => {
                          if (!props.canRefetch) return;
                          props.onClickRefresh(String(week.year_week));
                        }}
                        className={clsx(classes.refreshButton, {
                          [classes.refreshButtonOwner]: props.canRefetch,
                        })}
                      />
                    </Tooltip>
                  )}
                </Typography>
              </div>
              {week.no_result ? (
                <React.Fragment>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                  <div className={classes.notAvailableText}>
                    <Typography variant="body2">-</Typography>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {week.previous && week.previous.position !== week.position ? (
                    week.previous.position < week.position! ? (
                      <div className={classes.datumDec}>
                        <Typography variant="body2">{week.position}</Typography>
                      </div>
                    ) : (
                      <div className={classes.datumInc}>
                        <Typography variant="body2">{week.position}</Typography>
                      </div>
                    )
                  ) : (
                    <div>
                      <Typography variant="body2">{week.position}</Typography>
                    </div>
                  )}
                  {week.previous && week.previous.clicks !== week.clicks ? (
                    week.previous.clicks > week.clicks! ? (
                      <div className={classes.datumDec}>
                        <Typography variant="body2">{week.clicks}</Typography>
                      </div>
                    ) : (
                      <div className={classes.datumInc}>
                        <Typography variant="body2">{week.clicks}</Typography>
                      </div>
                    )
                  ) : (
                    <div>
                      <Typography variant="body2">{week.clicks}</Typography>
                    </div>
                  )}
                  {week.previous &&
                  week.previous.impressions !== week.impressions ? (
                    week.previous.impressions > week.impressions! ? (
                      <div className={classes.datumDec}>
                        <Typography variant="body2">
                          {week.impressions}
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.datumInc}>
                        <Typography variant="body2">
                          {week.impressions}
                        </Typography>
                      </div>
                    )
                  ) : (
                    <div>
                      <Typography variant="body2">
                        {week.impressions}
                      </Typography>
                    </div>
                  )}
                  {week.previous && week.previous.ctr !== week.ctr ? (
                    week.previous.ctr > week.ctr! ? (
                      <div className={classes.datumDec}>
                        <Typography variant="body2">{week.ctr}</Typography>
                      </div>
                    ) : (
                      <div className={classes.datumInc}>
                        <Typography variant="body2">{week.ctr}</Typography>
                      </div>
                    )
                  ) : (
                    <div>
                      <Typography variant="body2">{week.ctr}</Typography>
                    </div>
                  )}
                  {week.previous && week.previous.entries !== week.entries ? (
                    week.previous.entries > week.entries! ? (
                      <div className={classes.datumDec}>
                        <Typography variant="body2">{week.entries}</Typography>
                      </div>
                    ) : (
                      <div className={classes.datumInc}>
                        <Typography variant="body2">{week.entries}</Typography>
                      </div>
                    )
                  ) : (
                    <div>
                      <Typography variant="body2">{week.entries}</Typography>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Totals;
