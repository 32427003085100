import React, { FC } from "react";
import { API } from "../../../../api/types";
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface IKeywordAnalyzeDialogProps {
  onClose: () => void;
  open: boolean;
  content: API.ISearchConsoleKeywordAnalyzeData;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const KeywordAnalyzeDialog: FC<IKeywordAnalyzeDialogProps> = (props) => {
  const classes = useStyles();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div>{props.content.query}</div>
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {props.content.geo && props.content.geo!.length ? (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Geo</TableCell>
                  <TableCell align="right">Clicks</TableCell>
                  <TableCell align="right">Imps</TableCell>
                  <TableCell align="right">CTR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.content.geo!.map(
                  (geo: API.ISearchConsoleGeoData, index: number) => {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell>{geo.geo}</TableCell>
                        <TableCell align="right">{geo.clicks}</TableCell>
                        <TableCell align="right">{geo.impressions}</TableCell>
                        <TableCell align="right">{geo.ctr}</TableCell>
                      </StyledTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div>
            Geo data is only collected for keywords starred when GSC is queried.
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KeywordAnalyzeDialog;
