import React, { FC } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";

const PDate: FC<{
  label: string;
  name: string;
  formik: any;
  dateFormat: string;
}> = (props) => {
  const value = props.formik.values[props.name];

  return (
    <KeyboardDatePicker
      style={{ width: "100%" }}
      clearable
      margin="normal"
      id="date-picker-dialog"
      label={props.label}
      format={props.dateFormat}
      value={value ? new Date(value) : null}
      onChange={(date) => {
        const newValue = date ? format(date as any, props.dateFormat) : null;
        props.formik.setFieldValue(props.name, newValue);
      }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />
  );
};

export default PDate;
