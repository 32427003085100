import {
  blue,
  deepOrange,
  green,
  grey,
  orange,
  yellow,
} from "@material-ui/core/colors";
import { API } from "../../api/types";

export const getNextStatus = (
  link: API.Outreach.Link,
  proceed: boolean
): API.Outreach.Link["status"] | null => {
  const { status } = link;

  if (!proceed) {
    if (status === "analyze_pending") return "analyze_no_go";
    return "declined";
  }
  if (status === "analyze_pending") return "contact";
  if (status === "contact") return "awaiting_response";
  if (status === "awaiting_response") return "negotiating";
  if (status === "negotiating") return "accepted";

  return null;
};

export const getPreviousStatus = (
  link: API.Outreach.Link
): API.Outreach.Link["status"] | null => {
  const { status } = link;

  if (status === "contact") return "analyze_pending";
  if (status === "awaiting_response") return "contact";
  if (status === "negotiating") return "awaiting_response";
  if (status === "accepted") return "negotiating";
  if (status === "on_hold") return "negotiating";

  return null;
};

export const getStatusColor = (status: API.Outreach.Link["status"]) => {
  if (["analyze_pending", "new"].includes(status)) return deepOrange["700"];
  if (status === "contact") return orange["700"];
  if (status === "awaiting_response") return blue["700"];
  if (status === "negotiating") return yellow["700"];
  if (status === "accepted") return green["700"];
  if (["declined", "analyze_no_go"].includes(status)) return grey["500"];
};

export const getStatusPresentationName = (
  status: API.Outreach.Link["status"]
) => {
  if (status === "new") return "Analyzing";
  if (status === "analyze_pending") return "Analyzing";
  if (status === "analyze_no_go") return "Declined";
  return humanizeStatus(status);
};

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const humanizeStatus = (attributeName: string) => {
  return attributeName.split("_").map(capitalize).join(" ");
};
