import React, { FC } from "react";
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { API } from "../../../../api/types";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

interface ITodosProps {
  pageId?: number;
  pageUrl?: string;
  notes: API.ITodoNote[];
  onTodoSetDone: (id: number) => void;
  onClickAddTodo?: (data: API.ITodoNoteDialogData) => void;
  printAllValues?: boolean;
  userSiteId?: number;

}

const useStyles = makeStyles((theme) =>
  createStyles({
    todoNoteListItem: {
      display: "flex",
    },
    todoNoteListItemText: {
      flex: 1,
      display: "flex",
      alignItems: "center",

      padding: "8px",
    },
    todoNoteListItemPath: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      padding: "8px",
      textDecoration: "underline",
      cursor: "pointer",
    },
    todoNoteListItemTextDone: {
      textDecoration: "line-through",
    },
    todoNoteListItemButton: {
      width: "75px",
      textAlign: "right",
      display: "flex",
      alignItems: "center",
    },
    addTodoBtnWrapper: {
      textAlign: "center",
    },
  })
);

const Todos: FC<ITodosProps> = (props) => {
  const classes = useStyles();

  const history = useHistory();
  const gotoPage = (userSiteId: number, pageId: number) => {
    history.push(`/sites/${userSiteId}/pages/${pageId}`);
  };

  return (
    <>
      {props.notes.length ? (
        <>
          {props.notes.map((note, index) => {
            return (
              <React.Fragment key={note.id}>
                <div className={classes.todoNoteListItem}>
                  <div
                    className={clsx(classes.todoNoteListItemText, {
                      [classes.todoNoteListItemTextDone]: Boolean(
                        note.closed_at
                      ),
                    })}
                  >
                    {note.text}
                  </div>
                  {props.printAllValues && (
                    <>
                      <div
                        className={classes.todoNoteListItemPath}
                        onClick={() =>
                          gotoPage(props.userSiteId!, note.page_id)
                        }
                      >
                        <Typography variant="body2">{note.path}</Typography>
                      </div>
                    </>
                  )}
                  <div className={classes.todoNoteListItemButton}>
                    <Button
                      type="submit"
                      color="primary"
                      onClick={() => props.onTodoSetDone(note.id)}
                      disabled={Boolean(note.closed_at)}
                    >
                      Done!
                    </Button>
                  </div>
                </div>
                <Box mb={2} />
                <Divider />
                <Box mb={2} />
              </React.Fragment>
            );
          })}
          {props.pageId && props.pageUrl && (
            <div className={classes.addTodoBtnWrapper}>
              <Button
                type="submit"
                color="primary"
                onClick={() =>
                  props.onClickAddTodo!({
                    pageId: props.pageId!,
                    url: props.pageUrl!,
                  })
                }
              >
                Add ToDo
              </Button>
            </div>
          )}
        </>
      ) : (
        <Typography variant="body2" className={classes.todoNoteListItemText}>
          No todos found
        </Typography>
      )}
    </>
  );
};

export default Todos;
