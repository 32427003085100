import React, { FC, useContext, useState, useEffect } from "react";
import {
  Drawer,
  makeStyles,
  createStyles,
  Typography,
} from "@material-ui/core";
import { StoreContext } from "../../contexts";
import MessageDrawerHeader from "./MessageDrawerHeader";
import Message from "./Message";
import SingleMessage from "./SingleMessage";
import { API } from "../../api/types";
import { openedMessageDrawer, readMessage } from "../../api";
import { toUnicode } from "punycode";

const drawerWidth = 350;

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
    },
    noMessagesText: {
      marginLeft: theme.spacing(2),
    },
  })
);

const MessageDrawer: FC = () => {
  const [activeMessage, setActiveMessage] = useState<API.IMessage | null>(null);
  const classes = useStyles();
  const store = useContext(StoreContext);
  const onClose = () => store?.ui.messageDrawer.set(false);
  const onClickMessage = (message: API.IMessage) => {
    readMessage(message.id).then(store!.data.messages.refetch);
    setActiveMessage(message);
  };

  const isOpen = store!.ui.messageDrawer.get();

  useEffect(() => {
    if (isOpen) {
      openedMessageDrawer();
    }
  }, [isOpen]);

  useEffect(() => setActiveMessage(null), [isOpen]);
  const renderMesssage = (message: API.IMessage) => {
    const site = Boolean(message.domain) ? toUnicode(message.domain) : null;
    return (
      <Message
        key={message.id}
        message={message.subject}
        created={message.created_at}
        onClick={() => onClickMessage(message)}
        isRead={Boolean(message.read_date)}
        site={site}
      />
    );
  };

  const MessagesTab = (
    <>
      <MessageDrawerHeader
        onClickClose={() => store!.ui.messageDrawer.set(false)}
      />

      {store!.data.messages?.data?.map?.(renderMesssage) || (
        <Typography
          className={classes.noMessagesText}
          variant="body2"
          color="textSecondary"
        >
          You have no messages
        </Typography>
      )}
    </>
  );

  const SingleMessageTab = (
    <>
      <MessageDrawerHeader
        onClickBack={() => setActiveMessage(null)}
        onClickClose={() => store!.ui.messageDrawer.set(false)}
      />
      {activeMessage && (
        <SingleMessage
          //site={toUnicode(activeMessage!.domain)}
          site={
            Boolean(activeMessage.domain)
              ? toUnicode(activeMessage!.domain)
              : null
          }
          title={activeMessage!.subject}
          message={activeMessage!.message}
          created={activeMessage!.created_at}
        />
      )}
    </>
  );

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      open={isOpen}
      anchor="right"
      onClose={onClose}
    >
      {Boolean(activeMessage) ? SingleMessageTab : MessagesTab}
    </Drawer>
  );
};

export default MessageDrawer;
