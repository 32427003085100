// Temporary component since it's going to be a lot of changes.

import React, { FC } from "react";
import { API } from "../../../../api/types";
import PTable from "../GSC/PTable";

interface IMajesticProps {
  page: API.IPage;
}

const Majestic: FC<IMajesticProps> = (props) => {
  const header = [
    "Affected By Anti Spam",
    "Citation Flow",
    "Crawled Flag",
    "External Back Links",
    "Final Redirect Result",
    "Last Crawl Date",
    "Last Crawl Result",
    "Out Domains External",
    "Out Links External",
    "Out Links Internal",
    "Out Links Pages",
    "Redirect Flag",
    "Redirect To",
    "Ref Domains",
    "Ref IPs",
    "Ref Language",
    "Time Fetched",
    //"Topical Trust Flow",
    "Trust Flow",
    "Trust Metric",
  ];

  const body = props.page.majestic!.map((majesticObj) => {
    return [
      majesticObj.affected_by_anti_spam,
      majesticObj.citation_flow,
      majesticObj.crawled_flag,
      majesticObj.ext_back_links,
      majesticObj.final_redirect_result,
      majesticObj.last_crawl_date,
      majesticObj.last_crawl_result,
      majesticObj.out_domains_external,
      majesticObj.out_links_external,
      majesticObj.out_links_internal,
      majesticObj.out_links_pages,
      majesticObj.redirect_flag,
      majesticObj.redirect_to,
      majesticObj.ref_domains,
      majesticObj.ref_ips,
      majesticObj.ref_language,
      majesticObj.time_fetched,
      //majesticObj.topical_trust_flow,
      majesticObj.trust_flow,
      majesticObj.trust_metric,
    ].map(String);
  });

  return <PTable header={header} body={body}></PTable>;
};

export default Majestic;
